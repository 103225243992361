import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getRole } from '../../../services/token';
import IconBackArrow from "../../../assets/icons/icon-back-arrow.png"
import IconLock from "../../../assets/icons/icon-lock.png"
import IconLockSelected from "../../../assets/icons/icon-lock-selected.png"
import IconProfile from "../../../assets/icons/icon-profile.png"
import IconProfileSelected from "../../../assets/icons/icon-profile-selected.png"
import IconEmail from "../../../assets/icons/icon-letter.png"
import IconEmailSelected from "../../../assets/icons/icon-letter-selected.png"

export default function CustomerSideProfileMenu(props) {
    const navigate = useNavigate();

    function navigateBack() {
        const role = getRole();
        navigate(`/${role}/jobs`);
    }

    const handleHoverIcon = (event) => {
        if (event === "profile") {
            iconProfileRef.current.src = IconProfileSelected;
        } else if (event === "email") {
            iconEmailRef.current.src = IconEmailSelected;
        } else if (event === "password") {
            iconPasswordRef.current.src = IconLockSelected;
        } else {
            if (props?.selected !== "email") {
                iconEmailRef.current.src = IconEmail;
            }
            if (props?.selected !== "password") {
                iconPasswordRef.current.src = IconLock;
            }
            if (props?.selected !== "profile") {
                iconProfileRef.current.src = IconProfile;
            }
        }
    };

    const handleEmailClick = async () => {
        const role = getRole();
        navigate(`/${role}/email`);
    };

    const handlePasswordClick = async () => {
        const role = getRole();
        navigate(`/${role}/password`);
    };

    const handleProfileClick = () => {
        const role = getRole();
        navigate(`/${role}/profile`);
    };

    const iconProfileRef = useRef(null);
    const iconEmailRef = useRef(null);
    const iconPasswordRef = useRef(null);

    return (
        <>
            <div className="dashboard--view-item-header" onClick={() => navigateBack()} style={{ marginLeft: '0px' }}>
                <img className="dashboard--view-item-header-backicon" src={IconBackArrow} alt="" />
                Back
            </div>

            <h2 className="profile--menu__header">Your profile</h2>

            <div className="profile--menu">
                <button className={props.selected === "profile" ? "profile--menu__item profile--menu__item-selected" : "profile--menu__item"}
                    href="#/" style={{ marginTop: '1px' }}
                    onClick={() => handleProfileClick()}
                    onMouseEnter={() => handleHoverIcon('profile')}
                    onMouseLeave={() => handleHoverIcon('')}
                >
                    <img ref={iconProfileRef} src={props.selected === "profile" ? IconProfileSelected : IconProfile} alt="" style={{ height: '24px', width: '24px', marginRight: '10px' }} />
                    Edit profile
                </button>

                <button className={props.selected === "email" ? "profile--menu__item profile--menu__item-selected" : "profile--menu__item"}
                    onClick={() => handleEmailClick()}
                    onMouseEnter={() => handleHoverIcon('email')}
                    onMouseLeave={() => handleHoverIcon('')}
                >
                    <img ref={iconEmailRef} src={props.selected === "email" ? IconEmailSelected : IconEmail} alt="" style={{ height: '24px', width: '24px', marginRight: '10px' }} />
                    Update email
                </button>

                <button className={props.selected === "password" ? "profile--menu__item profile--menu__item-selected" : "profile--menu__item"}
                    onClick={() => handlePasswordClick()}
                    onMouseEnter={() => handleHoverIcon('logout')}
                    onMouseLeave={() => handleHoverIcon('')}
                >
                    <img ref={iconPasswordRef} src={props.selected === "password" ? IconLockSelected : IconLock} alt="" style={{ height: '24px', width: '24px', marginRight: '10px' }} />
                    Update password
                </button>
            </div>
        </>
    );
}
