import React from "react";
import "./css/Table.css"
import IconAddress from "../../assets/icons/icon-address.png"
import IconDate from "../../assets/icons/icon-date.png"
import IconUser from "../../assets/icons/icon-user.png"
import IconArrowLeft from "../../assets/icons/icon-arrow-left.png"
import IconArrowRight from "../../assets/icons/icon-arrow-right.png"
import IconCalendar from "../../assets/icons/icon-calendar.png"
import { useSearchParams } from 'react-router-dom';
import moment from "moment";
import { Tooltip } from 'react-tooltip'
import { getStatusTooltip } from "../../services/utils"

export default function JobsTable(props) {
    
    const [searchParams] = useSearchParams();
    let alternateRow = false;

    function handleUpdatePage(page) {
        if (isNaN(page)) { 
          page = 2
        }
        searchParams.set('page', page)
        props.loadData(page)
    }

    return (
        <div>
            {/* Loop through jobs */}
            {props.jobs?.data?.map((job) => {
                alternateRow = !alternateRow;

                var date = new Date(job?.deliver_by_datetime)
                var dateMoment = moment(date)
                var dateValue = dateMoment.format("Do MMM Y [at] h:mm A")    
            
                return (
                    <div className={alternateRow ? "row company-jobs__list-row-box" : "row company-jobs__list-row-box-alt"} key={job.id}                    
                        onClick={() => props?.viewRow(job.id)}
                    >
                        <div className="col-md-6">
                            {/* Job details */}
                          
                            <Tooltip id="tooltip-status" />

                            <div className="company-jobs__list-job-id">
                                ID: {job?.id} 
                                <span style={{fontSize: '12px'}} 
                                    data-tooltip-id="tooltip-status"
                                    data-tooltip-content={
                                        getStatusTooltip(job?.status)                                        
                                    }
                                    data-tooltip-place="top"
                                    className="dashboard-page--title-status">{job?.status}</span>
                            </div>
                            <div className="company-jobs__list-job-description">
                                {job?.description}
                            </div>
                            <div className="company-jobs__list-job-info">
                                {job?.paid_at ?
                                <>
                                    <img className="company-jobs__list-job-date-user" src={IconUser} alt="User" /> {job?.customer?.first_name} {job?.customer?.last_name}
                                    <img className="company-jobs__list-job-date-icon" src={IconDate} alt="Date" /> {dateValue}
                                </>
                                : 
                                <>
                                    <img className="company-jobs__list-job-date-user" src={IconDate} alt="Date" /> {dateValue}
                                </>
                                }                                
                            </div>
                            <div className="company-jobs__list-job-info">
                                <img className="company-jobs__list-job-date-user" src={IconCalendar} alt="Calendar" /> 
                                    Flexible On Delivery Time: {!job?.deliver_by_flexible ? "No" : job?.deliver_by_flexible + " day/s" }
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* Address box */}
                            <div className="job--view-address-box">
                                {/* Collection County */}
                                <div className="row" style={{ padding: 0 }}>
                                    <div style={{ width: '25px', float: 'left', height: '100%' }}>
                                        <img src={IconAddress} alt="" style={{ height: '16px', width: '16px' }} />
                                    </div>
                                    <div className="col">
                                        <div className="company-jobs__list-job-collection-title">
                                            Collection County
                                        </div>
                                        <div className="company-jobs__list-job-collection-value">
                                            {job?.collection_county}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <hr className="job--view-address-hr" />
                                </div>
                                {/* Delivery County */}
                                <div className="row" style={{ padding: 0 }}>
                                    <div style={{ width: '25px', float: 'left', height: '100%' }}>
                                        <img src={IconAddress} alt="" style={{ height: '16px', width: '16px' }} />
                                    </div>
                                    <div className="col">
                                        <div className="company-jobs__list-job-collection-title">
                                            Delivery County
                                        </div>
                                        <div className="company-jobs__list-job-collection-value">
                                            {job?.deliver_county}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {/* Pagination section */}
            {props?.showPaging !== "false" &&
                <div style={{ backgroundColor: '#fff', width: '100%', height: '30px', marginTop: '35px', marginBottom: '15px', textAlign: 'center' }}>
                    <div id="footer" style={{ margin: '0 auto', maxWidth: 'fit-content' }}>
                        {(!searchParams.get('page') || parseInt(searchParams.get('page')) === 1) ?
                            <div className="pageLink disabled">
                                <img src={IconArrowLeft} style={{ width: '14px', height: '14px' }} alt="left" />
                            </div>
                            :
                            <div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) - 1)}>
                                <img src={IconArrowLeft} style={{ width: '14px', height: '14px' }} alt="left" />
                            </div>
                        }
                        {[...Array(props?.jobs?.meta?.pagination?.total_pages)].map((x, i) =>
                            <div
                                key={i}
                                onClick={() => handleUpdatePage(i + 1)}
                                className={"pageLink" +
                                    (((i + 1) === parseInt(searchParams.get('page')) ||
                                        (!searchParams.get('page') && i === 0)
                                    ) ? ' active' : '')
                                }>
                                {(i + 1)}
                            </div>
                        )}
                        {(props?.jobs?.meta?.pagination?.total_pages === 1 || parseInt(searchParams.get('page')) === props?.jobs?.meta?.pagination?.total_pages) ?
                            <div className="pageLink disabled">
                                <img src={IconArrowRight} style={{ width: '14px', height: '14px' }} alt="right" />
                            </div>
                            :
                            <div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) + 1)}>
                                <img src={IconArrowRight} style={{ width: '14px', height: '14px' }} alt="right" />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}
