import React, {useState, useEffect} from "react";
import '../../Auth/Login.css';
import {useNavigate} from "react-router-dom";
import {
    customerSignUp
} from "../../../services/requests"
import LogoMain from "../../../assets/imgs/logo-main-dark.png"
import FormFieldSet from '../../../components/Dashboard/FormFieldSet'
import { setToken } from "../../../services/token";
import {irishCounties} from "../../../constants/counties"
import { getErrorMessage } from "../../../services/utils"

const CustomerSignUp = () => {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [customer, setCustomer] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
        address1: "",
        address2: "",
        city: "",
        county: "",
        postcode: "",
        vat_number: "",
    });

    const formValueChanged = async (fieldValue) => {
        var myValue = fieldValue['field']
        setCustomer({ ...customer, [myValue]: fieldValue['value'] })
    }

    const handleSignUpClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)

        var body = {
            first_name: customer?.first_name,
            last_name: customer?.last_name,
            email: customer?.email,
            phone: customer?.phone,
            password: customer?.password,
            password_confirmation: customer?.password_confirmation,
            address1: customer?.address1,
            address2: customer?.address2,
            city: customer?.city,
            county: customer?.county,
            postcode: customer?.postcode,
            vat_number: customer?.vat_number,
        };
    
        try {
            const resp = await customerSignUp({ ...body });
            if (resp?.result === 'success') {
                setToken(resp?.token, resp?.role, resp?.email, resp?.name, resp?.role_id)
                navigate("/customer/jobs")
            }
            else {              
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp))
                window.scrollTo(0, 0);
            }
        } catch (error) {
            setErrorMessage("There was an error signing up as a customer.")
        }
    }

    useEffect(() => {
        document.title = 'Smart Haul - Shipper Signup';
        // eslint-disable-next-line
    }, []);    

    return <>
<div className="login__background">

<div className="div-center" style={{width: '700px', marginTop: '50px', paddingBottom: '50px'}}>

  <div className="content"style={{textAlign:'center'}}>
    <a href="/"><img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/></a>

    {errorMessage ? 
    <>
    <div dangerouslySetInnerHTML={{__html: errorMessage}} 
        className="alert alert-danger" role="alert">        
    </div>
    </> : ''}
    
    <h1 className="login__header">Create a shipper account</h1>

    <p style={{marginTop: -20, marginBottom: 40}}>
        Are you a carrier looking to register? <a className="login__footer-link" href="/company/signup">Sign up here instead</a>.
    </p>

    <FormFieldSet 
        showLabels={false}
        model={customer}
        fields={['First Name', 'Last Name']}
        fieldsData={['first_name', 'last_name']}
                    fieldsOnChange={[
                        (event) => formValueChanged({field: 'first_name', value: event?.target?.value}),
                        (event) => formValueChanged({field: 'last_name', value: event?.target?.value}),
                    ]}/>
    <FormFieldSet 
        model={customer}
        fields={['Email Address', 'Phone']}
        fieldsData={['email', 'phone']}
                    fieldsOnChange={[
                        (event) => formValueChanged({field: 'email', value: event?.target?.value}),
                        (event) => formValueChanged({field: 'phone', value: event?.target?.value}),
                    ]}/>
    <FormFieldSet 
        model={customer}
        fields={['Password', 'Confirm Password']}
        fieldsData={['password', 'password_confirmation']}
                    fieldsOnChange={[
                        (event) => formValueChanged({field: 'password', value: event?.target?.value}),
                        (event) => formValueChanged({field: 'password_confirmation', value: event?.target?.value}),
                    ]}/>
    <FormFieldSet 
        model={customer}
        fields={['Address line 1', 'Address line 2']}
        fieldsData={['address1', 'address2']}
                    fieldsOnChange={[
                        (event) => formValueChanged({field: 'address1', value: event?.target?.value}),
                        (event) => formValueChanged({field: 'address2', value: event?.target?.value}),
                    ]}/>
    <FormFieldSet 
        model={customer}
        fields={['City', 'County']}
        fieldsData={['city', 'county']}
        fieldsLookup={{county: irishCounties}}
                    fieldsOnChange={[
                        (event) => formValueChanged({field: 'city', value: event?.target?.value}),
                        (event) => formValueChanged({field: 'county', value: event?.target?.value}),
                    ]}/>
    <FormFieldSet 
        model={customer}
        fields={['Postcode', 'VAT Number']}
        fieldsData={['postcode', 'vat_number']}
                    fieldsOnChange={[
                        (event) => formValueChanged({field: 'postcode', value: event?.target?.value}),
                        (event) => formValueChanged({field: 'vat_number', value: event?.target?.value}),
                    ]}/>

        <button type="button" className="btn btn-primary login__button"
            onClick={() => handleSignUpClick()}
            disabled={isLoading}
        >Sign Up</button>

        <div className="login__footer-text">Already have an account? <a className="login__footer-link" href="/login">Login</a>.</div>

  </div>
</div>
</div>
    </>
}

export default CustomerSignUp;
