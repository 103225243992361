import IconPdf from '../../../assets/icons/icon-pdf.png'
import IconJpg from '../../../assets/icons/icon-jpg.png'
import IconPng from '../../../assets/icons/icon-png.png'
import Modal from 'react-bootstrap/Modal';
import React, {useState} from "react";
import { adminViewCompanyDocument } from '../../../services/requests'
// import { Document } from 'react-pdf';

import { pdfjs } from "react-pdf";

export default function AdminCompanyDocuments(props) {

    const [showDocument, setShowDocument] = useState(false);
    const [document, setDocument] = useState(false);
    const handleCloseDocument = () => setShowDocument(false);
    pdfjs.GlobalWorkerOptions.workerSrc = `http://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const handleDocumentClick = async (id, title) => {
        setShowDocument(true);
        const document = await adminViewCompanyDocument(props?.company?.id, id, props?.title);
        document.title = title;
        setDocument(document);
    }

    return (
<>
            <Modal show={showDocument} onHide={handleCloseDocument} centered className="modal-lg">
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  {document?.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">

                {document?.content_type?.includes('image') ? 
                <img className="dashboard--view-documents-image" src={'data:' + document?.content_type + ';base64, ' + document?.base64} alt="" />
                : 
                <>
                    <iframe src={`data:application/pdf;base64,${ document?.base64 }`} 
                        title="PDF"
                        className="dashboard--view-documents-image"
                        style={{height: '500px', width: '500px'}}
                    />
                </>
                }
              </Modal.Body>
            </Modal>     

<div className="dashboard--view-documents-header">Documents</div>   
<div className="container">
  <div className="row no-gutters">

{props?.company?.documents?.data?.length === 0 ?
<div className="dashboard-page--description" style={{paddingLeft: '0px'}}>There are no documents uploaded for this company.</div>
: ''}

{props?.company?.documents?.data?.map((document, index) => {
  return <>
  <div className="col" style={{paddingLeft: '0px'}}>
    <div className="square dashboard--view-documents-box"
      onClick={() => handleDocumentClick(document?.id, document?.title)}
    >
      {(document?.type === "image/png") ?
      <div><img src={IconPng} alt="PNG" /></div> : '' }

      {(document?.type === "image/jpeg") ?
      <div><img src={IconJpg} alt="JPEG" /></div> : '' }

      {(document?.type === "application/pdf") ?
      <div><img src={IconPdf} alt="PDF}" /></div> : '' }

      <div className="dashboard--view-documents-box-title">
        {document?.title}
      </div>
    </div>
  </div>
  </>
})}

  </div>
</div>
</>
    );
}