import React, { useState, useEffect } from "react";
import './Login.css';
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../../../services/requests"
import { getErrorMessage } from "../../../services/utils";
import LogoMain from "../../../assets/imgs/logo-main-dark.png"
import { setToken } from '../../../services/token';

const AdminLogin = () => {

    const navigation = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputs, setInput] = useState({ email: "", password: "" });
    const inputChange = (e) => {
        setInput({ ...inputs, [e.target.name]: e.target.value });
    }

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        if (!isLoading) {
            setIsLoading(true);
            var body = {
                email: inputs?.email,
                password: inputs?.password,
            }
            const resp = await makeRequest('admin/auth/sign-in', body, 'POST', false)
            if (resp.token) {
                setToken(resp?.token, resp?.role, resp?.email, resp?.name)
                navigation('/admin/customers');
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        document.title = 'Smart Haul - Admin Login';

        // eslint-disable-next-line
    }, []);

    return (
        <div className="login__background">
            <div className="div-center">
                <div className="content" style={{ textAlign: 'center' }}>
                    <a href="/"><img src={LogoMain} style={{ width: '200px' }} className="login__logo" alt="" /></a>

                    {errorMessage ?
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} className="alert alert-danger" role="alert"></div>
                        : null}

                    <h1 className="login__header">Admin Login</h1>

                    <form onSubmit={handleLoginSubmit}>
                        <div className="form-group">
                            <div className="login_input-wrapper">
                                <input
                                    onChange={inputChange}
                                    type="email"
                                    className="form-control login_input"
                                    id="email"
                                    name="email"
                                    placeholder=" " />
                                <label htmlFor="email">Email Address</label>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="login_input-wrapper">
                                <input
                                    onChange={inputChange}
                                    type='password'
                                    className="form-control login_input"
                                    name="password"
                                    id="exampleInputPassword1"
                                    placeholder=" " />
                                <label htmlFor="password">Password</label>
                            </div>

                            <div className="text-end"
                                style={{ marginBottom: '15px', marginTop: '-5px' }}
                            ><a className="login__forgot-link" href="/forgot">Forgot password?</a></div>
                        </div>

                        <button type="submit" className="btn btn-primary login__button" disabled={isLoading}>
                            Login
                        </button>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
