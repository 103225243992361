import React, { useState, useEffect } from "react";
import '../../Auth/Login.css';
import {
    companyGetProfile, companyUpdateProfile
} from "../../../services/requests"
import CompanyLayout from "../../../layouts/CompanyLayout";
import "./Profile.css"
import CompanySideProfileMenu from "../../../components/Dashboard/Companies/SideProfileMenu";
import FormFieldSet from "../../../components/Dashboard/FormFieldSet";
import { irishCounties } from "../../../constants/counties";
import { getErrorMessage } from "../../../services/utils";

const CompanyProfile = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);    
    const [successMessage, setSuccessMessage] = useState(false);    

    const [company, setCompany] = useState({
        name: "",
        description: "",
        phone: "",
        password: "",
        password_confirmation: "",
        address1: "",
        address2: "",
        city: "",
        county: "",
        postcode: "",
        vat_number: "",
    });

    const formValueChanged = async (fieldValue) => {
        var myValue = fieldValue['field']
        setCompany({ ...company, [myValue]: fieldValue['value'] })
    }

    const handleSaveClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)
        setSuccessMessage(null)

        var body = {
            name: company?.name,
            description: company?.description,
            phone: company?.phone,
            password: company?.password,
            password_confirmation: company?.password_confirmation,
            address1: company?.address1,
            address2: company?.address2,
            city: company?.city,
            county: company?.county,
            postcode: company?.postcode,
            vat_number: company?.vat_number,
        };
    
        try {
            const resp = await companyUpdateProfile({ ...body });
            setIsLoading(false)
            if (resp?.result === 'success') {
                setSuccessMessage("Your profile has been updated successfully.")
            }
            else {             
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp))
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage("There was an error saving your profile.")
        }
    }    

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true)        
            const resp = await companyGetProfile();
            setCompany(resp?.company);
            setIsLoading(false)
        }
        loadData()
        document.title = 'Smart Haul - Company - Profile';
        // eslint-disable-next-line
    }, []);    

    return (
        <CompanyLayout>
            <div className="row">
                <div className="col-3">
                    <CompanySideProfileMenu selected="profile" />
                </div>
                <div className="col-9">
                    {successMessage ? 
                        <div dangerouslySetInnerHTML={{__html: successMessage}} className="alert alert-success" role="alert"></div> 
                        : ''}
                    {errorMessage ? 
                        <div dangerouslySetInnerHTML={{__html: errorMessage}} className="alert alert-danger" role="alert"></div> 
                        : ''}
                    <h1 className="profile--form__header">Edit profile</h1>
                    <div className="profile--form__description">Edit profile to reflect changes and keep your details accurate and up-to-date</div>

                    <FormFieldSet 
                        showLabels={false}
                        model={company}
                        fields={['Name']}
                        fieldsData={['name']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'name', value: event?.target?.value}),
                        ]}/>

                    <FormFieldSet 
                        showLabels={false}
                        model={company}
                        fields={['Description']}
                        fieldsData={['description']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'description', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={company}
                        fields={['Phone', '']}
                        fieldsData={['phone', '']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'phone', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={company}
                        fields={['Address line 1', 'Address line 2']}
                        fieldsData={['address1', 'address2']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'address1', value: event?.target?.value}),
                            (event) => formValueChanged({field: 'address2', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={company}
                        fields={['City', 'County']}
                        fieldsData={['city', 'county']}
                        fieldsLookup={{county: irishCounties}}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'city', value: event?.target?.value}),
                            (event) => formValueChanged({field: 'county', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={company}
                        fields={['Postcode', 'VAT Number']}
                        fieldsData={['postcode', 'vat_number']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'postcode', value: event?.target?.value}),
                            (event) => formValueChanged({field: 'vat_number', value: event?.target?.value}),
                        ]}/>
                    <button 
                        type="button" 
                        className="btn btn-primary login__button"
                        onClick={() => handleSaveClick()}
                        disabled={isLoading}
                        style={{width: '200px'}}
                    >
                        Save
                    </button>
                </div>
            </div>
        </CompanyLayout>
    )
}

export default CompanyProfile;
