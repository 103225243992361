import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import { customerAddJob, customerGetJob, customerUpdateJob } from '../../../services/requests'
import CustomerLayout from '../../../layouts/CustomerLayout'
import DashboardViewItemHeader from '../../../components/Dashboard/ViewItemHeader'
import DashboardAddItemHeader from '../../../components/Dashboard/AddItemHeader'
import DashboardAddItemField from '../../../components/Dashboard/AddItemField'
import DashboardAddItemSummaryField from '../../../components/Dashboard/AddItemSummaryField'
import { getToken, getLocalJob, setLocalJob } from '../../../services/token';
import { formatAddress, getErrorMessage } from '../../../services/utils';
import { irishCounties } from "../../../constants/counties"

const CustomerJobView = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [header1Active, setHeader1Active] = useState(true);
    const [header2Active, setHeader2Active] = useState(false);
    const [header3Active, setHeader3Active] = useState(false);
    const [job, setJob] = useState({description: "", weight: "", size: "", po_number: "",
        forklift_required: "", collection_name: "",
        collection_address1: "", collection_address2: "", collection_city: "",
        collection_county: "", collection_postcode: "", collection_delivery_note: "", 
        deliver_name: "",
        deliver_address1: "", deliver_address2: "", deliver_city: "", deliver_county: "",
        deliver_postcode: "", deliver_by_datetime: "", deliver_by_flexible: ""
    });

    const loadData = async () => {
        if (!isLoading) {
          setIsLoading(true);

          if (id) {
            const resp = await customerGetJob(id);
            var newLocalJob = {...job}
            for (var key in newLocalJob) {
                if (newLocalJob[key] === "" && resp?.job?.data[key] !== undefined) {
                    newLocalJob[key] = resp.job.data[key];
                }
            }
            setLocalJob(newLocalJob);
            updateJob(null, null)
          }
          else {
            setLocalJob(job)
          }          
          setIsLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData('');
        if (id) {
            document.title = 'Smart Haul - Edit Job';
        }
        else {
            document.title = 'Smart Haul - Add Job';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const handleSaveJobClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)

        if (id) {
            try {
                const resp = await customerUpdateJob(id, { ...job });
                if (resp?.result === 'success') {
                    navigate("/customer/jobs", { state: { jobUpdated: true } });
                }                
                else {              
                    setIsLoading(false)
                    setErrorMessage(getErrorMessage(resp))
                    window.scrollTo(0, 0);
                }
            } 
            catch (error) {
                setErrorMessage("There was an error saving this job.")
            }
        }

        else {

            try {
                const resp = await customerAddJob({ ...job });
                if (resp?.result === 'success') {
                    navigate("/customer/jobs", { state: { jobAdded: true } });
                }
                else {              
                    setIsLoading(false)
                    setErrorMessage(getErrorMessage(resp))                    
                    window.scrollTo(0, 0);
                }
            } 
            catch (error) {
                setErrorMessage("There was an error adding this job.")
            }
        }

        setIsLoading(false)
    }

    function handleHeaderClick(header) {
        setHeader1Active(false); 
        setHeader2Active(false); 
        setHeader3Active(false); 
        if (header === 1)  { setHeader1Active(!header1Active); }
        if (header === 2)  { setHeader2Active(!header2Active); }
        if (header === 3)  { setHeader3Active(!header3Active); }
    }

    function updateJobDateTime(field, value) {
        var localJob = getLocalJob();
        localJob[field] = value;
        const newJob = { ...localJob };
        setLocalJob(newJob)
        setJob(newJob)
    }

    function updateJob(field, value) {

        var localJob = getLocalJob();
        if (field) {
            localJob[field] = value;
        }
        const newJob = { ...localJob };
        setLocalJob(newJob)
        setJob(newJob)
    }

    function handleUpdateJob(event) {        
        updateJob(event.target.name, event.target.value);
    }

    return <>
      <CustomerLayout breadcrumbs={['Jobs', "ID: " + job?.id]}>

<div className="row">
  <div className="col-md-8">

    <DashboardViewItemHeader />    

    {errorMessage ? 
    <>
    <div dangerouslySetInnerHTML={{__html: errorMessage}} 
        className="alert alert-danger" role="alert">        
    </div>
    </> : ''}

        {id ?
        <h1 className="dashboard-page--title">Edit a Job</h1>
        :
        <h1 className="dashboard-page--title">List a Job</h1>
        }

    <DashboardAddItemHeader active={header1Active} number={1} title={"Description"} openClose={() => handleHeaderClick(1)}/>
    <div hidden={!header1Active}>
    <DashboardAddItemField names={['description']} titles={['Description']} types={['textarea']} 
        values={[job?.description]} update={handleUpdateJob }/> 

    <DashboardAddItemField names={['weight', 'size']} titles={['Weight', 'Size']} types={['input', 'input']}  
        values={[job?.weight, job?.size]}
        update={handleUpdateJob} /> 
    <DashboardAddItemField 
        names={['po_number', 'forklift_required']}
        titles={['PO Number', 'Forklift Required']}
        types={['input', 'radio']} 
        radioTitles={['Yes', 'No']}
        radioValues={['1', '0']}
        radioSelected={job?.forklift_required}
        update={handleUpdateJob}
        updateOptions={updateJob}
        values={[job?.po_number]}
        /> 
    </div>

    <DashboardAddItemHeader active={header2Active} number={2} title={"Collection"} openClose={() => handleHeaderClick(2)} />
    <div hidden={!header2Active}>
    <DashboardAddItemField names={['collection_name']}
        titles={['Name/Company']} types={['input']} 
        values={[job?.collection_name]} update={handleUpdateJob} /> 
    <DashboardAddItemField names={['collection_address1', 'collection_address2']} 
        titles={['Address 1', 'Address 2']} types={['input', 'input']} 
        values={[job?.collection_address1, job?.collection_address2]}
        update={handleUpdateJob} />     
    <DashboardAddItemField names={['collection_city', 'collection_county']}
        titles={['City', 'County']} types={['input', 'lookup']} 
        fieldsLookup={{collection_county: irishCounties}}
        values={[job?.collection_city, job?.collection_county]} update={handleUpdateJob} /> 
    <DashboardAddItemField names={['collection_postcode', '']}
        titles={['Postcode', '']} types={['input', 'input']} 
        values={[job?.collection_postcode]} update={handleUpdateJob} /> 
    <DashboardAddItemField names={['collection_delivery_note']}
        titles={['Collection/Delivery Note (additional instructions on picking up or delivery package).']}
        values={[job?.collection_delivery_note]} types={['textarea']} update={handleUpdateJob} /> 
    </div>

    <DashboardAddItemHeader active={header3Active} number={3} title={"Delivery"} openClose={() => handleHeaderClick(3)} />
    <div hidden={!header3Active}>
    <DashboardAddItemField names={['deliver_name']}
        titles={['Name/Company']} types={['input']} 
        values={[job?.deliver_name]} update={handleUpdateJob} /> 
    <DashboardAddItemField names={['deliver_address1', 'deliver_address2']}
        titles={['Address 1', 'Address 2']} 
        values={[job?.deliver_address1, job?.deliver_address2]} types={['input', 'input']} update={handleUpdateJob} /> 
    <DashboardAddItemField names={['deliver_city', 'deliver_county']}
        titles={['City', 'County']} types={['input', 'lookup']} 
        values={[job?.deliver_city, job?.deliver_county]} 
        fieldsLookup={{deliver_county: irishCounties}}
        update={handleUpdateJob} /> 
    <DashboardAddItemField names={['deliver_postcode', '']}
        titles={['Postcode', '']} types={['input', 'input']} 
        values={[job?.deliver_postcode]} update={handleUpdateJob} /> 
    <DashboardAddItemField names={['deliver_by_datetime', 'deliver_by_flexible']}
        titles={['Deliver by datetime (Optional)', 'Flexible on delivery time (in days)']} 
        types={['datetime', 'number']} 
        update={handleUpdateJob} 
        updateDateTime={updateJobDateTime} 
        values={[
            job?.deliver_by_datetime, 
            job?.deliver_by_flexible]}
        />         
    </div>

  </div>
  <div className="col-md-4">

    <button   
        className="btn btn-primary"
        style={{width:'165px', marginTop: '60px', height: '52px', float: 'right', marginBottom: '30px'}}
            onClick={() => handleSaveJobClick()}
            disabled={isLoading}
        >Save Job</button>

    <div className="add-item__summary-box">
        <h1 className="add-item__summary-title">Summary</h1>
        <DashboardAddItemSummaryField names={['Description']} values={[job?.description]} />
        <DashboardAddItemSummaryField names={['Weight', 'Size']} values={[job?.weight, job?.size]} /> 
        <DashboardAddItemSummaryField names={['PO Number', 'Forklift']} values={[job?.po_number, 
            job?.forklift_required === "1" ? 'Yes' : 
            job?.forklift_required === "" ? '' : 'No']} /> 
        <hr className="add-item__summary-hr" />
        <DashboardAddItemSummaryField names={['Collection Address']} 
            values={[formatAddress(job?.collection_name, job?.collection_address1, job?.collection_address2, job?.collection_city, 
                    job?.collection_county, job?.collection_postcode)]} /> 
        <hr className="add-item__summary-hr" />
        <DashboardAddItemSummaryField names={['Delivery Address']} 
            values={[formatAddress(job?.deliver_name, job?.deliver_address1, job?.deliver_address2, job?.deliver_city,
            job?.deliver_county, job?.deliver_postcode)]} /> 
        <DashboardAddItemSummaryField names={['Deliver by datetime (Optional)', 'Flexible on delivery time']} 
            values={[job?.deliver_by_datetime, 
                    (job?.deliver_by_flexible !== ""
                        && job?.deliver_by_flexible !== false) ? job?.deliver_by_flexible + " day/s" : '-']} />
    </div>    

  </div>
</div>

      </CustomerLayout>
    </>
}

export default CustomerJobView;
