
import LogoMain from "../assets/imgs/logo-main.png"
import LogoMainDark from "../assets/imgs/logo-main-dark.png"
import IconSocialFacebook from "../assets/icons/icon-social-fb.png"
import IconSocialInstagram from "../assets/icons/icon-social-insta.png"
import IconSocialX from "../assets/icons/icon-social-x.png"
import DashboardProfile from "../components/Dashboard/Profile";
import "./css/Sidebar.css"
import "./css/CustomerLayout.css"
import "./css/CompanyLayout.css"
import { useNavigate } from "react-router-dom"

export default function CompanyLayout({ children, breadcrumbs, selected }) {

    const navigate = useNavigate();
    const handleAvailableJobsClick = () => {
        navigate('/company/jobs')
    }
    const handleCurrentJobsClick = () => {
        navigate('/company/jobs/current')
    }
    const handleDriversClick = () => {
        navigate('/company/drivers')
    }

    return (
    <>
<div className="container">
    <div className="row" style={{paddingTop: '20px'}}>
        <div className="col-4" style={{paddingLeft: '100px'}}>
            <img src={LogoMainDark} alt="logo" style={{height:'42px', width: '95px'}} />
        </div>
        <div className="col-4" style={{paddingTop: '10px', textAlign: 'center'}}>
                        <span 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleAvailableJobsClick()}
                            className={selected === "available_jobs" ? 
                            'companyheader__menu companyheader__menu-selected' : 
                            'companyheader__menu'} 
                        >Available Jobs</span>
                        <span 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleCurrentJobsClick()}
                            className={selected === "current_jobs" ? 
                            'companyheader__menu companyheader__menu-middle companyheader__menu-selected' : 
                            'companyheader__menu companyheader__menu-middle'}
                        >Current Jobs</span>
                        <span 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleDriversClick()}
                            className={selected === "drivers" ? 
                            'companyheader__menu companyheader__menu-selected' : 
                            'companyheader__menu'}                         
                        >Drivers</span>
        </div>
        <div className="col-4" style={{minWidth: "200px", paddingRight: '70px' }}>
            <DashboardProfile />
        </div>                
    </div>
</div>

<hr style={{height: '1px'}}/>

<div className="container">
    <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10" style={{marginTop: '15px', marginBottom: '25px'}}>
            {children}
        </div>
        <div className="col-md-1"></div>
    </div>
</div>

<footer className="text-center">
    <div className="container text-center text-md-start">
      <div className="row">
        <div className="col-md-4 mx-auto text-center align-middle" style={{paddingTop: '18px'}}>
            <img src={LogoMain} alt="logo" style={{height:'42px', width: '95px'}} />
        </div>
        <div className="col-md-4 mx-auto text-center text-nowrap" style={{paddingTop: '28px'}}>
            <span className="footer__text">&copy; 2024 Smart Haul</span>
            <span className="footer__text">&nbsp;&nbsp;|&nbsp;&nbsp;</span> <a className="footer__link" href="#/">Privacy Policy</a>
            <span className="footer__text">&nbsp;&nbsp;|&nbsp;&nbsp;</span> <a className="footer__link" href="#/">Terms & Conditions</a>
        </div>
        <div className="col-md-4 mx-auto text-center" style={{paddingTop: '25px'}}>
            <img className="footer__social-icon" src={IconSocialFacebook} alt="facebook" />
            <img className="footer__social-icon" src={IconSocialInstagram} alt="instagram" />
            <img className="footer__social-icon" src={IconSocialX} alt="x" />
        </div>
      </div>
    </div>
</footer>
    </>
  )
}