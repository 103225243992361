import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css";
import "./JobsList.css";
import { companyListJobsBidOn } from '../../../services/requests';
import CompanyLayout from '../../../layouts/CompanyLayout';
import { getToken } from '../../../services/token';
import IconNoJobs from "../../../../src/assets/icons/icon-no-jobs.png";
import IconUnapproved from "../../../../src/assets/icons/icon-unapproved.png";
import JobsTable from "../../../components/Dashboard/JobsTable";
import { getErrorMessage } from "../../../services/utils"

const CompanyCurrentJobsList = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [jobs, setJobs] = useState(null);
    const [companyApproved, setCompanyApproved] = useState(false);

    const loadData = async (pageSupplied) => {
        if (!isLoading) {
            setLoading(true);
            var localPage = 1;
            if (searchParams.get('page') || pageSupplied) {
                searchParams.set('page', pageSupplied);
                localPage = searchParams.get('page');
            }

            const resp = await companyListJobsBidOn(localPage, 
                inputWonRef?.current?.checked,
                inputDeliveredRef?.current?.checked);   
            setCompanyApproved(true);

            if (resp?.result === 'error') {
                if (getErrorMessage(resp) === "Company user is not approved.") {
                    setCompanyApproved(false);
                }
            }
            setJobs(resp?.jobs);
            setLoading(false);
        }
    };

    const inputWonRef = useRef(null);
    const inputDeliveredRef = useRef(null);

    useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData('');
        document.title = 'Smart Haul - Company - Current Jobs';

        const intervalId = setInterval(() => {
            loadData('');
        }, 60000);
        return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount

        // eslint-disable-next-line
    }, []);

    const handleClickFilterText = (type) => {
        if (type === "won") { 
            inputWonRef.current.checked = !inputWonRef.current.checked 
            loadData(1)
        }
        if (type === "delivered") { 
            inputDeliveredRef.current.checked = !inputDeliveredRef.current.checked 
            loadData(1)
        }
    }

    const handleClickFilter = (type) => {        
        loadData(1)
    }

    const viewRow = async (id) => {
        navigate("/company/jobs/current/" + id);
    }

    return (
        <>
            <CompanyLayout breadcrumbs={['Companies']} selected={"current_jobs"}>

                {!companyApproved ?
                    <>
                        <div className="container">
                            <div className="dashboard-page__no-items-box row align-items-center justify-content-center">
                                <img src={IconUnapproved} alt="no jobs" style={{ width: '200px', height: '200px', paddingBottom: '25px' }} />
                                <h1 className="dashboard-page__no-items-title">Waiting for account approval.</h1>
                                <div className="dashboard-page__no-items-subtitle">
                                    Your account is waiting for our administrator approval. Please check back later.
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>

                        <h1 className="dashboard-page--title">Current Jobs</h1>

                        <div className="row company-jobs__filter-box">
                            <div className="company-jobs__filter-box-title">Filter</div>

                            <div className="checkbox-container col">
                                <input ref={inputWonRef} type="checkbox" className="checkbox-input"
                                    onClick={() => handleClickFilter('won')}
                                />
                                <span className="checkbox-label" onClick={() => handleClickFilterText('won')}>Bid Won</span>

                                <input ref={inputDeliveredRef} type="checkbox" className="checkbox-input"
                                    onClick={() => handleClickFilter('delivered')}
                                />
                                <span className="checkbox-label" onClick={() => handleClickFilterText('delivered')}>Delivered</span>
                            </div>

                        </div>

                        {jobs?.data?.length > 0 ?
                            <>
                                <JobsTable
                                    jobs={jobs}
                                    loadData={(page) => loadData(page)}
                                    viewRow={(id) => viewRow(id)}
                                />
                            </>
                            :
                            <div className="container">
                                <div className="dashboard-page__no-items-box row align-items-center justify-content-center">
                                    <img src={IconNoJobs} alt="no jobs" style={{ width: '200px', height: '200px' }} />
                                    <h1 className="dashboard-page__no-items-title">There are no jobs currently available.</h1>
                                </div>
                            </div>
                        }
                    </>
                }
            </CompanyLayout>
        </>
    );
};

export default CompanyCurrentJobsList;
