import React, { useState, useEffect } from "react";
import './Login.css';
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../../services/utils";
import { signin } from '../../services/requests';
import LogoMain from "../../assets/imgs/logo-main-dark.png"
import { getToken, setToken, getRole } from '../../services/token';

const Login = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputs, setInputs] = useState({ email: "", password: "" });

    const inputChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = inputs;

        setIsLoading(true);
        try {
            const resp = await signin({ email, password });
            if (resp.token) {
                setToken(resp?.token, resp?.role, resp?.email, resp?.name, resp?.role_id)
                if (getRole() === "customer") {
                    navigate("/customer/jobs")
                } else if (getRole() === "company") {
                    navigate("/company/jobs")
                }
            } else if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
        } catch (error) {
            console.error("Login Error:", error);
            setErrorMessage("An unexpected error occurred.");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (getToken()) {
            if (getRole() === "customer") {
                navigate("/customer/jobs")
            } else if (getRole() === "company") {
                navigate("/company/jobs")
            }
        }
        document.title = 'Smart Haul - Login';

        // Check for auto-fill values after component is mounted
        const emailInput = document.getElementById("email");
        const passwordInput = document.getElementById("password");
        if (emailInput.value && passwordInput.value) {
            setInputs({ email: emailInput.value, password: passwordInput.value });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="login__background">
            <div className="div-center">
                <div className="content" style={{ textAlign: 'center' }}>
                    <a href="/"><img src={LogoMain} style={{ width: '200px' }} className="login__logo" alt="" /></a>

                    {errorMessage ?
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} className="alert alert-danger" role="alert"></div>
                        : null}

                    <h1 className="login__header">Welcome back</h1>

                    <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <div className="login_input-wrapper">
                                <input
                                    onChange={inputChange}
                                    value={inputs.email}
                                    type="email"
                                    className="form-control login_input"
                                    id="email"
                                    name="email"
                                    placeholder=" " />
                                <label htmlFor="email">Email Address</label>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="login_input-wrapper">
                                <input
                                    onChange={inputChange}
                                    value={inputs.password}
                                    type='password'
                                    className="form-control login_input"
                                    name="password"
                                    id="password"
                                    placeholder=" " />
                                <label htmlFor="password">Password</label>
                            </div>

                            <div className="text-end"
                                style={{ marginBottom: '15px', marginTop: '-5px' }}
                            ><a className="login__forgot-link" href="/forgot">Forgot password?</a></div>
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary login__button"
                            disabled={isLoading}
                        >Login</button>
                    </form>

                    <div className="login__footer-text">
                        <b>Don't have an account?</b> <br />
                        <a className="login__footer-link" href="/customer/signup">Sign up</a> as shipper or
                        &nbsp;<a className="login__footer-link" href="/company/signup">sign up</a> as carrier.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
