import React, {useState, useEffect} from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import {
    adminListJobs,
  } from '../../../services/requests'
import Layout from '../../../layouts/AdminLayout'
import DashboardTable from '../../../components/Dashboard/Table'
import { getToken } from '../../../services/token';

const AdminJobsList = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [jobs, setJobs] = useState(null);

    const loadData = async (pageSupplied, showDeleted) => {
        if (!isLoading) {
          setLoading(true);
          var localPage = 1
          var localShowDeleted = ''

          if (searchParams.get('page') || pageSupplied) {
            searchParams.set('page', pageSupplied)
            localPage = searchParams.get('page')
          }
          if (searchParams.get('showdeleted') || showDeleted) {
            searchParams.set('showdeleted', showDeleted)
            localShowDeleted = searchParams.get('showdeleted')
          }
          const resp = await adminListJobs(localPage, localShowDeleted);
          setJobs(resp?.jobs);
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }

        loadData('');
        document.title = 'Smart Haul - Jobs';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const viewRow = async (id) => {    
      navigate("/admin/jobs/" + id);
    }
    const handleDeletedJobsClick = async (id) => {
      if (!searchParams.get('showdeleted')) {
        loadData(1, true)
      }
      else {
        loadData(searchParams.get('page'), '')
      }      
    }

    return <>
      <Layout breadcrumbs={['Jobs']}>
        <div style={{width:'100%'}}>
          <div style={{width: '250px', float: 'left'}}>
            <h1 className="dashboard-page--title">Jobs</h1>
          </div>
          <div style={{width: '250px', float: 'right'}}>
            <button 
                className={!searchParams.get('showdeleted') ? "btn btn-secondary" : "btn btn-primary"}
                style={{width:'165px', marginTop: '30px', float: 'right'}}
                onClick={() => handleDeletedJobsClick()}
                >Deleted Jobs</button>
          </div>
        </div>
        
          {searchParams.get('updated') ? (
            <div className={'alert alert-success'}>The job has now been saved.</div>
          ) : null}                
          {searchParams.get('deleted') ? (
            <div className={'alert alert-success'}>The job has now been deleted.</div>
          ) : null}                

        <DashboardTable 
          columns={['Customer Name', 'Company Name', 'Job Status', 'Lowest Bid', 'Highest Bid']}
          columnsData={jobs}
          columnsFields={['customer_name', 'company_name', 'status', 'bid_lowest', 'bid_highest']}
          loadData={(page) => loadData(page)}
          viewRow={(id) => viewRow(id)}
        />        
      </Layout>
    </>
}

export default AdminJobsList;
