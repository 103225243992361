import React, {useState, useEffect} from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import {
    adminApproveCompany,
    adminListCompanies,
  } from '../../../services/requests'
import Layout from '../../../layouts/AdminLayout'
import DashboardTable from '../../../components/Dashboard/Table'
import { getToken } from '../../../services/token';

const AdminCompaniesList = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [companies, setCompanies] = useState(null);

    const loadData = async (pageSupplied) => {
        if (!isLoading) {
          setLoading(true);
          var localPage = 1

          if (searchParams.get('page') || pageSupplied) {
            searchParams.set('page', pageSupplied)
            localPage = searchParams.get('page')
          }

          const resp = await adminListCompanies(localPage);
          setCompanies(resp?.companies);          
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }

        loadData('');
        document.title = 'Smart Haul - Companies';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const viewRow = async (id) => {    
      navigate("/admin/companies/" + id);
    }
    const editRow = async (id) => {    
      navigate("/admin/companies/" + id + "/?edit=true")
    }
    const deleteRow = async (id) => {    
      navigate("/admin/companies/" + id + "/?delete=true")
    }
    const approveRow = async (id) => {    
      await adminApproveCompany(id).then((results) => {
        loadData()
      })
    }

    return <>
      <Layout breadcrumbs={['Companies']}>
        <h1 className="dashboard-page--title">Companies</h1>

          {searchParams.get('updated') ? (
            <div className={'alert alert-success'}>The company has now been saved.</div>
          ) : null}                
          {searchParams.get('deleted') ? (
            <div className={'alert alert-success'}>The company has now been deleted.</div>
          ) : null}                

        <DashboardTable 
          columns={['Name', 'Email', 'Phone', 'Description', 'Approved']}
          columnsData={companies}
          columnsFields={['name', 'email', 'phone', 'description', 'approved']}
          
          loadData={(page) => loadData(page)}

          viewRow={(id) => viewRow(id)}
          editRow={(id) => editRow(id)}
          deleteRow={(id) => deleteRow(id)}
          approveRow={(id) => approveRow(id)}
        />        
      </Layout>
    </>
}

export default AdminCompaniesList;
