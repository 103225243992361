import React, {useState, useEffect} from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import {
    adminListCustomers,
  } from '../../../services/requests'
import Layout from '../../../layouts/AdminLayout'
import DashboardTable from '../../../components/Dashboard/Table'
import { getToken } from '../../../services/token';

const AdminCustomersList = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [customers, setCustomers] = useState(null);

    const loadData = async (pageSupplied) => {
        if (!isLoading) {
          setLoading(true);
          var localPage = 1

          if (searchParams.get('page') || pageSupplied) {
            searchParams.set('page', pageSupplied)
            localPage = searchParams.get('page')
          }

          const resp = await adminListCustomers(localPage);
          setCustomers(resp?.customers);          
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }

        loadData('');
        document.title = 'Smart Haul - Customers';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const viewRow = async (id) => {    
      navigate("/admin/customers/" + id);
    }
    const editRow = async (id) => {    
      navigate("/admin/customers/" + id + "/?edit=true")
    }
    const deleteRow = async (id) => {    
      navigate("/admin/customers/" + id + "/?delete=true")
    }

    return <>
      <Layout breadcrumbs={['Customers']}>
        <h1 className="dashboard-page--title">Customers</h1>

          {searchParams.get('updated') ? (
            <div className={'alert alert-success'}>The customer has now been saved.</div>
          ) : null}                
          {searchParams.get('deleted') ? (
            <div className={'alert alert-success'}>The customer has now been deleted.</div>
          ) : null}                

        <DashboardTable 
          columns={['Name', 'Phone', 'Email']}
          columnsData={customers}
          columnsFields={['name', 'phone', 'email']}
          
          loadData={(page) => loadData(page)}
          viewRow={(id) => viewRow(id)}
          editRow={(id) => editRow(id)}
          deleteRow={(id) => deleteRow(id)}
        />        
      </Layout>
    </>
}

export default AdminCustomersList;
