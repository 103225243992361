import React, {useState, useEffect} from "react";
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import { customerGetJob, customerAcceptBid } from '../../../services/requests'
import CustomerLayout from '../../../layouts/CustomerLayout'
import DashboardTable from '../../../components/Dashboard/Table'
import { getToken } from '../../../services/token';
import IconNoJobs from "../../../../src/assets/icons/icon-no-jobs.png"
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { getErrorMessage } from "../../../services/utils";
import IconBackArrow from "../../../assets/icons/icon-back-arrow.png"

const CustomerBidsList = () => {

    const {id} = useParams()    
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [job, setJob] = useState(null);
    const [bid, setBid] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);
    function navigateBack() {
        navigate(-1)
    }

    const loadData = async (pageSupplied, showDeleted) => {
        if (!isLoading) {
          setIsLoading(true);
          if (id) {
            const resp = await customerGetJob(id);            
            setJob(resp?.job?.data);
          }
          setIsLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }

        loadData('');
        document.title = 'Smart Haul - Customer - Job Bids';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const acceptRow = async (id) => {    
        setShowAcceptConfirmation(true)
        job?.bids?.data?.map((bidLocal) => {
            if (bidLocal.id === id) {
                setBid(bidLocal)
            }
            return null
        })
        setErrorMessage(null)
        setShowAcceptConfirmation(true)
    }  

    const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
    const handleCloseAcceptConfirmation = () => setShowAcceptConfirmation(false);
    const handleConfirmAccept = async (event) => {    
        setIsLoading(true)
        try {
            const resp = await customerAcceptBid(id, bid?.id);
            console.log(resp)
            if (resp?.result === 'success') {
                navigate("/customer/jobs/" + id + "/?accepted=true")
            }
            else {        
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp));
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage("There was an error accepting the bid.")
        }
    }

    var dateMoment = moment(bid?.created_at)
    var dateValue = dateMoment.format("Do MMM [at] h:m A")

    return <>
      <CustomerLayout breadcrumbs={['Customers']}>

            <div className="dashboard--view-item-header" onClick={() => navigateBack()} style={{marginLeft: '0px'}}>
                <img className="dashboard--view-item-header-backicon" src={IconBackArrow} alt="" />
                Back
            </div>

            <Modal show={showAcceptConfirmation} onHide={handleCloseAcceptConfirmation} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Accept this bid?                  
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">

              {errorMessage ? 
                <>
                <div className="container" style={{width: '100%'}}>
                  <div className="row edit--form_row">
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                </div>
                </> : ''}

                <p>After accepting this bid and receiving a confirmation from a company, you will be required to pay for the job.</p>

            <div className="container" style={{width: '95%', paddingBottom: '25px'}}>
                <div className="row" style={{paddingBottom: '15px'}}>
                    <div className="col">
                        <div className="modal_subtitle">Cost</div>
                        <div className="modal_subtitle-value">{bid?.amount}</div>
                    </div>
                    <div className="col">
                        <div className="modal_subtitle">Date</div>
                        <div className="modal_subtitle-value">{dateValue}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="modal_subtitle">Note</div>
                        <div className="modal_subtitle-value">{bid?.note}</div>
                    </div>
                </div>
            </div>                

              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                    <button type="button" className="btn btn-secondary" 
                        disabled={isLoading}
                        onClick={() => handleCloseAcceptConfirmation()}>Cancel</button>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-primary" 
                        disabled={isLoading}
                        onClick={() => handleConfirmAccept()}>Accept</button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>     

        {searchParams.get('updated') ? (
            <div className={'alert alert-success'}>The job has now been saved.</div>
          ) : null}                
          {searchParams.get('deleted') ? (
            <div className={'alert alert-success'}>The job has now been deleted.</div>
          ) : null}                
          {searchParams.get('added') ? (
            <div className={'alert alert-success'}>The job has now been added.</div>
          ) : null}                

      {job?.bids?.data?.length > 0 ?
<>
        <div style={{width:'100%'}}>
          <div style={{width: '250px', float: 'left'}}>
            <h1 className="dashboard-page--title">Bids</h1>
          </div>
          <div style={{width: '350px', float: 'right'}}>
          </div>
        </div>

        {job?.paid_at ? 
<>
        <DashboardTable 
          columns={['Bid', 'Company Name', 'Note', 'Date']}
          columnsData={job?.bids}
          columnsFields={['amount', 'company_name', 'note', 'created_at']}
          loadData={(page) => loadData(page)}
          acceptRow={job?.status === "pending" ? (id) => acceptRow(id) : null}
          hideEdit={true}
          hideDelete={true}
          showPaging={"false"}
        />        
</>
:
<>
        <DashboardTable 
          columns={['Bid', 'Note', 'Date']}
          columnsData={job?.bids}
          columnsFields={['amount', 'note', 'created_at']}
          loadData={(page) => loadData(page)}
          acceptRow={job?.status === "pending" ? (id) => acceptRow(id) : null}
          hideEdit={true}
          hideDelete={true}
          showPaging={"false"}
        />        
</>
}
</>      

      : 
<>
<div className="container">
  <div className="dashboard-page__no-items-box row align-items-center justify-content-center">

    <img src={IconNoJobs} alt="no bids" style={{width: '200px', height: '200px'}}/>
    <h1 className="dashboard-page__no-items-title">You don’t have any bids on this job</h1>

  </div>
</div>
</>
      }

      </CustomerLayout>
    </>
}

export default CustomerBidsList;
