import { formatDateTime } from "../../services/utils"

export default function DashboardAddItemSummaryField(props) {

    let content = [];
    props?.names?.map((name, index) => {
    
        content[index] = (
                    <>
            <div className="form-group">
                <div className="login_input-wrapper">
                    <div className="add-item__summary-subtitle">
                        {name}
                    </div>
                    <div className="add-item__summary-value">
                        {props?.values[index] === "" ? "-" :
                            name.includes("datetime") ? formatDateTime(props?.values[index]) : 
                            props?.values[index]}
                    </div>                    
                </div>  
            </div>
                    </>
        );

        return null;
    })

    if (content[1]) {
        return (
<>
<div className="row" style={{width: '100%'}}>
    <div className="col-md-6">
        {content[0]}
    </div>
    <div className="col-md-6">
        {content[1] === "empty" ? '' : 
        content[1]}
    </div>
</div>

</>
        )
    } else{
        return (
            <>
            {content[0]}
            </>
        )
    }
    
}