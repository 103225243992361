import React, {useState, useEffect, useRef} from "react";
import { useParams } from "react-router-dom";
import {driverSetPassword, driverCheckSecret, getApiUrl} from "../../services/requests"
import {getErrorMessage} from "../../services/utils";
import LogoMain from "../../assets/imgs/logo-main-dark.png"
import AndroidStore from "../../assets/imgs/android-store.png"
import AppleStore from "../../assets/imgs/apple-store.png"

const DriverPassword = () => {

    const {secret} = useParams()
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputs, setInput] = useState({password: "", password_confirmation: ""});
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isSaved, setIsSaved] = useState(false)
    const [isSecretValid, setIsSecretValid] = useState(false)

    const checkSecret = async () => {
        var body = { 
            secret: secret
        }
        const resp = await driverCheckSecret({ ...body });
        if (resp?.result === "success") {
            setIsSecretValid(true)
        }
        return resp;
    }

    useEffect(() => {
        checkSecret();
        // eslint-disable-next-line
    }, []);

    const inputChange = (e) => {
        setInput({...inputs, [e.target.name]: e.target.value});

        if (inputs['password'] !== "" && inputs['password_confirmation'] !== "") {
            setIsFormComplete(true)
        }
        else {
            setIsFormComplete(true)
        }
    }

    const handleSaveClick = async (event) => {
        var body = { 
            secret: secret,
            password: passwordRef.current.value,
            password_confirmation: passwordConfirmationRef.current.value,
        }

        if (!isLoading) {
            setIsLoading(true);
            const resp = await driverSetPassword({ ...body });

            if (resp?.result === "success") {
                setIsSaved(true)
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        document.title = 'Smart Haul - Save Password';

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
              handleSaveClick();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };        

        // eslint-disable-next-line
    }, []);

    return <>
<div className="login__background">

<div className="div-center">

  <div style={{textAlign:'center'}}>

    <img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/>

    {errorMessage ? 
    <>
    <div className="alert alert-danger" role="alert">{errorMessage}</div>
    </> : ''}
    
    <h1 className="login__header">Set password</h1>

{!isSecretValid ?  
<>
<div className="login__header-info-text">
The link you've followed is no longer valid. Please contact the company that sent you this link.
</div>
<div className="login__header-info-text" style={{paddingTop: '40px'}}>
<b>You can download the SmartHaul driver app from the App Store or Google Play Store below:</b>
</div>

<a href={getApiUrl() + '/android'}><img src={AndroidStore} style={{height: '65px'}} alt=""/></a>
<a href={getApiUrl() + '/apple'}><img src={AppleStore} style={{height: '45px'}} alt=""/></a>
</>
: 
!isSaved ?
<>
    <div className="login__header-info-text">
    Enter a new password so that you can login to the app on your phone.
    </div>

        <div className="form-group">
            <div className="login_input-wrapper">
                <input onChange={inputChange} 
                    ref={passwordRef}
                    type="password" 
                    className="form-control login_input" 
                    id="password" 
                    name="password" 
                    placeholder=" " />
                <label htmlFor="password">Password</label>
            </div>  
            <div className="login_input-wrapper">
                <input onChange={inputChange} 
                    ref={passwordConfirmationRef}
                    type="password" 
                    className="form-control login_input" 
                    id="password_confirmation" 
                    name="password_confirmation" 
                    placeholder=" " />
                <label htmlFor="password_confirmation">Retype Password</label>
            </div>  
        </div>

        <button type="button" className="btn btn-primary login__button"
            disabled={!isFormComplete || isLoading}
            onClick={() => handleSaveClick()}
        >Save Password</button>
</>
: 
<>
<div className="login__header-info-text">
Thank you. Your password has been saved and you can now login to the SmartHaul mobile app.
</div>

<div className="login__header-info-text" style={{paddingTop: '40px'}}>
<b>You can download the SmartHaul driver app from the App Store or Google Play Store below:</b>
</div>

<a href={getApiUrl() + '/android'}><img src={AndroidStore} style={{height: '65px'}} alt=""/></a>
<a href={getApiUrl() + '/apple'}><img src={AppleStore} style={{height: '45px'}} alt=""/></a>
</>
}

  </div>
</div>
</div>
    </>
    
}

export default DriverPassword;
