import React, { useState, useEffect } from "react";
import '../../Auth/Login.css';
import { useNavigate } from "react-router-dom";
import {
    companySignUp
} from "../../../services/requests"
import LogoMain from "../../../assets/imgs/logo-main-dark.png"
import FormFieldSet from '../../../components/Dashboard/FormFieldSet'
import { setToken } from "../../../services/token";
import { irishCounties } from "../../../constants/counties"
import IconPlus from '../../../assets/icons/icon-plus.png'
import IconPdf from '../../../assets/icons/icon-pdf.png'
import IconPng from '../../../assets/icons/icon-png.png'
import IconJpg from '../../../assets/icons/icon-jpg.png'
import IconTrash from '../../../assets/icons/icon-trash.png'
import {useDropzone} from 'react-dropzone';
import { getErrorMessage } from "../../../services/utils";

const CompanySignUp = () => {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [company, setCompany] = useState({
        name: "",
        description: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        address1: "",
        address2: "",
        city: "",
        county: "",
        postcode: "",
        vat_number: "",
    });

    const formValueChanged = async (fieldValue) => {
        var myValue = fieldValue['field']
        setCompany({ ...company, [myValue]: fieldValue['value'] })
    }

    const handleSignUpClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)

        var body = {
            name: company?.name,
            description: company?.description,
            email: company?.email,
            phone: company?.phone,            
            password: company?.password,
            password_confirmation: company?.password_confirmation,
            address1: company?.address1,
            address2: company?.address2,
            city: company?.city,
            county: company?.county,
            postcode: company?.postcode,
            vat_number: company?.vat_number,
        };

        var documents = []
        var documentTitles = []
        files.map((file) => {
            documents.push(file?.object)
            documentTitles.push(file?.title)
            return null
        })
        if (documents) {
            body = {...body, 
                documents: documents,
                document_titles: documentTitles
            }    
        }

        try {
            const resp = await companySignUp({ ...body });
            if (resp?.result === 'success') {
                setToken(resp?.token, resp?.role, resp?.email, resp?.name, resp?.role_id)
                navigate("/company/jobs")
            }
            else {
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp))
                window.scrollTo(0, 0);
            }
        } catch (error) {
            setErrorMessage("There was an error signing up as a company.")
        }
    }

    const [files, setFiles] = useState([]); 
    useEffect(() => {
        document.title = 'Smart Haul - Carrier Signup';
        // eslint-disable-next-line
    }, [files]);

    const uploadFile = async (acceptedFile) => {
        var acceptedFileObject = acceptedFile        
        var fileToStore = {
            object: acceptedFileObject[0],
            title: "Untitled"
        }
        const newFiles = [...files, fileToStore];
        setFiles(newFiles)
    }

    const { getRootProps:getRootPropsMain, getInputProps:getInputPropsMain  } =  useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFile) => {
            uploadFile(acceptedFile);
        },
    });   
    const handleImageMainChange = async (event) => {
        var acceptedFileObject = event?.target?.files
        uploadFile(acceptedFileObject)
    };

    // const { getRootProps:getRootPropsMain, getInputProps:getInputPropsMain  } = useDropzone({
    //     accept: 'image/*',
    //     onDrop: (acceptedFile) => {
    //         var fileToStore = {
    //             object: acceptedFile,
    //             title: "Untitled"
    //         }        
    //         const newFiles = [...files, fileToStore];
    //         setFiles(newFiles)
    //     },
    // });   
    // const handleImageMainChange = (event) => {
    //     var acceptedFileObject = event?.target?.files[0]
    //     var fileToStore = {
    //         object: acceptedFileObject,
    //         title: "Untitled"
    //     }        
    //     const newFiles = [...files, fileToStore];
    //     setFiles(newFiles)
    // };

    return <>    
        <div className="login__background">

            <div className="div-center" style={{ width: '700px', marginTop: '50px', paddingBottom: '50px' }}>

                <div className="content" style={{ textAlign: 'center' }}>
                    <a href="/"><img src={LogoMain} style={{ width: '200px' }} className="login__logo" alt="" /></a>

                    {errorMessage ?
                        <>
                            <div dangerouslySetInnerHTML={{ __html: errorMessage }}
                                className="alert alert-danger" role="alert">
                            </div>
                        </> : ''}

                    <h1 className="login__header">Create a carrier account</h1>

                    <p style={{marginTop: -20, marginBottom: 40}}>
                        Are you a shipper looking to register? <a className="login__footer-link" href="/customer/signup">Sign up here instead</a>.
                    </p>

                    <FormFieldSet
                        showLabels={false}
                        model={company}
                        fields={['Company Name']}
                        fieldsData={['name']}
                        fieldsOnChange={[
                            (event) => formValueChanged({ field: 'name', value: event?.target?.value }),
                        ]} />

                    <FormFieldSet
                        showLabels={false}
                        model={company}
                        fields={['Description']}
                        fieldsData={['description']}
                        fieldsOnChange={[
                            (event) => formValueChanged({ field: 'description', value: event?.target?.value }),
                        ]} />

                    <FormFieldSet
                        showLabels={false}
                        model={company}
                        fields={['Email', 'Phone']}
                        fieldsData={['email', 'phone']}
                        fieldsOnChange={[
                            (event) => formValueChanged({ field: 'email', value: event?.target?.value }),
                            (event) => formValueChanged({ field: 'phone', value: event?.target?.value }),
                        ]} />


                    <FormFieldSet
                        model={company}
                        fields={['Password', 'Confirm Password']}
                        fieldsData={['password', 'password_confirmation']}
                        fieldsOnChange={[
                            (event) => formValueChanged({ field: 'password', value: event?.target?.value }),
                            (event) => formValueChanged({ field: 'password_confirmation', value: event?.target?.value }),
                        ]} />
                    <FormFieldSet
                        model={company}
                        fields={['Address line 1', 'Address line 2']}
                        fieldsData={['address1', 'address2']}
                        fieldsOnChange={[
                            (event) => formValueChanged({ field: 'address1', value: event?.target?.value }),
                            (event) => formValueChanged({ field: 'address2', value: event?.target?.value }),
                        ]} />
                    <FormFieldSet
                        model={company}
                        fields={['City', 'County']}
                        fieldsData={['city', 'county']}
                        fieldsLookup={{ county: irishCounties }}
                        fieldsOnChange={[
                            (event) => formValueChanged({ field: 'city', value: event?.target?.value }),
                            (event) => formValueChanged({ field: 'county', value: event?.target?.value }),
                        ]} />
                    <FormFieldSet
                        model={company}
                        fields={['Postcode', 'VAT Number']}
                        fieldsData={['postcode', 'vat_number']}
                        fieldsOnChange={[
                            (event) => formValueChanged({ field: 'postcode', value: event?.target?.value }),
                            (event) => formValueChanged({ field: 'vat_number', value: event?.target?.value }),
                        ]} />

                    <div className="row edit--form_row">
                        <div className="col-md-12">

                            <div className="form-group edit--form_group">
                                <div className="form-group">
                                    <div className="signup_input-wrapper">
                                        <span className="signup_input-wrapper-title-bold">Documents</span>
                                        <span className="signup_input-wrapper-title"> (Licence, insurance details)</span>
<div>

{files?.map((file, index) => {
    const handleOnFocusDocumentTitle = (event) => {
        event.target.select()
    }
    // const handleOnChangeDocumentTitle = (event) => {
    //     files[index].title = event.target.value
    // }

    const handleTitleChange = (index, event) => {
        const updatedFiles = [...files];
        updatedFiles[index].title = event.target.value;
        setFiles(updatedFiles);
    };

    const handleDeleteImageClick = (index) => {
        files.splice(index, 1);
        const newFiles = [...files];
        setFiles(newFiles)
        console.log(newFiles)
    }    
    return (
<>
<div key={index} className="square dashboard--view-documents-box" style={{
    marginTop: '15px',
    marginRight: '10px',
    marginBottom: '10px',
    float: 'left',
    cursor: 'default' }}
>
    <div style={{
        position: "absolute",
        right: '10px',
        top: '10px',
        height: '20px',
        cursor: 'pointer'
    }}
        onClick={() => handleDeleteImageClick(index)}
    >
        <img style={{width: '20px', height: '20px', marginTop: '0px'}} src={IconTrash} alt="delete" />
    </div>

    {file?.object?.type === "application/pdf" ?
    <div><img style={{marginTop: '20px'}} src={IconPdf} alt="PDF" /></div>
    : ''}
    {file?.object?.type === "image/png" ?
    <div><img style={{marginTop: '20px'}} src={IconPng} alt="PNG" /></div>
    : ''}
    {file?.object?.type === "image/jpeg" ?
    <div><img style={{marginTop: '20px'}} src={IconJpg} alt="JPG" /></div>
    : ''}
 
    <div className="dashboard--view-documents-box-title">
        <input type="text" 
            id={index}
            defaultValue={file?.title}
            style={{width: '85%', marginTop: '5px', textAlign: 'center'}}
            onFocus={handleOnFocusDocumentTitle}

            value={file.title} 
            onChange={(event) => handleTitleChange(index, event)} 
            
            // onChange={handleOnChangeDocumentTitle}
        />
    </div>
</div>    
</>
    );
})}

<div {...getRootPropsMain({className: 'dropzone', refKey: 'main'})} 
    className="square dashboard--view-documents-box" 
    style={{float: 'left', marginTop: '15px'}}>
    <input {...getInputPropsMain()} id="imageMain" name="imageMain" onChange={handleImageMainChange} />          
    <img src={IconPlus} alt="plus" style={{height: '33px', width: '33px', marginTop: '50px', marginLeft: '55px'}} />          
</div>    

</div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <button type="button" className="btn btn-primary login__button"
                        onClick={() => handleSignUpClick()}
                        disabled={isLoading}
                    >Sign Up</button>

                    <div className="login__footer-text">Already have an account? <a className="login__footer-link" href="/login">Login</a>.</div>

                </div>
            </div>
        </div>
    </>
}

export default CompanySignUp;
