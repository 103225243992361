import React from 'react';
import FlatpickrComponent from '../FlatpickrComponent'

export default function DashboardAddItemField(props) {

    let content = [];
    const handleOptionChange = (event) => {
      props?.updateOptions(event.target.name, event.target.value)
    };
    const handleDateTimeChange = (_, dateStr, instance) => {
        var [datePart, timePart] = dateStr.split(' ');
        var [day, month, year] = datePart.split("-")
        var finalDate = year + "-" + month + "-" + day + " " + timePart + ":00"
        props?.updateDateTime(instance.element.name, finalDate)
    };

    props?.types?.map((type, index) => {

        if (type === "textarea") {
            content[index] = (
                <>
        <div className="form-group">
            <div className="login_input-wrapper">
                <textarea placeholder={props?.titles[0]} 
                        className="form-control form_input add-item__row-field"
                        style={{height: '120px'}}        
                        onChange={props?.update}
                        id={props?.names[index]}
                        name={props?.names[0]}
                        defaultValue={props?.values[0]}
                ></textarea>
            </div>  
        </div>
                </>
            );
        }

        if (type === "input") {
            if (props?.names[index]) {
                content[index] = (
                    <>
            <div className="form-group">
                <div className="login_input-wrapper">
                    <input
                        type="text" 
                        className="form-control form_input" 
                        id={props?.names[index]}
                        name={props?.names[index]} 
                        placeholder={props?.titles[index]}
                        onChange={props?.update}
                        defaultValue={props?.values[index]}
                    />
                </div>  
            </div>
                    </>
                );    
            }
            else {
                content[index] = "empty";
            }
        }

        if (type === "number") {
            if (props?.names[index]) {
                content[index] = (
                    <>
            <div className="form-group">
                <div className="login_input-wrapper">
                    <input
                        type="number" 
                        className="form-control form_input" 
                        id={props?.names[index]}
                        name={props?.names[index]} 
                        placeholder={props?.titles[index]}
                        onChange={props?.update}
                        defaultValue={props?.values[index]}
                    />
                </div>  
            </div>
                    </>
                );    

            }
            else {
                content[index] = "empty";
            }
        }

        if (type === "datetime") {
            if (props?.names[index]) {
                content[index] = (
                    <>        
            <div className="form-group">
                <div className="login_input-wrapper">
                    <FlatpickrComponent 
                        placeholder={props?.titles[index]} 
                        onDateTimeChange={handleDateTimeChange} 
                        id={props?.names[index]}
                        name={props?.names[index]} 
                        value={props?.values[index]} 
                    />
                </div>  
            </div>
                    </>
                );    
            }
        }

        if (type === "lookup") {
            if (props?.names[index]) {

                var fieldName = props?.names[index]
                content[index] = (                    
                    <>        
            <div className="form-group">
                <div className="login_input-wrapper">
                
                    <select className="form-control form_input"
                        id={fieldName}
                        name={fieldName}
                        onChange={props?.update}
                        >
                        <option value="">-- Select {props?.titles[index]} --</option>
                        {props.fieldsLookup[fieldName].map((selectOption) => {
                            return <option value={selectOption}
                                selected={selectOption === props?.values[index] ? 'selected' : '' }
                                >{selectOption}</option>
                        })}
                    </select>
                </div>  
            </div>
                    </>
                );    
            }
        }

        if (type === "radio") {
            content[index] = (
                <>
        <div className="form-group">
            <div className="login_input-wrapper">
                <div className="add-item__row-radio-title">
                    {props?.titles[index]}
                </div>
                <span className="radio-label">
                    <input type="radio" value={props?.radioValues[0]}
                        checked={
                            // eslint-disable-next-line
                            props?.radioSelected == props?.radioValues[0] ? "true" : ''
                        }
                        name={props?.names[index]}
                        onChange={handleOptionChange}
                    />
                    <span className="add-item__row-radio-option">{props?.radioTitles[0]}</span>
                </span>
                <span className="radio-label">
                    <input type="radio" value={props?.radioValues[1]}
                        checked={
                            // eslint-disable-next-line
                            props?.radioSelected == props?.radioValues[1] ? "true" : ''
                        }
                        name={props?.names[index]}
                        onChange={handleOptionChange}
                    />
                    <span className="add-item__row-radio-option">{props?.radioTitles[1]}</span>
                </span>
            </div>  
        </div>
                </>
            );
        }

        return null;
    })

    if (content[1]) {
        return (
<>
<div className="row">
    <div className="col-md-6">
        {content[0]}
    </div>
    <div className="col-md-6">
        {content[1] === "empty" ? '' : 
        content[1]}
    </div>
</div>
</>
        )
    } else{
        return content[0];
    }
    
}