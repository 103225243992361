import React, {useState, useEffect} from "react";
import './Login.css';
import {useNavigate} from "react-router-dom";
import {forgotPassword} from "../../services/requests"
import {getErrorMessage} from "../../services/utils";
import LogoMain from "../../assets/imgs/logo-main-dark.png"
import IconArrowLeftCircle from "../../assets/icons/icon-arrow-left-circle.png"
import { useRef } from "react";

const ForgotPassword = () => {

    const emailRef = useRef();
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputs, setInput] = useState({email: ""});
    const [isFormComplete, setIsFormComplete] = useState(false);
    const inputChange = (e) => {
        setInput({...inputs, [e.target.name]: e.target.value});
        if (e.target.value === "") {
            setIsFormComplete(false)
        }
        else {
            setIsFormComplete(true)
        }
    }

    const handleSendCodeClick = async (event) => {
        var body = {
            email: emailRef.current.value,
        }
        if (!isLoading) {
            setIsLoading(true);
            const resp = await forgotPassword({ ...body });
            if (resp?.result === "success") {
                var email = emailRef.current.value
                email = encodeURIComponent(email);        
                navigation('/forgot/code?email=' + email);
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        document.title = 'Smart Haul - Forgot Password';

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
              handleSendCodeClick();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };        
        // eslint-disable-next-line
    }, []);

    const navigate = useNavigate()
    function navigateBack() {
        navigate(-1)
    }

    return <>
<div className="login__background">

<div className="div-center">

    <div style={{marginLeft: '0px'}}>
        <img src={IconArrowLeftCircle} style={{width: '42px', 
                                            height: '42px', 
                                            cursor:'pointer'}} 
                                            alt="back" 
            onClick={() => navigateBack()} />
    </div>

  <div style={{textAlign:'center'}}>

    <img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/>

    {errorMessage ? 
    <>
    <div dangerouslySetInnerHTML={{__html: errorMessage}} 
        className="alert alert-danger" role="alert">        
    </div>
    </> : ''}
    
    <h1 className="login__header">Forgot password?</h1>

    <div className="login__header-info-text">
    Enter your email to request a password reset code.
    </div>

        <div className="form-group">
            <div className="login_input-wrapper">
                <input onChange={inputChange} 
                    ref={emailRef}
                    type="email" 
                    className="form-control login_input" 
                    id="email" 
                    name="email" 
                    placeholder=" " />
                <label htmlFor="email">Email Address</label>                    
            </div>              
        </div>

        <button type="button" className="btn btn-primary login__button"
            disabled={!isFormComplete || isLoading}
            onClick={() => handleSendCodeClick()}
        >Send Code</button>
    
  </div>
</div>
</div>
    </>

    
}

export default ForgotPassword;
