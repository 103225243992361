import IconRightDark from "../../assets/icons/icon-right-dark.png"
import "./css/Dashboard.css"
import DashboardProfile from "./Profile"

export default function DashboardHeader({breadcrumbs}) {

    return (
<>
<div className="container" style={{marginLeft: '0px', width: 'auto', marginRight: '50px', maxWidth: '2000px'}}>
    <div className="row">

        <div className="col-md header--breadcrumbs">
            {
            breadcrumbs.length === 1 ?
                <>
                {breadcrumbs[0]}
                </>
                :
                <>
                <span className="header--breadcrumbs-first">{breadcrumbs[0]}</span> 
                    <img src={IconRightDark} 
                        style={{width: '6px', height: '10px', margin: '-1px 10px 0px 10px'}} 
                        alt="" />
                {breadcrumbs[1]}
                </>
            }
        </div>

        <DashboardProfile />

    </div>
    <hr className="header--hr" />
</div>
</>);

}