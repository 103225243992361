import React, {useState, useEffect, useRef} from "react";
import './Login.css';
import {useNavigate} from "react-router-dom";
import {forgotPasswordSave} from "../../services/requests"
import {getErrorMessage} from "../../services/utils";
import LogoMain from "../../assets/imgs/logo-main-dark.png"
import IconArrowLeftCircle from "../../assets/icons/icon-arrow-left-circle.png"
import { useSearchParams } from 'react-router-dom';
import { setToken } from "../../services/token";

const ForgotNewPassword = () => {

    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputs, setInput] = useState({password: "", password_confirmation: ""});
    const [isFormComplete, setIsFormComplete] = useState(false);
    const inputChange = (e) => {
        setInput({...inputs, [e.target.name]: e.target.value});

        if (inputs['password'] !== "" && inputs['password_confirmation'] !== "") {
            setIsFormComplete(true)
        }
        else {
            setIsFormComplete(true)
        }
    }

    const handleSaveClick = async (event) => {
        var body = { 
            email: searchParams.get('email'),
            secret: searchParams.get('code'),
            password: passwordRef.current.value,
            password_confirmation: passwordConfirmationRef.current.value,
        }

        if (!isLoading) {
            setIsLoading(true);
            const resp = await forgotPasswordSave({ ...body });

            if (resp?.result === "success") {
                setToken(resp?.token, resp?.role, resp?.email, resp?.name)
                navigation('/customer/jobs');
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        document.title = 'Smart Haul - Save Password';

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
              handleSaveClick();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };        

        // eslint-disable-next-line
    }, []);

    const navigate = useNavigate()
    function navigateBack() {
        navigate(-1)
    }

    return <>
<div className="login__background">

<div className="div-center">

    <div style={{marginLeft: '0px'}}>
        <img src={IconArrowLeftCircle} style={{width: '42px', 
                                            height: '42px', 
                                            cursor:'pointer'}} 
                                            alt="back" 
            onClick={() => navigateBack()} />
    </div>

  <div style={{textAlign:'center'}}>

    <img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/>

    {errorMessage ? 
    <>
    <div className="alert alert-danger" role="alert">{errorMessage}</div>
    </> : ''}
    
    <h1 className="login__header">Reset password</h1>

    <div className="login__header-info-text">
    Enter a new password and login to your account.
    </div>

        <div className="form-group">
            <div className="login_input-wrapper">
                <input onChange={inputChange} 
                    ref={passwordRef}
                    type="password" 
                    className="form-control login_input" 
                    id="password" 
                    name="password" 
                    placeholder=" " />
                <label htmlFor="password">Password</label>
            </div>  
            <div className="login_input-wrapper">
                <input onChange={inputChange} 
                    ref={passwordConfirmationRef}
                    type="password" 
                    className="form-control login_input" 
                    id="password_confirmation" 
                    name="password_confirmation" 
                    placeholder=" " />
                <label htmlFor="password_confirmation">Retype Password</label>
            </div>  
        </div>

        <button type="button" className="btn btn-primary login__button"
            disabled={!isFormComplete || isLoading}
            onClick={() => handleSaveClick()}
        >Save & Login</button>
    
  </div>
</div>
</div>
    </>
    
}

export default ForgotNewPassword;
