import '../Auth/Login.css';

const Home = () => {

    document.title = 'Smart Haul - Logistics Simplified';

    return <>
    
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />

    <link rel="stylesheet" href="/assets/css/style.css" />
    <link rel="stylesheet" href="/assets/css/responsive.css" />
    <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
    
    <header class="section_common" id="hero-section">
      <div class="container">
        <div class="row header_top">
          <div class="col-sm-6">
            <a href="/" class="main_logo"><img src="/assets/img/logo.png" alt="" /></a>
          </div>
          <div class="col-sm-6 topCta">
            <a style={{fontWeight: 600}} href="/customer/signup" class="btn btnSignUp">Sign up</a>
            <a style={{marginLeft: 10}} href="/login" class="btn btnSignUp">Login</a>
          </div>
        </div>
        <div class="row">
           <div class="col-md-6">
              <div class="welcome_content">
                 <h2>Revolutionising Logistics with Our Real-Time Digital Marketplace</h2>
                 <p>Connecting shippers and carriers effortlessly, where every stakeholder wins. </p>
                 <a href="/customer/signup" class="btn btnSignUpNow">Sign up now</a>
              </div>
           </div>
        </div>
      </div>
    </header>

    <section class="section_common" id="about-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="about_content">
              <div class="about_content_image">
                <img src="/assets/img/about_img.jpg" alt="" />
              </div>
              <div class="about_content_text">
                <h2>SmartHaul - Smart Matches. Smooth Deliveries.</h2>
                <p>We match shippers with a multitude of carriers. We match carriers with a multitude of jobs. Shippers submit their delivery orders, and carriers fulfil the orders. Driving efficiency in every delivery.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_common" id="feature-section-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="feature_wrap1">
              <h3>Transforming Logistics</h3>
              <p>At SmartHaul, we are leveraging technology to transform the way goods are delivered, driving down costs for shippers and increasing earnings for carriers.</p>
            </div>
            <ul class="list_feature">
              <li>
                <div class="lf_thumb">
                  <img src="/assets/img/icon_so.png" alt="" />
                </div>
                <div class="lf_desc">
                  <h6>Streamline Operations</h6>
                  <p>Say goodbye to time-consuming manual coordination. Our platform automates the entire logistics process, from order placement to delivery, saving you valuable time and resources.</p>
                </div>
              </li>
              <li>
                <div class="lf_thumb">
                  <img src="/assets/img/icon_rtv.png" alt="" />
                </div>
                <div class="lf_desc">
                  <h6>Real-Time Visibility</h6>
                  <p>Track your shipments in real-time and receive instant updates on delivery status. With SmartHaul, you'll always know where your goods are and when they'll arrive.</p>
                </div>
              </li>
              <li>
                <div class="lf_thumb">
                  <img src="/assets/img/icon_s.png" alt="" />
                </div>
                <div class="lf_desc">
                  <h6>Sustainability</h6>
                  <p>By optimising routes and reducing empty backload journeys, we're helping to minimise carbon emissions and promote environmental responsibility.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section class="section_common" id="feature-section-2">
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-6">
            <div class="feature_wrap2">
              <h4>Effortless Shipping: Competitive Quotes, Easy Orders</h4>
              <p>Shippers can easily submit delivery orders and receive competitive quotes from multiple carriers.</p>
              <p>Track goods in real-time, receive status updates and proof of delivery, ensuring transparency and visibility throughout the delivery process.</p>
              <p><a href="/customer/signup" class="btn btnSignUpNow">Sign up</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_common" id="feature-section-3">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="feature_wrap3">
              <h4>Maximizing Revenues Through Transparent Logistics</h4>
              <p>Carriers can browse available shipments, submit bids, and communicate with shippers to secure jobs, and maximise earnings.</p>
              <p>Earn more with less hassle. Say goodbye to empty backload journeys. Fill spare capacity and reduce empty miles.</p>
              <p><a href="/customer/signup" class="btn btnSignUpNow">Sign up</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_common" id="feature-solution-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="section_title">Innovative Solutions for Sustainable Logistics.</h2>
            <div class="fs-wrap">
              <div class="fs-content">
                <div class="thumb">
                  <img src="/assets/img/icon_innovation.png" alt="" />
                </div>
                <div class="desc">
                  <h4>Innovation</h4>
                  <p>By leveraging advanced technology and integrating AI algorithms, SmartHaul optimises logistics operations, making them faster, more efficient, and more reliable for both shippers and carriers.</p>
                </div>
              </div>
              <div class="fs-content">
                <div class="thumb">
                  <img src="/assets/img/icon_sustainability.png" alt="" />
                </div>
                <div class="desc">
                  <h4>Sustainability</h4>
                  <p>SmartHaul reduces empty backload journeys and optimises route planning, cutting costs and carbon emissions. With emissions reporting, both shippers and carriers can track their environmental impact, driving sustainability.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer id="footer-section">
      <div id="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="footer_contents">
                <h3>Be a part of the future of logistics.</h3>
                <p>Sign up for Smart Haul today for free and experience the difference firsthand.</p>
                <p><a href="/customer/signup" class="btn btnSignUpAlter">Sign up</a></p>

                {/* <p class="ctaTips">Download our app from</p>
                <div class="footer_cta">
                  <a href="#"><img src="/assets/img/appStore.png" alt="" /></a>
                  <a href="#"><img src="/assets/img/playStore.png" alt="" /></a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-3 m_order_1 footer_logo">
              <a href="/"><img src="/assets/img/footer-logo.png" alt="" /></a>
            </div>
            <div class="col-md-6 m_order_3">
              <div class="footer-links">
                <span>&copy; 2024 Smart Haul</span>
                <span><a href="/privacy">Privacy Policy</a></span>
                <span><a href="/support">Support</a></span>
                {/* <span><a href="#">Terms of Conditions</a></span> */}
              </div>
            </div>
            <div class="col-md-3 m_order_2">
               {/* <ul class="footer_social">
                 <li><a href="#"><img src="/assets/img/social_fb.png" /></a></li>
                 <li><a href="#"><img src="/assets/img/social_ins.png" /></a></li>
                 <li><a href="#"><img src="/assets/img/social_x.png" /></a></li>
               </ul> */}
            </div>
          </div>
        </div>
      </div>      
    </footer>

    <script src="/assets/js/jquery.min.js"></script>
    <script src="/assets/js/popper.min.js"></script>
    <script src="/assets/js/bootstrap.min.js"></script>
    <script src="/assets/js/smoothScroll.js"></script>
    <script src="/assets/js/apps.js"></script>

    </>
}

export default Home;
