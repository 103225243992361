import React from "react";
import { useSearchParams } from 'react-router-dom';
import moment from "moment";
import IconEdit from "../../assets/icons/icon-edit.png";
import IconEditActive from "../../assets/icons/icon-edit-active.png";
import IconDelete from "../../assets/icons/icon-delete.png";
import IconDeleteActive from "../../assets/icons/icon-delete-active.png";
import IconArrowLeft from "../../assets/icons/icon-arrow-left.png";
import IconArrowRight from "../../assets/icons/icon-arrow-right.png";
import "./css/Table.css";
import { Tooltip } from 'react-tooltip'
import { getStatusTooltip } from "../../services/utils"

export default function DashboardTable(props) {
    const [searchParams] = useSearchParams();
    var columnsData = props?.columnsData?.data;

    function handleUpdatePage(page) {
        if (isNaN(page)) { 
            page = 2;
        }
        searchParams.set('page', page);
        props.loadData(page);
    }

    return (
        <>
            <table className={props.viewRow ? "dashboard-table" : "dashboard-table dashboard-table-nohover"}>
                <thead className="dashboard-table--header">
                    <tr className="dashboard-table--header-row">
                        {props.columns?.map((column, index) => {
                            return (<th className="dashboard-table--header-column" key={index}>{column}</th>);
                        })}
                        {props.editRow || props.deleteRow || props.approveRow || props.acceptRow ? (
                            <th className="dashboard-table--header-column dashboard-table--header-column-action">Action</th>
                        ) : null}
                    </tr>
                </thead>
                <tbody>
                    {columnsData?.map((columnData, index) => {
                        if (!props.limitRowCount || (index+1) <= props.limitRowCount) {
                            return (            
                                <tr className='dashboard-table--row' onClick={props.viewRow ? () => props.viewRow(columnData['id']) : null} key={index}>
                                    {props.columns?.map((column, columnIndex) => {
                                        var dateValue = null;
                                        if (props?.columnsFields[columnIndex].includes('date') || props?.columnsFields[columnIndex].includes('_at')) {
                                            var date = new Date(columnData[props?.columnsFields[columnIndex]]);
                                            var dateMoment = moment(date);
                                            dateValue = dateMoment.format("Do MMM [at] h:m A");
                                        }
                                        return (
                                            <td className="dashboard-table--body-column" key={columnIndex}>                                                
                                                {props.columns[columnIndex] === "Approved" || props.columns[columnIndex] === "Status" ? (
                                                    columnData[props?.columnsFields[columnIndex]] === 1 ? (
                                                        <span className="dashboard-table--body-column-label">YES</span>
                                                    ) : (
                                                        <span className="dashboard-table--body-column-label">NO</span>
                                                    )
                                                ) : props?.columnsFields[columnIndex].includes('bid') ? (
                                                    <>&euro;{columnData[props?.columnsFields[columnIndex]]}</>
                                                ) : dateValue ? (
                                                    dateValue
                                                ) : columnData[props?.columnsFields[columnIndex]].length > 50 ? (
                                                    columnData[props?.columnsFields[columnIndex]].substring(0, 50) + "..."
                                                ) : props.columns[columnIndex].includes("Status") ? (
                                                    <>
                                                    <Tooltip id="tooltip-status" />
                                                    <span className="dashboard-table--body-column-label" 
                                                        data-tooltip-id="tooltip-status"
                                                        data-tooltip-content={
                                                            getStatusTooltip(columnData[props?.columnsFields[columnIndex]])
                                                        }
                                                        data-tooltip-place="top"
                                                        style={{textTransform: 'uppercase'}}>
                                                        {columnData[props?.columnsFields[columnIndex]]}
                                                    </span>
                                                    </>
                                                ) : props.columns[columnIndex].includes("Enabled") ? (
                                                    columnData['enabled'] ? (
                                                        <button className="btn btn-table-action btn-table-action-success" onClick={() => props.disableRow(columnData['id'])}>Enabled</button>
                                                    ) : (
                                                        <button className="btn btn-table-action" onClick={() => props.enableRow(columnData['id'])}>Disabled</button>
                                                    )
                                                ) : props?.columnsFields[columnIndex] === 'company_name' 
                                                    && columnData['paid_at'] ? (
                                                    <>{columnData[props?.columnsFields[columnIndex]]}
                                                    </>  
                                                ) : props?.columnsFields[columnIndex] === 'company_name' 
                                                    && columnData.hasOwnProperty('paid_at')
                                                    && !columnData['paid_at'] 
                                                    ? (
                                                    <>-</>  
                                                ) : columnData[props?.columnsFields[columnIndex]] === 'null' ? (
                                                    <>-</>
                                                ) : columnData[props?.columnsFields[columnIndex]]}
                                            </td>
                                        );
                                    })}
                                    {props.editRow || props.deleteRow || props.approveRow || props.acceptRow || props.enableRow ? (
                                        <td className="dashboard-table--body-column dashboard-table--body-column-actions" onClick={(e) => e.stopPropagation()} style={{whiteSpace: "nowrap"}}>
                                            {props.approveRow && !columnData['approved'] ? (
                                                <button className="btn btn-table-action" onClick={() => props.approveRow(columnData['id'])}>Approve</button>
                                            ) : null}
                                            {props.acceptRow && !columnData['approved'] ? (
                                                <button className="btn btn-table-action" onClick={() => props.acceptRow(columnData['id'])}>Accept</button>
                                            ) : null}
                                            {((props?.hideEdit) ||                                              
                                             (props?.modelType === "job"
                                             && columnData['status'] !== "pending"))                                             
                                              ? '' : (
                                                <>
                                                    <img className="dashboard-table--action-icon" src={IconEdit} 
                                                        onMouseOver={e => (e.currentTarget.src = IconEditActive)} 
                                                        onMouseOut={e => (e.currentTarget.src = IconEdit)} 
                                                        onClick={() => props.editRow(columnData['id'])} 
                                                        alt="edit" />
                                                </>
                                            )}
                                            {!props?.hideDelete ? (
                                                <>
                                                    <img className="dashboard-table--action-icon" src={IconDelete} onMouseOver={e => (e.currentTarget.src = IconDeleteActive)} onMouseOut={e => (e.currentTarget.src = IconDelete)} onClick={() => props.deleteRow(columnData['id'])} alt="delete" />
                                                </>
                                            ) : null}
                                        </td>
                                    ) : null}
                                </tr>
                            );
                        }
                        return null;
                    })}
                </tbody>
            </table>
            {props?.showPaging !== "false" ? (
                <div style={{backgroundColor: '#fff', width: '100%', height: '30px', marginTop: '35px', marginBottom: '15px'}}>
                    <div style={{float: 'right'}}>
                        {(!searchParams.get('page') || parseInt(searchParams.get('page')) === 1) ? (
                            <div className="pageLink disabled">
                                <img src={IconArrowLeft} style={{width: '14px', height: '14px'}} alt="left" />
                            </div>
                        ) : (
                            <div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) - 1)}>
                                <img src={IconArrowLeft} style={{width: '14px', height: '14px'}} alt="left" />
                            </div>
                        )}
                        {[...Array(props?.columnsData?.meta?.pagination?.total_pages)].map((_, i) => (
                            <div key={i} onClick={() => handleUpdatePage(i + 1)} className={`pageLink ${((i + 1) === parseInt(searchParams.get('page')) || (!searchParams.get('page') && i === 0)) ? 'active' : ''}`}>
                                {(i + 1)}
                            </div>
                        ))}
                        {(props?.columnsData?.meta?.pagination?.total_pages === 1 || parseInt(searchParams.get('page')) === props?.columnsData?.meta?.pagination?.total_pages) ? (
                            <div className="pageLink disabled">
                                <img src={IconArrowRight} style={{width: '14px', height: '14px'}} alt="right" />
                            </div>
                        ) : (
                            <div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) + 1)}>
                                <img src={IconArrowRight} style={{width: '14px', height: '14px'}} alt="right" />
                            </div>
                        )}
                    </div>
                </div>
            ) : <br />}
        </>
    );
}
