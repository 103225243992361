import moment from "moment"

export const getStatusTooltip = (status) => {
    
    if (!status) return;

    status = status.toLowerCase(); // Convert status to lowercase
    if (status === "pending") {
        return "New job waiting for bids.";
    } else if (status === "accepted") {
        return "Customer accepted a bid and waits for confirmation from company.";
    } else if (status === "confirmed") {
        return "Company confirmed customer’s bid and waits for payment from customer.";
    } else if (status === "waiting assignment" || status === "waiting_assignment") {
        return "Customer paid for job and waits for company to assign driver.";
    } else if (status === "assigned") {
        return "Driver is assigned to the job and will collect the package soon.";
    } else if (status === "collected") {
        return "Driver collected the package and will deliver it soon.";
    } else {
        return "Invalid status.";
    }
}

export const getErrorMessage = (error) => {
    if (error.result !== 'error') {
        return false;
    }
    if (typeof error.message === 'string') {
        return error.message;
    }
    if (typeof error.messages === 'object') {
        if (typeof(error.messages) === "object") {
            if (typeof(error.messages[0]) === "string") {
                return error.messages[0]
            }
        }
        return error.messages.map(v => {
            return Object.values(v).join('<br />')
        })
    }
}

export const capitalize = (word) => {
    return word?.charAt(0).toUpperCase()
        + word?.slice(1);
}

export function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const dateObj = new Date(dateString);
    const formattedDate = dateObj?.toLocaleDateString('en-US', options);
    return formattedDate?.replace(',', '');
}

export function formatDateTime(dateTimeString) {
    let dateTimeParts = dateTimeString.split(/[- :]/);
    dateTimeParts[1]--;
    const dateObject = new Date(...dateTimeParts); // our Date object
    return moment(dateObject).format('Do MMM YYYY HH:mm:00');
}

export function formatAddress(name, address1, address2, city, county, postcode) {

    let address = name
    if (address1 !== "") {
        if (address !== "") {
            address += ", " + address1
        }
        else {
            address += address1
        }        
    }
    if (address2 !== "") {
        address += ", " + address2
    }
    if (city !== "") {
        address += ", " + city
    }
    if (county !== "") {
        address += ", " + county
    }
    if (postcode !== "") {
        address += ", " + postcode
    }

    return address
}  