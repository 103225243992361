import '../Auth/Login.css';
import LogoMain from "../../assets/imgs/logo-main-dark.png"

const Links = () => {

    return <>
<div className="login__background">

<div className="div-center">
  <div className="content"style={{textAlign:'center'}}>
    <img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/>

    <div className="login__footer-text">
        <ul>
            <li style={{display: 'block'}}><a className="login__footer-link" href="/admin">Admin</a></li>
            <li style={{display: 'block'}}><a className="login__footer-link" href="/customer/signup">Customer Signup</a></li>
            <li style={{display: 'block'}}><a className="login__footer-link" href="/company/signup">Company Signup</a></li>
            <li style={{display: 'block'}}><a className="login__footer-link" href="/login">Customer/Company Login</a></li>
            <li style={{display: 'block'}}><a className="login__footer-link" href="/driver/deletion">Driver Deletion</a></li>   
            <br />
            <li style={{display: 'block'}}><a className="login__footer-link" href="/privacy">Privacy</a></li>
        </ul>
    </div>

  </div>
</div>
</div>
    </>

}

export default Links;
