import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css";
import "./DriversList.css";
import { companyListDrivers, companyAddDriver, companyEnableDriver, 
        companyDisableDriver, companyUpdateDriver, companyDeleteDriver,
        companyResetDriverPassword } from '../../../services/requests';
import CompanyLayout from '../../../layouts/CompanyLayout';
import { getToken } from '../../../services/token';
import IconNoJobs from "../../../../src/assets/icons/icon-no-jobs.png";
import IconUnapproved from "../../../../src/assets/icons/icon-unapproved.png";
import IconPlusWhite from "../../../../src/assets/icons/icon-plus-white.png"
import DashboardTable from '../../../components/Dashboard/Table'
import Modal from 'react-bootstrap/Modal';
import {getErrorMessage} from "../../../services/utils";

const CompanyDriversList = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [drivers, setDrivers] = useState(null);
    const [driver, setDriver] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [companyApproved, setCompanyApproved] = useState(false);

    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)

    const loadData = async (pageSupplied) => {
        if (!isLoading) {
            setIsLoading(true);
            var localPage = 1;

            if (searchParams.get('page') || pageSupplied) {
                searchParams.set('page', pageSupplied);
                localPage = searchParams.get('page');
            }

            const resp = await companyListDrivers(localPage);
            setCompanyApproved(true);
            if (resp?.result === 'error') {
                console.log('error: ')
                console.log(getErrorMessage(resp))
                if (getErrorMessage(resp) === "Company user is not approved.") {
                    setCompanyApproved(false);
                }
            }
            setDrivers(resp?.drivers);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData('');
        document.title = 'Smart Haul - Company - Drivers';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddDriverClick = async () => {    
        setDriver(null)
        setIsLoading(false)
        setErrorMessage(null)
        setShowAddForm(true)
    } 

    const enableRow = async (id) => {    
        try {
            setIsLoading(true)
            const resp = await companyEnableDriver(id);
            setIsLoading(false)
            if (resp?.result === 'success') {
                loadData(1)
            }
            else {              
                if (resp.result === 'error') {
                    setErrorMessage(getErrorMessage(resp));
                }
            }
          } catch (error) {
            setErrorMessage("There was an error enabling the driver.")
          }        
    } 
    const disableRow = async (id) => {    
        try {
            setIsLoading(true)
            const resp = await companyDisableDriver(id);
            setIsLoading(false)
            if (resp?.result === 'success') {
                loadData(1)
            }
            else {              
                if (resp.result === 'error') {
                    setErrorMessage(getErrorMessage(resp));
                }
            }
          } catch (error) {
            setErrorMessage("There was an error disabling the driver.")
          }      
    } 

    const editRow = async (id) => {    
        drivers?.data?.map((driverLoop, index) => {
            if (driverLoop.id === id) {
                setDriver(driverLoop)
                setFirstName(driverLoop?.first_name)
                setLastName(driverLoop?.last_name)
                setEmail(driverLoop?.email)
                setPhone(driverLoop?.phone)
            }
            return null
        })    

        setIsLoading(false)
        setErrorMessage(null)
        setShowAddForm(true)
    } 

    // add driver
    const [showAddForm, setShowAddForm] = useState(false);
    const handleCloseAddForm = () => setShowAddForm(false);
    const handleSaveClick = async (event) => {    

        setSuccessMessage(null)

        var body = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
        };

      try {
        setIsLoading(true)
        var resp = null
        if (driver) {
            resp = await companyUpdateDriver(driver?.id, body);
        }
        else {
            resp = await companyAddDriver(body);
        }
        
        setIsLoading(false)
        if (resp?.result === 'success') {
            setShowAddForm(false)
            loadData(1)
            if (driver) {
                setSuccessMessage('The driver has now been updated.')
            }
            else {
                setSuccessMessage('The driver has now been added.')
            }
        }
        else {              
            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
        }
      } catch (error) {
        setErrorMessage("There was an error adding the driver.")
      }
    }

    // delete company
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
    const deleteRow = async (id) => {  
        drivers?.data?.map((driverLoop, index) => {
            if (driverLoop.id === id) {
                setDriver(driverLoop)
            }
            return null
        })    

        setErrorMessage(null)
        setShowDeleteConfirmation(true)
    }
    const handleConfirmDelete = async (id) => {    
      try {
        setIsLoading(true)
        const resp = await companyDeleteDriver(driver?.id);
        setIsLoading(false)
        if (resp?.result === 'success') {
            loadData(1)
            setSuccessMessage("The driver has now been removed.")
            setShowDeleteConfirmation(false)
        }
        else {              
            setErrorMessage(getErrorMessage(resp))
            setShowDeleteConfirmation(false)
        }
      } catch (error) {
        setErrorMessage("There was an error deleting the driver.")
      }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSaveClick();
        }
    };

    // reset company password
    const [showResetPasswordComplete, setShowResetPasswordComplete] = useState(false);
    const handleResetPasswordComplete = () => setShowResetPasswordComplete(false);
    const handleResetPasswordClick = async (id) => {      
      setIsLoading(true)
      try {
        const resp = await companyResetDriverPassword(id);
        setIsLoading(false)
        if (resp.result === 'error') {
            setErrorMessage(getErrorMessage(resp));
        }
        else {
            setShowAddForm(false)
            setShowResetPasswordComplete(true)
        }
      } catch (error) {
        setErrorMessage("There was an error reseting the driver password222.")
      }
    }    

    return (
        <CompanyLayout selected={"drivers"}>

{!companyApproved ? 
<>
            <div className="container">
                <div className="dashboard-page__no-items-box row align-items-center justify-content-center">
                    <img src={IconUnapproved} alt="no jobs" style={{ width: '200px', height: '200px', paddingBottom: '25px' }} />
                    <h1 className="dashboard-page__no-items-title">Waiting for account approval.</h1>
                    <div className="dashboard-page__no-items-subtitle">
                        Your account is waiting for our administrator approval. Please check back later.
                    </div>
                </div>
            </div>
</>
        : 
        <>
            {successMessage ? (
            <div className={'alert alert-success'}>{successMessage}</div>
            ) : null}                

            <Modal show={showResetPasswordComplete} onHide={handleResetPasswordComplete} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Password reset
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">
                <p>The driver has now been sent an email with instructions on how to reset their password.</p>
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">
                <button type="button" className="btn btn-primary" onClick={() => handleResetPasswordComplete()}>OK</button>
              </Modal.Footer>
            </Modal>                  

            <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Delete this driver?                  
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">
                <p>Are you sure you want to remove this driver from your company?</p>
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                    <button type="button" className="btn btn-secondary" onClick={() => handleCloseDeleteConfirmation()}>Cancel</button>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-primary" onClick={() => handleConfirmDelete()}>Delete</button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>       

            <Modal show={showAddForm} onHide={handleCloseAddForm} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                    {driver ? 'Edit driver':'Add Driver'}
                  
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">

                {errorMessage ? 
                <div dangerouslySetInnerHTML={{__html: errorMessage}} className="alert alert-danger" role="alert"></div> 
                : ''}

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">


                <div className="form-group edit--form_group">
                    <div className="form-group">
                        <div className="signup_input-wrapper">
                        <input className="form-control signup--form_group-control signup_input"
                            type="text"
                            onChange={(event) => setFirstName(event.target.value)}
                            id="first_name"
                            name="first_name"
                            placeholder=" "
                            defaultValue={driver?.first_name}
                            onKeyPress={handleKeyPress}
                        />
                        <label className="signup--form_group-label" htmlFor="first_name">First Name</label>
                        </div>
                    </div>
                </div>

                    </div>
                    <div className="col">

                <div className="form-group edit--form_group">
                    <div className="form-group">
                        <div className="signup_input-wrapper">
                        <input className="form-control signup--form_group-control signup_input"
                            type="text"
                            onChange={(event) => setLastName(event.target.value)}
                            id="last_name"
                            name="last_name"
                            placeholder=" "
                            defaultValue={driver?.last_name}
                            onKeyPress={handleKeyPress}
                        />
                        <label className="signup--form_group-label" htmlFor="last_name">Last Name</label>
                        </div>
                    </div>
                </div>

                    </div>
                </div>

                  <div className="row">

                <div className="form-group edit--form_group">
                    <div className="form-group">
                        <div className="signup_input-wrapper">
                        <input className="form-control signup--form_group-control signup_input"
                            type="text"
                            onChange={(event) => setEmail(event.target.value)}
                            id="email"
                            name="email"
                            placeholder=" "
                            defaultValue={driver?.email}
                            onKeyPress={handleKeyPress}
                        />
                        <label className="signup--form_group-label" htmlFor="email">Email</label>
                        </div>
                    </div>
                </div>

                <div className="form-group edit--form_group">
                    <div className="form-group">
                        <div className="signup_input-wrapper">
                        <input className="form-control signup--form_group-control signup_input"
                            type="text"
                            onChange={(event) => setPhone(event.target.value)}
                            id="phone"
                            name="phone"
                            placeholder=" "
                            defaultValue={driver?.phone}
                            onKeyPress={handleKeyPress}
                        />
                        <label className="signup--form_group-label" htmlFor="phone">Phone</label>
                        </div>
                    </div>
                </div>

                </div>
                </div>

                </Modal.Body>
                <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                    <button type="button" className="btn btn-primary" onClick={() => handleSaveClick()}
                        disabled={isLoading}
                        >Save</button>
                    </div>
                  </div>


{driver ?
                  <div className="row">
                    <div className="col">
                      <button type="button" className="btn btn-secondary" onClick={() => handleResetPasswordClick(driver?.id)}
                        disabled={isLoading}
                      >Reset Password</button>
                    </div>
                  </div>  
: ''}
                </div>

              </Modal.Footer>                
            </Modal>

            {drivers?.data?.length > 0 ?
<>
<div style={{width:'100%'}}>
<div style={{width: '250px', float: 'left'}}>
  <h1 className="dashboard-page--title">Drivers</h1>
</div>
<div style={{width: '350px', float: 'right'}}>
  <button 
      className="btn btn-primary"
      style={{width:'165px', marginTop: '30px', float: 'right', height: '52px'}}
      onClick={() => handleAddDriverClick()}
      ><img src={IconPlusWhite} style={{width: '20px', height: '20px', marginLeft: '-5px'}} alt="plus" /> Add new driver</button>
</div>
</div>
                <DashboardTable 
                    columns={['Name', 'Email', 'Phone', 'Enabled']}
                    columnsData={drivers}
                    columnsFields={['name', 'email', 'phone', 'enabled']}
                    loadData={(page) => loadData(page)}
                    editRow={(id) => editRow(id)}
                    deleteRow={(id) => deleteRow(id)}
                    enableRow={(id) => enableRow(id)}
                    disableRow={(id) => disableRow(id)}
                />        
                </>
                :
                <div className="container">
                    <div className="dashboard-page__no-items-box row align-items-center justify-content-center">
                        <img src={IconNoJobs} alt="no jobs" style={{ width: '200px', height: '200px' }} />
                        <h1 className="dashboard-page__no-items-title">You don't have any drivers.</h1>

                        <button 
                            className="btn btn-primary"
                            style={{width:'165px', marginTop: '30px', float: 'right', height: '52px'}}
                                onClick={() => handleAddDriverClick()}>
                            <img src={IconPlusWhite} style={{width: '20px', height: '20px', marginLeft: '-5px'}} alt="plus" /> 
                            Add new driver
                        </button>

                    </div>
                </div>
            }
</>
}            
        </CompanyLayout>
    );
};

export default CompanyDriversList;
