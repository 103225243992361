import './App.css';

import AdminLogin from "./screens/Admin/Auth/Login";
import AdminCustomersList from "./screens/Admin/Customers/CustomersList";
import AdminCustomerView from "./screens/Admin/Customers/CustomerView";
import AdminCompaniesList from "./screens/Admin/Companies/CompaniesList";
import AdminCompanyView from "./screens/Admin/Companies/CompanyView";
import AdminJobsList from "./screens/Admin/Jobs/JobsList";
import AdminJobView from "./screens/Admin/Jobs/JobView";

import Login from "./screens/Auth/Login";

import ForgotPassword from "./screens/Auth/ForgotPassword";
import ForgotCode from "./screens/Auth/ForgotCode";
import ForgotNewPassword from "./screens/Auth/ForgotNewPassword";

import DriverPassword from "./screens/Driver/Password";
import DriverDeletion from "./screens/Driver/Deletion";

import CustomerSignUp from "./screens/Customer/Auth/SignUp";
import CustomerJobsList from "./screens/Customer/Jobs/JobsList";
import CustomerJobView from "./screens/Customer/Jobs/JobView";
import CustomerBidsList from "./screens/Customer/Bids/BidsList";
import CustomerJobAdd from "./screens/Customer/Jobs/JobAddEdit";
import CustomerJobEdit from "./screens/Customer/Jobs/JobAddEdit";
import CustomerProfile from "./screens/Customer/Profile/Profile";
import CustomerEmail from "./screens/Customer/Profile/Email";
import CustomerEmailVerify from "./screens/Customer/Profile/EmailVerify";
import CustomerPassword from "./screens/Customer/Profile/Password";
import CompanySignUp from "./screens/Company/Auth/SignUp";
import CompanyJobsList from "./screens/Company/Jobs/JobsList";
import CompanyCurrentJobsList from "./screens/Company/Jobs/CurrentJobsList";
import CompanyJobView from "./screens/Company/Jobs/JobView";
import CompanyDriversList from "./screens/Company/Drivers/DriversList";
import CompanyProfile from "./screens/Company/Profile/Profile";
import CompanyEmail from "./screens/Company/Profile/Email";
import CompanyEmailVerify from "./screens/Company/Profile/EmailVerify";
import CompanyPassword from "./screens/Company/Profile/Password";
import CompanyDocuments from "./screens/Company/Profile/Documents";

import WebsiteHome from "./screens/Website/Home.js";
import WebsiteLinks from "./screens/Website/Links.js";
import WebsitePrivacy from "./screens/Website/Privacy.js";
import WebsiteSupport from "./screens/Website/Support.js";

import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";

const App = () => {
  let routes = useRoutes([

    { path: "/", element: <WebsiteHome /> },
    { path: "/links", element: <WebsiteLinks /> },
    { path: "/privacy", element: <WebsitePrivacy /> },
    { path: "/support", element: <WebsiteSupport /> },

    { path: "/admin", element: <AdminLogin /> },
    { path: "/admin/customers", element: <AdminCustomersList /> },
    { path: "/admin/customers/:id", element: <AdminCustomerView /> },
    { path: "/admin/companies", element: <AdminCompaniesList /> },
    { path: "/admin/companies/:id", element: <AdminCompanyView /> },
    { path: "/admin/jobs", element: <AdminJobsList /> },
    { path: "/admin/jobs/:id", element: <AdminJobView /> },

    { path: "/login", element: <Login /> },

    { path: "/forgot", element: <ForgotPassword /> },
    { path: "/forgot/code", element: <ForgotCode /> },
    { path: "/forgot/password", element: <ForgotNewPassword /> },

    { path: "/driver/password/:secret", element: <DriverPassword /> },
    { path: "/driver/deletion", element: <DriverDeletion /> },

    { path: "/customer/signup", element: <CustomerSignUp /> },
    { path: "/customer/jobs", element: <CustomerJobsList /> },
    { path: "/customer/jobs/:id", element: <CustomerJobView /> },
    { path: "/customer/jobs/add", element: <CustomerJobAdd /> },
    { path: "/customer/jobs/:id/edit", element: <CustomerJobEdit /> },
    { path: "/customer/jobs/:id/bids", element: <CustomerBidsList /> },
    { path: "/customer/profile", element: <CustomerProfile /> },
    { path: "/customer/email", element: <CustomerEmail /> },
    { path: "/customer/email/verify", element: <CustomerEmailVerify /> },
    { path: "/customer/password", element: <CustomerPassword /> },

    { path: "/company/signup", element: <CompanySignUp /> },
    { path: "/company/jobs", element: <CompanyJobsList /> },
    { path: "/company/jobs/:id", element: <CompanyJobView /> },
    { path: "/company/jobs/current", element: <CompanyCurrentJobsList /> },
    { path: "/company/jobs/current/:id", element: <CompanyJobView /> },
    { path: "/company/drivers", element: <CompanyDriversList /> },
    { path: "/company/profile", element: <CompanyProfile /> },
    { path: "/company/email", element: <CompanyEmail /> },
    { path: "/company/email/verify", element: <CompanyEmailVerify /> },
    { path: "/company/password", element: <CompanyPassword /> },
    { path: "/company/documents", element: <CompanyDocuments /> },

  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;