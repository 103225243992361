import React, {useState, useEffect} from "react";
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import {
    adminResetCompanyPassword,
    adminApproveCompany,
    adminUpdateCompany,
    adminDeleteCompany,
    adminGetCompany,
  } from '../../../services/requests'
import Layout from '../../../layouts/AdminLayout.js'
import DashboardViewItemHeader from '../../../components/Dashboard/ViewItemHeader'
import DashboardViewItemRow from '../../../components/Dashboard/ViewItemRow'
import FormFieldSet from '../../../components/Dashboard/FormFieldSet'
import { getToken } from '../../../services/token';
import Modal from 'react-bootstrap/Modal';
import AdminCompanyDocuments from '../../../components/Dashboard/Companies/Documents.js'

const AdminCompanyView = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);
    const [searchParams] = useSearchParams();

    const loadData = async () => {
        if (!isLoading) {
          setIsLoading(true);
          if (id) {
            const resp = await adminGetCompany(id);
            setCompany(resp?.company?.data);  
            if (searchParams.get('edit')) {
              setShowEditForm(true)
            }
            else if (searchParams.get('delete')) {
              setShowDeleteConfirmation(true)
            }
          }
          setIsLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData('');
        document.title = 'Smart Haul - View Company';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      // approve company
      const handleApproveClick = async() => {
        await adminApproveCompany(id).then((results) => {
          loadData()
        })
      }

    // edit company
    const [showEditForm, setShowEditForm] = useState(false);
    const handleCloseEditForm = () => setShowEditForm(false);
    function loadEditForm() {
        setIsLoading(false)
        setErrorMessage(null)
        setShowEditForm(true)
    }
    const handleSaveClick = async (event) => {    

      var body = {
        name: company?.name,
        email: company?.email,
        phone: company?.phone,
        description: company?.description,        
      };

      try {
        const resp = await adminUpdateCompany(id, { ...body });
        if (resp?.result === 'success') {
            navigate("/admin/companies?updated=true")
        }
        else {              
          if (resp.messages[0]) {                
            var errorMessageAll = ""
            for (const key in resp.messages[0]) {
              errorMessageAll = errorMessageAll + " " + resp.messages[0][key];
            }  
            setErrorMessage(errorMessageAll)
          }
          else {
            setErrorMessage("There was an error updating the company.")
          }
        }
      } catch (error) {
        setErrorMessage("There was an error updating the company.")
      }
    }

    // delete company
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
    function loadDeleteConfirmation() {
        setErrorMessage(null)
        setShowDeleteConfirmation(true)
    }
    const handleConfirmDelete = async (event) => {    
      try {
        const resp = await adminDeleteCompany(id);
        if (resp?.result === 'success') {
            navigate("/admin/companies?deleted=true")
        }
        else {              
          if (resp.messages[0]) {                
            var errorMessageAll = ""
            for (const key in resp.messages[0]) {
              errorMessageAll = errorMessageAll + " " + resp.messages[0][key];
            }  
            setErrorMessage(errorMessageAll)
          }
          else {
            setErrorMessage("There was an error deleting the company.")
          }
        }
      } catch (error) {
        setErrorMessage("There was an error deleting the company.")
      }
    }

    // reset company password
    const [showResetPasswordComplete, setShowResetPasswordComplete] = useState(false);
    const handleResetPasswordComplete = () => setShowResetPasswordComplete(false);
    const handleResetPasswordClick = async (event) => {      
      setIsLoading(true)
      try {
        const resp = await adminResetCompanyPassword(id);
        if (resp?.result === 'success') {
            setShowEditForm(false)
            setShowResetPasswordComplete(true)
        }
        else {              
          if (resp.messages[0]) {                
            var errorMessageAll = ""
            for (const key in resp.messages[0]) {
              errorMessageAll = errorMessageAll + " " + resp.messages[0][key];
            }  
            setErrorMessage(errorMessageAll)
          }
          else {
            setErrorMessage("There was an error reseting the company password.")
          }
        }
      } catch (error) {
        setErrorMessage("There was an error reseting the company password.")
      }
    }

    return <>
      <Layout breadcrumbs={['Companies', company?.name]}>

            <Modal show={showResetPasswordComplete} onHide={handleResetPasswordComplete} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Password reset
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">
                <p>The company has now been sent an email with instructions on how to reset their password.</p>
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">
                <button type="button" className="btn btn-primary" onClick={() => handleResetPasswordComplete()}>OK</button>
              </Modal.Footer>
            </Modal>       

            <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Delete this company?                  
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">
                <p>Are you sure you want to delete this company from the system?</p>
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                    <button type="button" className="btn btn-secondary" onClick={() => handleCloseDeleteConfirmation()}>Cancel</button>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-primary" onClick={() => handleConfirmDelete()}>Delete</button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>                  

            <Modal show={showEditForm} onHide={handleCloseEditForm} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Edit company
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">

                {errorMessage ? 
                <>
                <div className="container" style={{width: '100%'}}>
                  <div className="row edit--form_row">
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                </div>
                </> : ''}

                <div className="container">

                <FormFieldSet 
                    model={company}
                    fields={['Company Name']}
                    fieldsData={['name']}
                    fieldsOnChange={[
                      (event) => setCompany({ ...company, name: event?.target?.value }),
                    ]}
                  />

                <FormFieldSet 
                    model={company}
                    fields={['Email Address']}
                    fieldsData={['email']}
                    fieldsOnChange={[
                      (event) => setCompany({ ...company, email: event?.target?.value }),
                    ]}
                  />

                <FormFieldSet 
                    model={company}
                    fields={['Phone']}
                    fieldsData={['phone']}
                    fieldsOnChange={[
                      (event) => setCompany({ ...company, phone: event?.target?.value }),
                    ]}
                  />

                <FormFieldSet 
                    model={company}
                    fields={['Description']}
                    fieldsData={['description']}
                    fieldsOnChange={[
                      (event) => setCompany({ ...company, description: event?.target?.value }),
                    ]}
                  />

                </div>                
                
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                      <button type="button" className="btn btn-primary" onClick={() => handleSaveClick()}
                        disabled={isLoading}
                      >Save</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button type="button" className="btn btn-secondary" onClick={() => handleResetPasswordClick()}
                        disabled={isLoading}
                      >Reset Password</button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>    

<div className="row">
  <div className="col-md-8">

        <DashboardViewItemHeader 
            editAction={() => loadEditForm()}
            deleteAction={() => loadDeleteConfirmation()}
            approveAction={() => handleApproveClick()}
            approveShowButton={!company?.approved}
            approveShowStatus={true}
        />

        <h1 className="dashboard-page--title">{company?.name}</h1>

        <div className="dashboard-page--description">
            {company?.description}
        </div>

        <DashboardViewItemRow
          columns={['Email Address', 'Phone']}
          columnsData={[company?.email, company?.phone]} />

        <DashboardViewItemRow
          columns={['Address line 1', 'Address line 2']}
          columnsData={[company?.address1, company?.address2]} 
          alternateRow={true}
        />

        <DashboardViewItemRow
          columns={['City', 'County']}
          columnsData={[company?.city, company?.county]}           
        />

        <DashboardViewItemRow
          columns={['Postcode', 'Vat Number']}
          columnsData={[company?.postcode, company?.vat_number]} 
          alternateRow={true}          
        />

  </div>
  <div className="col-md-4">

  <AdminCompanyDocuments company={company} />

  </div>
</div>


      </Layout>
    </>
}

export default AdminCompanyView;
