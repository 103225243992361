import React, { useState, useEffect } from "react";
import '../../Auth/Login.css';
import {
    companyGetProfile, companyUpdateDocumentTitle, companyDeleteDocument,
    companyAddDocument
} from "../../../services/requests"
import CompanyLayout from "../../../layouts/CompanyLayout";
import "./Profile.css"
import CompanySideProfileMenu from "../../../components/Dashboard/Companies/SideProfileMenu";
import { getErrorMessage } from "../../../services/utils";

import IconPlus from '../../../assets/icons/icon-plus.png'
import IconPdf from '../../../assets/icons/icon-pdf.png'
import IconPng from '../../../assets/icons/icon-png.png'
import IconJpg from '../../../assets/icons/icon-jpg.png'
import IconTrash from '../../../assets/icons/icon-trash.png'
import IconEdit from '../../../assets/icons/icon-edit-small.png'
import { Modal, Button, Form } from "react-bootstrap";

const CompanyDocuments = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);    
    const [errorMessagePopup, setErrorMessagePopup] = useState(false);    
    const [documents, setDocuments] = useState(null);
    const [documentIndex, setDocumentIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('add'); // ['add', 'edit']

    const [filename, setFilename] = useState('');
    const [file, setFile] = useState(null);

    const handleCloseModal = () => {
        setShowModal(false);
        setFilename('');
        setFile(null);        
    };

    const handleShowModal = () => {
        setModalMode('add');
        setErrorMessagePopup(false)
        setShowModal(true);
    };

    const handleFilenameChange = (event) => {
        setFilename(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {

        if (modalMode === 'edit') {
            handleSaveIconClick(documentIndex)

            setFilename('');
            setFile(null);
            setShowModal(false);
            return
        }

        // validate fields
        if (!file) {
            setErrorMessagePopup("Please select a file to upload.")
            return
        }
        if (!filename) {
            setErrorMessagePopup("Please enter a name for this document.")
            return
        }

        var data = {
            document: file,
            document_title: filename,
        }
        const resp = await companyAddDocument(data)
        var fileToStore = {
            filename: resp?.company_document?.filename,
            id: resp?.company_document?.id,
            type: resp?.company_document?.type,
            url: resp?.company_document?.url,
            title: filename
        }
        const newDocuments = [...documents, fileToStore];
        setDocuments(newDocuments)

        // Use the 'filename' and 'file' state variables
        // Reset state variables and close the modal
        setFilename('');
        setFile(null);
        setShowModal(false);
    };

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true)        
            const resp = await companyGetProfile();
            setDocuments(resp?.company?.documents?.data);
            setIsLoading(false)
        }
        loadData()
        document.title = 'Smart Haul - Company - Documents';
        // eslint-disable-next-line
    }, []);    

    const handleEditImageClick = (index) => {
        setDocumentIndex(index);
        setFilename(documents[index].title);
        handleShowModal();
        setModalMode('edit');
    };

    const handleSaveIconClick = async (index) => {        

        var data = { document_title: filename }
        setIsLoading(true)
        const resp = await companyUpdateDocumentTitle(documents[index].id, data);
        console.log(resp)
        setIsLoading(false)

        const updatedDocuments = [...documents];
        updatedDocuments[index].title = filename;
        setDocuments(updatedDocuments);
    };

    return (
        <CompanyLayout>
            <div className="row">
                <div className="col-3">
                    <CompanySideProfileMenu selected="documents" />
                </div>
                <div className="col-9">
                    {/* {successMessage ? 
                        <div dangerouslySetInnerHTML={{__html: successMessage}} className="alert alert-success" role="alert"></div> 
                        : ''} */}
                    {errorMessage ? 
                        <div dangerouslySetInnerHTML={{__html: errorMessage}} className="alert alert-danger" role="alert"></div> 
                        : ''}
                    <h1 className="profile--form__header">Manage documents</h1>
                    <div className="profile--form__description">Edit profile to reflect changes and keep your details accurate and up-to-date</div>

                    <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {modalMode === 'edit' ? 'Edit Document'
                            : 'Add Document'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                        {errorMessagePopup ? 
                <>
                <div className="container" style={{width: '100%'}}>
                  <div className="row edit--form_row">
                    <div className="alert alert-danger" role="alert">
                      {errorMessagePopup}
                    </div>
                  </div>
                </div>
                </> : ''}
                            <div key="filename" className="col-md-12">
                                <div className="form-group edit--form_group">
                                    <div className="form-group">
                                        <div className="signup_input-wrapper">
                                            <input className="form-control signup--form_group-control signup_input"
                                                type="text"
                                                id="filename"
                                                name="filename"
                                                placeholder=" "
                                                key="filename"
                                                value={filename}
                                                onChange={handleFilenameChange}
                                            />

                                            <label className="signup--form_group-label" htmlFor="filename">Document Name</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {modalMode === 'add' ?
                            <Form.Group controlId="formFile">                                
                                <Form.Control type="file" onChange={handleFileChange} />
                            </Form.Group>
                            : ''}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleUpload} disabled={isLoading}>
                            {modalMode === 'add' ? 'Upload' : 'Save'}                            
                        </Button>
                        <Button variant="secondary" onClick={handleCloseModal} disabled={isLoading}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>


                    <div>

{documents?.map((file, index) => {

    const handleDeleteImageClick = async (index) => {
        const resp = await companyDeleteDocument(documents[index].id)
        if (resp.result === 'success') {
            documents.splice(index, 1);
            const newDocuments = [...documents];
            setDocuments(newDocuments)    
        }
        else {
            setErrorMessage(getErrorMessage(resp))
        }
    }    

    return (
<>
<div key={index} className="square dashboard--view-documents-box" style={{
    marginTop: '15px',
    marginRight: '10px',
    marginBottom: '10px',
    float: 'left',
    cursor: 'default' }}
>
    <div style={{
        position: "absolute",
        right: '10px',
        top: '10px',
        height: '20px',
        cursor: 'pointer'
    }}
        onClick={() => handleDeleteImageClick(index)}
    >
        <img style={{width: '20px', height: '20px', marginTop: '0px'}} src={IconTrash} alt="delete" />
    </div>

    <div style={{
        position: "absolute",
        right: '10px',
        top: '40px',
        height: '20px',
        cursor: 'pointer'
    }}
        onClick={() => handleEditImageClick(index)}
    >
        <img style={{width: '20px', height: '20px', marginTop: '0px'}} src={IconEdit} alt="edit" />
    </div>

    {(file?.object?.type === "application/pdf" || file?.type === "application/pdf") ?
    <div><img style={{marginTop: '20px'}} src={IconPdf} alt="PDF" /></div>
    : ''}
    {(file?.object?.type === "image/png" || file?.type === "image/png") ?
    <div><img style={{marginTop: '20px'}} src={IconPng} alt="PNG" /></div>
    : ''}
    {(file?.object?.type === "image/jpeg" || file?.type === "image/jpeg") ?
    <div><img style={{marginTop: '20px'}} src={IconJpg} alt="JPG" /></div>
    : ''}
 
    <div className="dashboard--view-documents-box-title">

        {/* {isEditing[index] ?         
        <>
            <input type="text" 
                id={index}
                defaultValue={file?.title}
                style={{width: '85%', marginTop: '5px', textAlign: 'center'}}
                onFocus={handleOnFocusDocumentTitle}
                value={file.title}
                onChange={(event) => handleTitleChange(index, event)} 
            />            
            <button onClick={() => handleSaveIconClick(index)} 
                disabled={isLoading}
                className="btn btn-secondary">Save</button>
        </>
            : 
            */}
            <div style={{paddingTop: '14px'}}> 
                {file?.title}
            </div>
        {/* } */}
    </div>
</div>    
</>
    );
})}

<div className="square dashboard--view-documents-box" 
    style={{float: 'left', marginTop: '15px'}}
    onClick={handleShowModal}
    >
    <img src={IconPlus} alt="plus" style={{height: '33px', width: '33px', marginTop: '50px', marginLeft: '55px'}} />          
</div>    

</div>

                </div>
            </div>
        </CompanyLayout>
    )
}

export default CompanyDocuments;
