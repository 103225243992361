import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import {
  customerListJobs,
} from '../../../services/requests'
import CustomerLayout from '../../../layouts/CustomerLayout'
import DashboardTable from '../../../components/Dashboard/Table'
import { getToken } from '../../../services/token';
import IconPlusWhite from "../../../../src/assets/icons/icon-plus-white.png"
import IconNoJobs from "../../../../src/assets/icons/icon-no-jobs.png"

const CustomerJobsList = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false);
  const [jobs, setJobs] = useState(null);
  const location = useLocation();

  const [stateMessageVisible] = useState(true)
  const jobUpdated = location.state?.jobUpdated;
  const jobAdded = location.state?.jobAdded;
  const jobDeleted = location.state?.jobDeleted;

  const loadData = async (pageSupplied) => {
    if (!isLoading) {
      setLoading(true);
      var localPage = 1

      if (searchParams.get('page') || pageSupplied) {
        searchParams.set('page', pageSupplied)
        localPage = searchParams.get('page')
      }

      const resp = await customerListJobs(localPage, inputDeliveredRef?.current?.checked);
      setJobs(resp?.jobs);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!getToken()) { navigate("/") }

    loadData('');
    document.title = 'Smart Haul - Customer - Jobs';

    const intervalId = setInterval(() => {
      // Reload data every 5 seconds
      loadData('');
      console.log('Reloading data')
    }, 60000);

    return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
    // eslint-disable-next-line
  }, []);

  const viewRow = async (id) => {
    navigate("/customer/jobs/" + id);
  }

  const handleAddJobClick = async (id) => {
    navigate("/customer/jobs/add");
  }

  const editRow = async (id) => {
    navigate("/customer/jobs/" + id + "/edit")
  }
  const deleteRow = async (id) => {
    navigate("/customer/jobs/" + id + "/?delete=true")
  }

  const inputDeliveredRef = useRef(null);

  const handleClickFilterText = (type) => {
    if (type === "delivered") { 
        inputDeliveredRef.current.checked = !inputDeliveredRef.current.checked 
        loadData(1)
    }
  }

  const handleClickFilter = (type) => {
    if (type === "delivered") {
        console.log('clicked delivered: ' + inputDeliveredRef.current.checked)
        loadData(1)
    }
  }

  return <>
    <CustomerLayout breadcrumbs={['Customers']}>

      {stateMessageVisible ?
        <>
          {jobUpdated ? (
            <div className={'alert alert-success'}>The job has now been saved.</div>
          ) : null}
          {jobDeleted ? (
            <div className={'alert alert-success'}>The job has now been deleted.</div>
          ) : null}
          {jobAdded ? (
            <div className={'alert alert-success'}>The job has now been added.</div>
          ) : null}
        </>
        : ''}

          <div style={{ width: '100%', float:'left' }}>
            <div style={{ width: '250px', float: 'left' }}>
              <h1 className="dashboard-page--title">Jobs</h1>
            </div>
            <div style={{ width: '350px', float: 'right' }}>
              <button
                className="btn btn-primary"
                style={{ width: '165px', marginTop: '30px', float: 'right', height: '52px' }}
                onClick={() => handleAddJobClick()}
              ><img src={IconPlusWhite} style={{ width: '20px', height: '20px', marginLeft: '-5px' }} alt="plus" /> List a Job</button>
            </div>
          </div>

          <div className="row company-jobs__filter-box">
            <div className="company-jobs__filter-box-title">Filter</div>
            <div className="checkbox-container col">
              <input ref={inputDeliveredRef} type="checkbox" className="checkbox-input"
                onClick={() => handleClickFilter('delivered')}
              />
              <span className="checkbox-label" onClick={() => handleClickFilterText('delivered')}>Delivered</span>
            </div>
          </div>


      {jobs?.data?.length > 0 ?
        <>
          <DashboardTable
            columns={['ID', 'Job Description', 'Company Name', 'Job Status', 'Lowest Bid', 'Highest Bid']}
            columnsData={jobs}
            columnsFields={['id', 'description', 'company_name', 'status', 'bid_lowest', 'bid_highest']}
            loadData={(page) => loadData(page)}
            viewRow={(id) => viewRow(id)}
            editRow={(id) => editRow(id)}
            deleteRow={(id) => deleteRow(id)}
            modelType={'job'}
          />
        </>

        :
        <>
          <div className="container">
            <div className="dashboard-page__no-items-box row align-items-center justify-content-center">

              <img src={IconNoJobs} alt="no jobs" style={{ width: '200px', height: '200px' }} />
              <h1 className="dashboard-page__no-items-title">You don’t have any jobs</h1>

              <button
                className="btn btn-primary"
                style={{ width: '165px', marginTop: '30px', float: 'right', height: '52px' }}
                onClick={() => handleAddJobClick()}>
                <img src={IconPlusWhite} style={{ width: '20px', height: '20px', marginLeft: '-5px' }} alt="plus" /> List a Job
              </button>

            </div>
          </div>
        </>
      }

    </CustomerLayout>
  </>
}

export default CustomerJobsList;
