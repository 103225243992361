import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

const FlatpickrComponent = (props) => {
    const flatpickrRef = useRef(null);
  
    useEffect(() => {

      var newDateTimeString = ""
      var dateTimeParts = props?.value.split(" ");

      if (dateTimeParts.length > 1) {
        var dateParts = dateTimeParts[0].split("-")
        var timeParts = dateTimeParts[1].split(":")
        var newDatePart = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]
        var newTimePart = timeParts[0] + ":" + timeParts[1]
        newDateTimeString = newDatePart + " " + newTimePart  
      }

      flatpickr(flatpickrRef.current, {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        onChange: props?.onDateTimeChange,
        defaultDate: newDateTimeString
      });
  
      return () => {
        // Cleanup Flatpickr instance when the component unmounts
        // flatpickrRef.current.destroy();
      };
    
      // eslint-disable-next-line react-hooks/exhaustive-deps      
    }, [props]);
  
    return (
      <div>
        <input type="text" 
            ref={flatpickrRef} 
            placeholder={props?.placeholder} 
            className="form-control form_input"
            id={props?.name}
            name={props?.id}
        />        
      </div>
      
    );
  };
  
  export default FlatpickrComponent;
  