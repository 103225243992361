import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import './Jobs.css'
import {
    adminGetJob,
  } from '../../../services/requests'
import Layout from '../../../layouts/AdminLayout'
import DashboardViewItemHeader from '../../../components/Dashboard/ViewItemHeader'
import DashboardViewItemRow from '../../../components/Dashboard/ViewItemRow'
import { getToken } from '../../../services/token';
import moment from "moment";
import IconAddress from "../../../assets/icons/icon-address.png"
import DashboardTable from '../../../components/Dashboard/Table'
import { getStatusTooltip } from "../../../services/utils.js";
import { Tooltip } from 'react-tooltip'

const AdminJobView = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [job, setJob] = useState(null);

    const loadData = async () => {
        if (!isLoading) {
          setLoading(true);
          if (id) {
            const resp = await adminGetJob(id);
            setJob(resp?.job?.data);  
          }
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData('');
        document.title = 'Smart Haul - View Job';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    var companyName = "None"
    if (job?.company) {
        companyName = job?.company_name;
    }

    return <>
      <Layout breadcrumbs={['Jobs', "ID: " + job?.id]}>

<div className="row">
  <div className="col-md-8">

        <DashboardViewItemHeader />
        
        <div className="d-flex">
            <h1 className="dashboard-page--title">ID: {job?.id}</h1>

            <Tooltip id="tooltip-status" />
            <span className="dashboard-page--title-status"
              data-tooltip-id="tooltip-status"
              data-tooltip-content={
                getStatusTooltip(job?.status)
              }
              data-tooltip-place="top"
            >{job?.status}</span>
        </div>

        <div className="dashboard-page--description">
            {job?.description}
        </div>

        <DashboardViewItemRow
          columns={['Customer Name', 'Company Name']}
          columnsData={[job?.customer?.first_name + ' ' + job?.customer?.last_name, 
                        companyName]} />

        <DashboardViewItemRow
          columns={['Weight', 'Size']}
          columnsData={[job?.weight, job?.size]} 
          alternateRow={true}
        />

        <DashboardViewItemRow
          columns={['Forklift', 'Deliver By']}
          columnsData={[
            job?.forklift_required ? 'Yes' : 'No',             
            moment.utc(job?.deliver_by_datetime, 'YYYY-MM-DD HH:mm:ss').local()
                          .format("Do MMM Y HH:mmA")]}
        />

        <DashboardViewItemRow
          columns={['Flexible on Delivery Time', 'PO Number']}
          columnsData={[
            job?.deliver_by_flexible ? job?.deliver_by_flexible + ' day/s' : '',
            job?.po_number]} 
          alternateRow={true}          
        />

        <div className="job--view-address">

        <div className="job--view-address-box-title">Delivery Details</div>
        <div className="job--view-address-box">      

            <div className="row" style={{padding: 0}}>
                <div style={{width: '25px', float: 'left', height: '100%'}}>
                    <img src={IconAddress} alt="" style={{height: '16px', width: '16px'}} />
                </div>
                <div className="col">
                    <div className="job--view-delivery-address-label">
                        Collection address
                    </div>
                    <div className="job--view-delivery-address-value">
                        {job?.collection_address1}, {job?.collection_address2}, {job?.collection_city}, {job?.collection_county}, {job?.collection_postcode}
                    </div>
                    <div className="job--view-delivery-note">
                        {job?.collection_delivery_note}                        
                    </div>
                </div>
            </div>

            <div>
                <hr className="job--view-address-hr" />
            </div>

            <div className="row" style={{padding: 0}}>
                <div style={{width: '25px', float: 'left', height: '100%'}}>
                    <img src={IconAddress} alt="" style={{height: '16px', width: '16px'}} />
                </div>
                <div className="col">
                    <div className="job--view-delivery-address-label">
                        Delivery address
                    </div>
                    <div className="job--view-delivery-address-value">
                        {job?.deliver_address1}, {job?.deliver_address2}, {job?.deliver_city}, {job?.deliver_county}, {job?.deliver_postcode}
                    </div>
                </div>
            </div>

</div>            
            
        </div>

        <div className="job--view-bids-title">
          Bids
        </div>

        <DashboardTable 
          columns={['Bid', 'Company Name']}
          columnsData={job?.bids}
          columnsFields={['amount', 'company_name']}
          showPaging={"false"}
        />

  </div>
  <div className="col-md-4">

    <div className="job--view-timeline-title">
      Timeline
    </div>

    <div className="job--view-timeline-list">
    {job?.log?.map((log) => {
      return <>
      <div className="job--view-timeline-list-header">
        {log?.action.charAt(0).toUpperCase() + log?.action.slice(1)}
      </div>
      <div className="job--view-timeline-list-value">
        {moment.utc(log?.created_at, 'YYYY-MM-DD HH:mm:ss').local()
                          .format("Do MMM Y HH:mmA")}
      </div>      
      </>
    })}
    </div>

  </div>
</div>


      </Layout>
    </>
}

export default AdminJobView;
