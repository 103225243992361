
import LogoMain from "../assets/imgs/logo-main.png"
import LogoMainDark from "../assets/imgs/logo-main-dark.png"
import IconSocialFacebook from "../assets/icons/icon-social-fb.png"
import IconSocialInstagram from "../assets/icons/icon-social-insta.png"
import IconSocialX from "../assets/icons/icon-social-x.png"
import DashboardProfile from "../components/Dashboard/Profile";
import "./css/Sidebar.css"
import "./css/CustomerLayout.css"
import { getRole } from '../services/token';
import { useNavigate } from 'react-router-dom';

export default function CustomerLayout({ children, breadcrumbs }) {

    const navigate = useNavigate() 
    const handleClickLogo = () => {
        if (getRole() === "customer") {
            navigate("/customer/jobs");
        }
    }

    return (
    <>
<div className="container">
    <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10" style={{marginTop: '15px'}}>

            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src={LogoMainDark} alt="logo" 
                            style={{height:'42px', width: '95px', cursor: 'pointer'}} 
                            onClick={() => handleClickLogo()} />
                    </div>
                    <div className="col-md-6">
                        <DashboardProfile />
                    </div>
                </div>
            </div>

        </div>
        <div className="col-md-1"></div>
    </div>
</div>

<hr style={{height: '1px'}}/>

<div className="container">
    <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10" style={{marginTop: '15px', marginBottom: '25px'}}>
            {children}
        </div>
        <div className="col-md-1"></div>
    </div>
</div>

<footer className="text-center">
    <div className="container text-center text-md-start">
      <div className="row">
        <div className="col-md-4 mx-auto text-center align-middle" style={{paddingTop: '18px'}}>
            <img src={LogoMain} alt="logo" style={{height:'42px', width: '95px'}} />
        </div>
        <div className="col-md-4 mx-auto text-center text-nowrap" style={{paddingTop: '28px'}}>
            <span className="footer__text">&copy; 2024 Smart Haul</span>
            <span className="footer__text">&nbsp;&nbsp;|&nbsp;&nbsp;</span> <a className="footer__link" href="#/">Privacy Policy</a>
            <span className="footer__text">&nbsp;&nbsp;|&nbsp;&nbsp;</span> <a className="footer__link" href="#/">Terms & Conditions</a>
        </div>
        <div className="col-md-4 mx-auto text-center" style={{paddingTop: '25px'}}>
            <img className="footer__social-icon" src={IconSocialFacebook} alt="facebook" />
            <img className="footer__social-icon" src={IconSocialInstagram} alt="instagram" />
            <img className="footer__social-icon" src={IconSocialX} alt="x" />
        </div>
      </div>
    </div>
</footer>
    </>
  )
}