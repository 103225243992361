import React, { useState, useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import { customerGetJob, customerDeleteJob, customerGetCheckoutUrl } from '../../../services/requests'
import CustomerLayout from '../../../layouts/CustomerLayout'
import DashboardViewItemHeader from '../../../components/Dashboard/ViewItemHeader'
import DashboardViewItemRow from '../../../components/Dashboard/ViewItemRow'
import { getToken } from '../../../services/token';
import moment from "moment";
import IconAddress from "../../../assets/icons/icon-address.png"
import DashboardTable from '../../../components/Dashboard/Table'
import Modal from 'react-bootstrap/Modal';
import { getErrorMessage, getStatusTooltip } from "../../../services/utils.js";
import { Tooltip } from 'react-tooltip'

const CustomerJobView = () => {

  const { id } = useParams()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false);
  const [job, setJob] = useState(null);
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState(null)

  const loadData = async () => {
    if (!isLoading) {
      setLoading(true);
      if (id) {
        const resp = await customerGetJob(id);
        setJob(resp?.job?.data);
        if (searchParams.get('delete')) {
          setShowDeleteConfirmation(true)
        }

        if (resp?.job?.data?.status === "confirmed" && resp?.job?.data?.payment_status === "pending") {
          const respCheckoutUrl = await customerGetCheckoutUrl(id);
          setCheckoutUrl(respCheckoutUrl.url)
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!getToken()) { navigate("/") }
    loadData('');
    document.title = 'Smart Haul - View Job';

    const intervalId = setInterval(() => {
      loadData('');
    }, 60000);
    return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount

    // eslint-disable-next-line
  }, []);

  function loadEditForm() {
    navigate(`/customer/jobs/${id}/edit`)
  }

  // delete job
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
  function loadDeleteConfirmation() {
    setErrorMessage(null)
    setShowDeleteConfirmation(true)
  }
  const handleConfirmDelete = async (event) => {
    try {
      const resp = await customerDeleteJob(id);
      if (resp?.result === 'success') {
        navigate("/customer/jobs", { state: { jobDeleted: true } });
      }
      else {
        setErrorMessage(getErrorMessage(resp));
      }
    } catch (error) {
      setErrorMessage("There was an error deleting the job.")
    }
  }

  const redirectForPayment = async (event) => {
    document.location.href = checkoutUrl
  }

  return <>
    <CustomerLayout breadcrumbs={['Jobs', "ID: " + job?.id]}>

      <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation} centered>
        <Modal.Header className="px-4 edit-form__header" closeButton>
          <Modal.Title className="edit-form__title">
            Delete this job?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-form__body">

          {errorMessage ?
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} className="alert alert-danger" role="alert"></div>
            : null}

          <p>Are you sure you want to delete this job from the system?</p>
        </Modal.Body>
        <Modal.Footer className="edit-form__footer px-4">

          <div style={{ width: '100%' }}>
            <div className="row">
              <div className="col">
                <button type="button" className="btn btn-secondary" onClick={() => handleCloseDeleteConfirmation()}>Cancel</button>
              </div>
              <div className="col">
                <button type="button" className="btn btn-primary" onClick={() => handleConfirmDelete()}>Delete</button>
              </div>
            </div>
          </div>

        </Modal.Footer>
      </Modal>

      <DashboardViewItemHeader
        editAction={job?.status === "pending" ? () => loadEditForm() : null}
        deleteAction={() => loadDeleteConfirmation()}
        otherAction={
          checkoutUrl ?
            () => redirectForPayment()
            : null
        }
        otherActionText={
          checkoutUrl ?
            "Pay €" + job?.accepted_bid?.amount
            : ""
        }
        navigateBackUrl={'/customer/jobs'}
      />

      <div className="row">
        <div className="col-md-8">

          <div className="d-flex">
            <h1 className="dashboard-page--title">ID: {job?.id}</h1>

            <Tooltip id="tooltip-status" />
            <span className="dashboard-page--title-status"
              data-tooltip-id="tooltip-status"
              data-tooltip-content={
                getStatusTooltip(job?.status)
              }
              data-tooltip-place="top"
            >{job?.status}</span>
          </div>

          <DashboardViewItemRow
            columns={['Job Description']}
            columnsData={[job?.description]}
            alternateRow={false}
          />

          <DashboardViewItemRow
            columns={['Weight', 'Size']}
            columnsData={[job?.weight, job?.size]}
            alternateRow={true}
          />

          <DashboardViewItemRow
            columns={['Forklift', 'PO Number']}
            columnsData={[
              job?.forklift_required ? 'Yes' : 'No',
              job?.po_number]}
          />

          <div className="job--view-address">

            <div className="job--view-address-box-title">Delivery Details</div>
            <div className="job--view-address-box">

              <div className="row" style={{ padding: 0 }}>
                <div style={{ width: '25px', float: 'left', height: '100%' }}>
                  <img src={IconAddress} alt="" style={{ height: '16px', width: '16px' }} />
                </div>
                <div className="col">
                  <div className="job--view-delivery-address-label">
                    Collection address
                  </div>
                  <div className="job--view-delivery-address-value">
                    {job?.collection_address}
                  </div>
                  <div className="job--view-delivery-note">
                    {job?.collection_delivery_note}
                  </div>
                </div>
              </div>

              <div>
                <hr className="job--view-address-hr" />
              </div>

              <div className="row" style={{ padding: 0 }}>
                <div style={{ width: '25px', float: 'left', height: '100%' }}>
                  <img src={IconAddress} alt="" style={{ height: '16px', width: '16px' }} />
                </div>
                <div className="col">
                  <div className="job--view-delivery-address-label">
                    Delivery address
                  </div>
                  <div className="job--view-delivery-address-value">
                    {job?.deliver_address}
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div className="col-md-4">

          <div className="job--view-timeline-title">
            Top Bids
          </div>

          {job?.paid_at ?

            <>
              <DashboardTable
                columns={['Bid', 'Company Name']}
                columnsData={job?.bids}
                columnsFields={['amount', 'company_name']}
                showPaging={"false"}
                limitRowCount={5}
              />
            </>
            :
            <>
              <DashboardTable
                columns={['Bid']}
                columnsData={job?.bids}
                columnsFields={['amount']}
                showPaging={"false"}
                limitRowCount={5}
              />
            </>
          }
          <button className="btn btn-simple" style={{ marginTop: '10px' }}
            onClick={() => navigate('bids')}
          >View All</button>

          <div className="job--view-timeline-title">
            Timeline
          </div>

          <div className="job--view-timeline-list">
            {job?.log?.map((log) => {
              return <>
                <div className="job--view-timeline-list-header">
                  {log?.action.charAt(0).toUpperCase() + log?.action.slice(1)}
                </div>
                <div className="job--view-timeline-list-value">
                  {moment.utc(log?.created_at, 'YYYY-MM-DD HH:mm:ss').local()
                    .format("Do MMM Y HH:mmA")}
                </div>
              </>
            })}
          </div>

        </div>
      </div>

    </CustomerLayout>
  </>
}

export default CustomerJobView;
