import React, {useState, useEffect} from "react";
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import {
    adminResetCustomerPassword,
    adminUpdateCustomer,
    adminDeleteCustomer,
    adminGetCustomer,
  } from '../../../services/requests'
import Layout from '../../../layouts/AdminLayout'
import DashboardViewItemHeader from '../../../components/Dashboard/ViewItemHeader'
import DashboardViewItemRow from '../../../components/Dashboard/ViewItemRow'
import FormFieldSet from '../../../components/Dashboard/FormFieldSet'
import { getToken } from '../../../services/token';
import Modal from 'react-bootstrap/Modal';

const AdminCustomerView = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);
    const [searchParams] = useSearchParams();

    const loadData = async () => {
        if (!isLoading) {
          setLoading(true);
          if (id) {
            const resp = await adminGetCustomer(id);
            setCustomer(resp?.customer?.data);  
            if (searchParams.get('edit')) {
              setShowEditForm(true)
            }
            else if (searchParams.get('delete')) {
              setShowDeleteConfirmation(true)
            }
          }
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData('');
        document.title = 'Smart Haul - View Customer';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    // edit customer
    const [showEditForm, setShowEditForm] = useState(false);
    const handleCloseEditForm = () => setShowEditForm(false);
    function loadEditForm() {
        setLoading(false)
        setErrorMessage(null)
        setShowEditForm(true)
    }
    const handleSaveClick = async (event) => {    

      var body = {
        first_name: customer?.first_name,
        last_name: customer?.last_name,
        phone: customer?.phone,
        email: customer?.email,
      };

      try {
        const resp = await adminUpdateCustomer(id, { ...body });
        if (resp?.result === 'success') {
            navigate("/admin/customers?updated=true")
        }
        else {              
          if (resp.messages[0]) {                
            var errorMessageAll = ""
            for (const key in resp.messages[0]) {
              errorMessageAll = errorMessageAll + " " + resp.messages[0][key];
            }  
            setErrorMessage(errorMessageAll)
          }
          else {
            setErrorMessage("There was an error updating the customer.")
          }
        }
      } catch (error) {
        setErrorMessage("There was an error updating the customer.")
      }
    }

    // delete customer
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
    function loadDeleteConfirmation() {
        setErrorMessage(null)
        setShowDeleteConfirmation(true)
    }
    const handleConfirmDelete = async (event) => {    
      try {
        const resp = await adminDeleteCustomer(id);
        if (resp?.result === 'success') {
            navigate("/admin/customers?deleted=true")
        }
        else {              
          if (resp.messages[0]) {                
            var errorMessageAll = ""
            for (const key in resp.messages[0]) {
              errorMessageAll = errorMessageAll + " " + resp.messages[0][key];
            }  
            setErrorMessage(errorMessageAll)
          }
          else {
            setErrorMessage("There was an error deleting the customer.")
          }
        }
      } catch (error) {
        setErrorMessage("There was an error deleting the customer.")
      }
    }

    const [showResetPasswordComplete, setShowResetPasswordComplete] = useState(false);
    const handleResetPasswordComplete = () => setShowResetPasswordComplete(false);
    const handleResetPasswordClick = async (event) => {      
      setLoading(true)
      try {
        const resp = await adminResetCustomerPassword(id);
        if (resp?.result === 'success') {
            setShowEditForm(false)
            setShowResetPasswordComplete(true)
        }
        else {              
          if (resp.messages[0]) {                
            var errorMessageAll = ""
            for (const key in resp.messages[0]) {
              errorMessageAll = errorMessageAll + " " + resp.messages[0][key];
            }  
            setErrorMessage(errorMessageAll)
          }
          else {
            setErrorMessage("There was an error reseting the customer password.")
          }
        }
      } catch (error) {
        setErrorMessage("There was an error reseting the customer password.")
      }
    }

    return <>
      <Layout breadcrumbs={['Customers', customer?.name]}>

            <Modal show={showResetPasswordComplete} onHide={handleResetPasswordComplete} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Password reset
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">
                <p>The customer has now been sent an email with instructions on how to reset their password.</p>
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">
                <button type="button" className="btn btn-primary" onClick={() => handleResetPasswordComplete()}>OK</button>
              </Modal.Footer>
            </Modal>        

            <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Delete this customer?                  
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">
                <p>Are you sure you want to delete this customer from the system?</p>
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                    <button type="button" className="btn btn-secondary" onClick={() => handleCloseDeleteConfirmation()}>Cancel</button>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-primary" onClick={() => handleConfirmDelete()}>Delete</button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>                  

            <Modal show={showEditForm} onHide={handleCloseEditForm} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Edit customer                  
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">

                {errorMessage ? 
                <>
                <div className="container" style={{width: '100%'}}>
                  <div className="row edit--form_row">
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                </div>
                </> : ''}

                <div className="container">
                  
                  <FormFieldSet 
                    model={customer}
                    fields={['First Name', 'Last Name']}
                    fieldsData={['first_name', 'last_name']}
                    fieldsOnChange={[
                      (event) => setCustomer({ ...customer, first_name: event?.target?.value }),
                      (event) => setCustomer({ ...customer, last_name: event?.target?.value }),
                    ]}
                  />

                  <FormFieldSet 
                    model={customer}
                    fields={['Phone']}
                    fieldsData={['phone']}
                    fieldsOnChange={[
                      (event) => setCustomer({ ...customer, phone: event?.target?.value }),
                    ]}
                  />

                  <FormFieldSet 
                    model={customer}
                    fields={['Email Address']}
                    fieldsData={['email']}
                    fieldsOnChange={[
                      (event) => setCustomer({ ...customer, email: event?.target?.value }),
                    ]}
                  />

                </div>                
                
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                      <button type="button" className="btn btn-primary" onClick={() => handleSaveClick()}
                        disabled={isLoading}
                      >Save</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button type="button" className="btn btn-secondary" onClick={() => handleResetPasswordClick()}
                        disabled={isLoading}
                      >Reset Password</button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>    

<div className="row">
  <div className="col-md-8">


        <DashboardViewItemHeader 
            editAction={() => loadEditForm()}
            deleteAction={() => loadDeleteConfirmation()}
        />

        <h1 className="dashboard-page--title">{customer?.name}</h1>

        <DashboardViewItemRow
          columns={['Email Address', 'Phone']}
          columnsData={[customer?.email, customer?.phone]} />

        <DashboardViewItemRow
          columns={['City', 'County']}
          columnsData={[customer?.city, customer?.county]} 
          alternateRow={true}
          />

        <DashboardViewItemRow
          columns={['Postcode', 'Vat Number']}
          columnsData={[customer?.postcode, customer?.vat_number]} />

  </div>
</div>

      </Layout>
    </>
}

export default AdminCustomerView;
