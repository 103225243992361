import React, { useState, useEffect } from "react";
import '../../Auth/Login.css';
import {
    changeEmailVerify
} from "../../../services/requests"
import CustomerLayout from "../../../layouts/CustomerLayout";
import "./Profile.css"
import CustomerSideProfileMenu from "../../../components/Dashboard/Customers/SideProfileMenu";
import FormFieldSet from "../../../components/Dashboard/FormFieldSet";
import { getErrorMessage } from "../../../services/utils";
import { useNavigate } from 'react-router-dom';
import { setTokenOnly, setEmail } from "../../../services/token"

const CustomerEmailVerify = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);    
    const [successMessage, setSuccessMessage] = useState(false);    
    const navigate = useNavigate()     

    const [customer, setCustomer] = useState({
        code: "",
    });

    const formValueChanged = async (fieldValue) => {
        var myValue = fieldValue['field']
        setCustomer({ ...customer, [myValue]: fieldValue['value'] })
    }

    const handleSaveClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)
        setSuccessMessage(null)

        var body = {
            code: customer?.code,
        };
    
        try {
            const resp = await changeEmailVerify({ ...body });
            setIsLoading(false)
            if (resp?.result === 'success') {

                console.log(resp)

                setEmail(resp?.email);
                setTokenOnly(resp?.token);
                navigate("/customer/email?changed=true")
            }
            else {             
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp))
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage("There was an error verifying your email code.")
        }
    }    

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true)        
            setIsLoading(false)
        }
        loadData()
        document.title = 'Smart Haul - Customer - Email';
        // eslint-disable-next-line
    }, []);    

    return (
        <CustomerLayout>
            <div className="row">
                <div className="col-3">
                    <CustomerSideProfileMenu selected="email" />
                </div>
                <div className="col-9">
                    {successMessage ? 
                        <div dangerouslySetInnerHTML={{__html: successMessage}} className="alert alert-success" role="alert"></div> 
                        : ''}
                    {errorMessage ? 
                        <div dangerouslySetInnerHTML={{__html: errorMessage}} className="alert alert-danger" role="alert"></div> 
                        : ''}
                    <h1 className="profile--form__header">Verify email</h1>
                    <div className="profile--form__description">Please enter the code you received to verify your email address.</div>

                    <FormFieldSet 
                        showLabels={false}
                        model={customer}
                        fields={['Code']}
                        fieldsData={['code']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'code', value: event?.target?.value}),
                        ]}/>

                    <button 
                        type="button" 
                        className="btn btn-primary login__button"
                        onClick={() => handleSaveClick()}
                        disabled={isLoading}
                        style={{width: '200px'}}
                    >
                        Verify
                    </button>
                </div>
            </div>
        </CustomerLayout>
    )
}

export default CustomerEmailVerify;
