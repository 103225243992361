import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css";
import "./JobsList.css";
import { companyListJobs } from '../../../services/requests';
import CompanyLayout from '../../../layouts/CompanyLayout';
import { getToken } from '../../../services/token';
import IconNoJobs from "../../../../src/assets/icons/icon-no-jobs.png";
import IconUnapproved from "../../../../src/assets/icons/icon-unapproved.png";
import { irishCounties } from "../../../constants/counties";
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import JobsTable from "../../../components/Dashboard/JobsTable";
import { getErrorMessage } from "../../../services/utils";
import { useLocation } from "react-router-dom";

const CompanyJobsList = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [jobs, setJobs] = useState(null);
    const [deliverCounty, setDeliverCounty] = useState("");
    const [collectionCounty, setCollectionCounty] = useState("");
    const [companyApproved, setCompanyApproved] = useState(false);
    // eslint-disable-next-line
    const [startDate, setStartDate] = useState(null);
    // eslint-disable-next-line
    const [endDate, setEndDate] = useState(null);
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(location.state?.successMessage);  

    const flatpickrRef = useRef(null);
    const collectionCountyRef = useRef(null);
    const deliverCountyRef = useRef(null);

    const loadData = async (page, startDateSupplied, endDateSupplied ) => {
        if (!isLoading) {
            setLoading(true);
            const localPage = searchParams.get('page') || 1;
            const localCollectionCounty = collectionCountyRef.current?.value || '';
            const localDeliverCounty = deliverCountyRef.current?.value || '';

            var localStartDate = ""
            var localEndDate = ""

            console.log("startdate: " + startDate)
            console.log("endDate: " + endDate)

            if (startDate) { 
                localStartDate = startDate
                localEndDate = endDate 
            }

            if (startDateSupplied) { 
                localStartDate = startDateSupplied 
                localEndDate = endDateSupplied 
            }

            const resp = await companyListJobs(localPage, 
                                                localCollectionCounty, localDeliverCounty, 
                                                localStartDate, localEndDate);
            setCompanyApproved(true);
            if (resp?.result === 'error' && getErrorMessage(resp) === "Company user is not approved.") {
                setCompanyApproved(false);
            }
            setJobs(resp?.jobs);
            setLoading(false);
        }
    };

    useEffect(() => {

        if (localStorage.getItem('message')) {
            setSuccessMessage(localStorage.getItem('message'));
            localStorage.removeItem('message');
        }

        if (!getToken()) { 
            navigate("/");
        } else {
            if (flatpickrRef.current) {
                flatpickr(flatpickrRef.current, {
                    enableTime: false,
                    dateFormat: 'd-m-Y',
                    onChange: handleDateTimeChange,
                    defaultDate: "",
                    mode: "range"
                });
            }

            loadData('');
            document.title = 'Smart Haul - Company - Jobs';
        }

        const intervalId = setInterval(() => {
            loadData('');            
        }, 60000);
        return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount

        // eslint-disable-next-line
    }, [companyApproved]);

    const handleCollectionCountyChange = (event) => {
        setCollectionCounty(event.target.value);
        loadData(1);
    };

    const handleDeliverCountyChange = (event) => {
        setDeliverCounty(event.target.value);
        loadData(1);
    };

    const handleDateTimeChange = (selectedDates) => {        
        const [start, end] = selectedDates;
        const localStartDate = start ? start.toLocaleDateString() : '';
        const localEndDate = end ? end.toLocaleDateString() : '';

        setStartDate(localStartDate);
        setEndDate(localEndDate);

        loadData(1, localStartDate, localEndDate);
    };

    const handleClearFilters = () => {
        if (flatpickrRef.current) {
            const flatpickrInstance = flatpickrRef.current._flatpickr;
            if (flatpickrInstance) {
                flatpickrInstance.clear();
            }
        }
        setStartDate(null);
        setEndDate(null);
        setDeliverCounty('');
        setCollectionCounty('');
        loadData(1, null, null);
    };

    const viewRow = (id) => {    
        navigate("/company/jobs/" + id);
    };

    return (
        <CompanyLayout breadcrumbs={['Companies']} selected={"available_jobs"}>
            {!companyApproved ? (
                <div className="container">
                    <div className="dashboard-page__no-items-box row align-items-center justify-content-center">
                        <img src={IconUnapproved} alt="no jobs" style={{ width: '200px', height: '200px', paddingBottom: '25px' }} />
                        <h1 className="dashboard-page__no-items-title">Waiting for account approval.</h1>
                        <div className="dashboard-page__no-items-subtitle">
                            Your account is waiting for our administrator approval. Please check back later.
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <h1 className="dashboard-page--title">Available Jobs</h1>

                    {successMessage ? (
                        <div className={'alert alert-success'}>{successMessage}</div>
                    ) : null}                

                    <div className="row company-jobs__filter-box">
                        <div className="company-jobs__filter-box-title">Filter</div>
                        <div className="col">
                        <select className="btn btn-simple btn-filter select-filter" ref={collectionCountyRef} onChange={handleCollectionCountyChange} style={{width: '200px'}} name="collection_county" value={collectionCounty}>
    <option value="">Collection County</option>
    {irishCounties.map((county, index) => (
        <option key={county} value={county}>{county}</option>
    ))}
</select>

<select className="btn btn-simple btn-filter select-filter" ref={deliverCountyRef} onChange={handleDeliverCountyChange} style={{width: '180px'}} name="deliver_county" value={deliverCounty}>
    <option value="">Delivery County</option>
    {irishCounties.map((county, index) => (
        <option key={county} value={county}>{county}</option>
    ))}
</select>

                            <input type="text" style={{width: '260px', color: '#000'}} ref={flatpickrRef} placeholder="Select Date" className="btn btn-simple btn-filter select-filter" id="date" name="date" />

                            <button className="btn btn-filter btn-simple" style={{width: '150px', float: 'right'}} onClick={handleClearFilters}>Clear Filters</button>
                        </div>
                    </div>

                    {jobs?.data?.length > 0 ? (
                        <JobsTable 
                            jobs={jobs}
                            loadData={(page) => loadData(page)}
                            viewRow={(id) => viewRow(id)}
                        />
                    ) : (
                        <div className="container">
                            <div className="dashboard-page__no-items-box row align-items-center justify-content-center">
                                <img src={IconNoJobs} alt="no jobs" style={{ width: '200px', height: '200px' }} />
                                <h1 className="dashboard-page__no-items-title">There are no jobs currently available.</h1>
                            </div>
                        </div>
                    )}
                </>
            )}
        </CompanyLayout>
    );
};

export default CompanyJobsList;
