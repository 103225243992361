import '../Auth/Login.css';
import LogoMain from "../../assets/imgs/logo-main-dark.png"

const Support = () => {

    return (
      <>
        <div className="login__background">
          <div className="div-center" style={{display:"block", width: "75%", height: "100%"}}>
            <div className="content" style={{ textAlign: 'left' }}>
              <div style={{textAlign: 'center', paddingTop: '50px'}}>
              <a href="/"><img src={LogoMain} style={{ width: '200px', textAlign: "center" }} className="login__logo" alt="" /></a>
              </div>
              <h1 className="login__header">Support</h1>

<p>We're here to help you with any questions or issues you may encounter. 
    You can reach us via email at <a href="mailto:support@smarthaul.ie">support@smarthaul.ie.</a></p>

<p>If you're experiencing technical issues, please provide us with 
    the following details when contacting support:
</p>

<ul>
<li>Your device model</li>
<li>Operating system version</li>
<li>App version (if available)</li>
<li>A detailed description of the issue</li>
</ul>

<p>Our support team will get back to you as soon as possible.</p>

            </div>
          </div>
        </div>
      </>
    );

}

export default Support;
