import {createStore} from "redux";
import {LOGIN_USER, LOGOUT_USER} from "./constants/types";
import {setToken} from "./services/token";

const intitialState = {
    authenticated: false,
};

const reducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            setToken(action.payload.token);
            return {...state, authenticated: true};
        case LOGOUT_USER:
            setToken('');
            return {...state, authenticated: false};

        default:
            return state;
    }
};

const store = createStore(reducer);

export default store;
