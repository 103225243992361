import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import "../../../layouts/css/Website.css"
import { companyGetJob, companyBidOnJob, companyUpdateBidOnJob,
        companyConfirmBidOnJob, companyListDrivers, companyAssignDriver } from '../../../services/requests'
import CompanyLayout from '../../../layouts/CompanyLayout'
import DashboardViewItemHeader from '../../../components/Dashboard/ViewItemHeader'
import DashboardViewItemRow from '../../../components/Dashboard/ViewItemRow'
import { getRoleId, getToken } from '../../../services/token';
import moment from "moment";
import IconAddress from "../../../assets/icons/icon-address.png"
import IconPhone from "../../../assets/icons/icon-phone.png"
import IconEmail from "../../../assets/icons/icon-email.png"
import IconTick from "../../../assets/icons/icon-tick-orange-circle.png"
import Modal from 'react-bootstrap/Modal';
import { getErrorMessage, getStatusTooltip } from "../../../services/utils.js";
import { Tooltip } from 'react-tooltip'

const CompanyJobView = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [job, setJob] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageBid, setErrorMessageBid] = useState(false);
    const [errorMessageAssign, setErrorMessageAssign] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null)
    const [note, setNote] = useState(null);
    const [amount, setAmount] = useState(null);
    const [myBid, setMyBid] = useState(null)
    const [drivers, setDrivers] = useState(null);
    const [selectedDriverId, setSelectedDriverId] = useState(null)
 
    const loadData = async () => {
        if (!isLoading) {
          setIsLoading(true);
          if (id) {
            const resp = await companyGetJob(id);
            setJob(resp?.job?.data);

            // loop around bids
            resp?.job?.data?.bids?.data?.map((bid, index) => {
                if (String(bid?.company_id) === getRoleId()) {
                    setMyBid(bid)
                    setAmount(parseFloat(myBid?.amount.replace(/[^\d.-]/g, "")))
                    setNote(bid?.note)
                }
                return null
            })
          }
          setIsLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData('');
        document.title = 'Smart Haul - View Job';

        const intervalId = setInterval(() => {
          loadData('');
        }, 60000);
        return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount    

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    // place bid
    const [showPlaceBidForm, setShowPlaceBidForm] = useState(false);
    const handleClosePlaceBidForm = () => setShowPlaceBidForm(false);
    function handleShowEditBidForm() {
      setErrorMessage(null)
      setErrorMessageBid(null)
      setShowPlaceBidForm(true)
    }
    function handleShowPlaceBidForm() {
        setErrorMessage(null)
        setErrorMessageBid(null)
        setShowPlaceBidForm(true)
    }
    const handlePlaceBid = async (event) => {
        try {
            setIsLoading(true)
            var data = { amount: amount, note: note }
            var resp = null
            if (myBid) {
              resp = await companyUpdateBidOnJob(id, data);
            }
            else {
              resp = await companyBidOnJob(id, data);              
            }
            setIsLoading(false)
            if (resp?.result === 'success') {
                setShowPlaceBidForm(false)
                localStorage.setItem('message', "Your bid on this job has now been placed.")
                navigate("/company/jobs");
            }
            else {                              
                if (resp.result === 'error') {
                  setErrorMessageBid(getErrorMessage(resp));
                }
            }
        } catch (error) {
          setErrorMessageBid("There was an error bidding on this job.")
        }
    }

    const handleConfirmBid = async (event) => {
      try {
        setIsLoading(true)
        var resp = await companyConfirmBidOnJob(id, myBid?.id);
        setIsLoading(false)
        if (resp?.result === 'success') {
            navigate("/company/jobs/current/" + id + "?confirmed=true")
            loadData()
        }
        else {                              
            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
        }
        } catch (error) {
          setErrorMessage("There was an error confirming the bid on this job.")
      }      
    }

    // assign to driver
    const [showAssignDriverForm, setShowAssignDriverForm] = useState(false);
    const handleCloseAssignDriverForm = () => setShowAssignDriverForm(false);
    const handleShowAssignDriverForm = async (event) => {
        const resp = await companyListDrivers(1);
        setSelectedDriverId(null)
        setDrivers(resp?.drivers);      
        setErrorMessage(null)
        setErrorMessageAssign(null)
        setShowAssignDriverForm(true)
    }
    const handleAssignDriver = async (event) => {
        try {
            setIsLoading(true)
            var data = { driver_id: selectedDriverId }
            var resp = await companyAssignDriver(id, data)
            setIsLoading(false)
            if (resp?.result === 'success') {
                setShowAssignDriverForm(false)
                setSuccessMessage("Your driver has now been assigned to this job.")
                loadData()
            }
            else {                              
                if (resp.result === 'error') {
                  console(getErrorMessage(resp))
                    setErrorMessageAssign(getErrorMessage(resp));
                }
            }
        } catch (error) {
            setErrorMessage("There was an error assigning the driver to this job.")
        }
    }

    const currentUrl = window.location.href;
    const isCurrentInUrl = currentUrl.includes("current");
    var altRow = false

    var otherHtml = ""
    if (myBid) {
      otherHtml = "<span class='dashboard-page--info-pill'>Your Bid</span> " 
        + "<span class='dashboard-page--info-pill-value'>" + myBid.amount + "</span>" 

      if (job?.status === "assigned") {
        otherHtml += "<span class='dashboard-page--info-pill'>Driver</span>" 
        + "<span class='dashboard-page--info-pill-value'>" + job?.driver?.first_name + " " + job?.driver?.last_name + "</span>"
      }
    }
  
    return <>
      <CompanyLayout breadcrumbs={['Jobs', "ID: " + job?.id]} selected={isCurrentInUrl ? 'current_jobs' : 'available_jobs'}>

        <Modal show={showAssignDriverForm} onHide={handleCloseAssignDriverForm} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  Assign to Driver
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">

              {errorMessageAssign ? 
              <><div dangerouslySetInnerHTML={{__html: errorMessageAssign}} 
              className="alert alert-danger" role="alert">        
              </div></> : ''}

                {drivers?.data?.map((driver, index) => {
                  altRow = !altRow
                  return <>
                  <div className={altRow ? "company-view-job__driver-list-box-alt" 
                                        : "company-view-job__driver-list-box"}
                        onClick={() => setSelectedDriverId(driver?.id)}>                                        
                            <div className="company-jobs__list-job-id">
                            <div className="row">
                                <div className="col">
                                  {driver?.name}
                                </div>
                                <div className="col text-end">
                                  {selectedDriverId === driver?.id ?
                                  <img className="company-jobs__list-job-date-user" 
                                    style={{height: '31px', width: '31px'}}
                                    src={IconTick} alt="tick" />
                                  : 
                                  <><div style={{height: '31px'}}></div></>
                                  }
                                </div>
                                </div>
                            </div>
                            <div className="company-jobs__list-job-info">
                                <img className="company-jobs__list-job-date-user" src={IconEmail} alt="email" /> {driver?.email}
                                <img className="company-jobs__list-job-date-icon" src={IconPhone} alt="phone" /> {driver?.phone}
                            </div>
                    </div>
                  </>
                })}
              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                    <button type="button" className="btn btn-secondary" disabled={isLoading}
                        onClick={() => setShowAssignDriverForm(false)}>Cancel</button>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-primary" disabled={isLoading || !selectedDriverId}
                        onClick={() => handleAssignDriver()}>
                          Assign
                        </button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>                  

            <Modal show={showPlaceBidForm} onHide={handleClosePlaceBidForm} centered>
              <Modal.Header className="px-4 edit-form__header" closeButton>
                <Modal.Title className="edit-form__title">
                  {myBid ? "Edit your bid" : 'Enter your bid  '}                   
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="edit-form__body">

              {errorMessageBid ? 
                <>
                <div className="container" style={{width: '100%'}}>
                  <div className="row edit--form_row">
                    <div className="alert alert-danger" role="alert">
                      {errorMessageBid}
                    </div>
                  </div>
                </div>
                </> : ''}

                <div className="form-group edit--form_group">
                    <div className="form-group">
                        <div className="signup_input-wrapper">
                        <input className="form-control signup--form_group-control signup_input"
                            type="number"
                            onChange={(event) => setAmount(event.target.value)}
                            id="amount"
                            name="amount"
                            placeholder=" "
                            defaultValue={myBid ? parseFloat(myBid?.amount.replace(/[^\d.-]/g, "")) : ''}
                        />
                        <label className="signup--form_group-label" htmlFor="amount">Bid</label>
                        </div>
                    </div>
                </div>

                <div className="form-group edit--form_group">
                    <div className="form-group">
                        <div className="signup_input-wrapper">
                        <textarea placeholder=" " 
                            className="form-control signup--form_group-control signup_input"
                            style={{height: '120px'}}        
                            onChange={(event) => setNote(event.target.value)}
                            id="note"
                            name="note"
                            defaultValue={myBid ? myBid?.note : ""}
                        ></textarea>
                        <label className="signup--form_group-label" htmlFor="note">Note (conditions or experience)</label>
                        </div>
                    </div>
                </div>

              </Modal.Body>
              <Modal.Footer className="edit-form__footer px-4">

                <div style={{width: '100%'}}>
                  <div className="row">
                    <div className="col">
                    <button type="button" className="btn btn-secondary" disabled={isLoading}
                        onClick={() => setShowPlaceBidForm(false)}>Cancel</button>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-primary" disabled={isLoading}
                        onClick={() => handlePlaceBid()}>
                          {myBid ? "Save" : "Done"}
                        </button>
                    </div>
                  </div>  
                </div>

              </Modal.Footer>
            </Modal>                  

            <DashboardViewItemHeader        
          otherAction={
            job?.status === "pending" ? 
              myBid ? () => handleShowEditBidForm() : () => handleShowPlaceBidForm()
              : job?.status === "accepted" ?
                () => handleConfirmBid() 
              : job?.status === "waiting_assignment" ?
                () => handleShowAssignDriverForm() : null
          }
          otherActionText={
            job?.status === "pending" ? 
              myBid ? "Edit Bid" : "Place Bid"
              : 
              job?.status === "accepted" ?
              'Confirm Bid' : 
              job?.status === "waiting_assignment" ?
                'Assign to Driver' : ''
          }

          otherHtml={otherHtml}

          navigateBackUrl={isCurrentInUrl ? '/company/jobs/current' : '/company/jobs' }
        />


<div className="row">
  <div className="col-md-12">

          {errorMessage ? 
            <><div dangerouslySetInnerHTML={{__html: errorMessage}} 
            className="alert alert-danger" role="alert">        
          </div></> : ''}


          {successMessage ? (
            <div className={'alert alert-success'}>{successMessage}</div>
          ) : null}                
  </div>
</div>
<div className="row">
  <div className="col-md-8">

        <div className="d-flex">
            <h1 className="dashboard-page--title">ID: {job?.id}</h1>

            <Tooltip id="tooltip-status" />
            <span className="dashboard-page--title-status"
              data-tooltip-id="tooltip-status"
              data-tooltip-content={
                getStatusTooltip(job?.status)
              }
              data-tooltip-place="top"
            >{job?.status}</span>
        </div>

        {job?.paid_at ? 
        <DashboardViewItemRow
          columns={['Customer']}
          columnsData={[
            job?.customer?.first_name + ' ' + job?.customer?.last_name
            ]}
            alternateRow={true}
        />
        : '' }

        <DashboardViewItemRow
          columns={['Job Description']}
          columnsData={[job?.description]} 
          alternateRow={false}
        />

        <DashboardViewItemRow
          columns={['Weight', 'Size']}
          columnsData={[job?.weight, job?.size]} 
          alternateRow={true}
        />

        <DashboardViewItemRow
          columns={['Forklift', 'PO Number']}
          columnsData={[
            job?.forklift_required ? 'Yes' : 'No',             
            job?.po_number]}
          alternateRow={false}
        />

        <DashboardViewItemRow
          columns={['Deliver By', 'Flexible On Delivery Time']}
          columnsData={[
            moment.utc(job?.deliver_by_datetime, 'YYYY-MM-DD HH:mm:ss').local().format("Do MMM Y HH:mmA"),
            !job?.deliver_by_flexible ? "No" : job?.deliver_by_flexible + " day/s" 
          ]}
          alternateRow={false}
        />

        <div className="job--view-address">

        <div className="job--view-address-box-title">Delivery Details</div>
        <div className="job--view-address-box">

            <div className="row" style={{padding: 0}}>
                <div style={{width: '25px', float: 'left', height: '100%'}}>
                    <img src={IconAddress} alt="" style={{height: '16px', width: '16px'}} />
                </div>
                <div className="col">
                    <div className="job--view-delivery-address-label">
                        Collection address
                    </div>
                    <div className="job--view-delivery-address-value">
                        {job?.collection_address}
                    </div>
                    <div className="job--view-delivery-note">
                        {job?.collection_delivery_note}                        
                    </div>
                </div>
            </div>

            <div>
                <hr className="job--view-address-hr" />
            </div>

            <div className="row" style={{padding: 0}}>
                <div style={{width: '25px', float: 'left', height: '100%'}}>
                    <img src={IconAddress} alt="" style={{height: '16px', width: '16px'}} />
                </div>
                <div className="col">
                    <div className="job--view-delivery-address-label">
                        Delivery address
                    </div>
                    <div className="job--view-delivery-address-value">
                        {job?.deliver_address}
                    </div>
                </div>
            </div>

</div>            
            
        </div>

  </div>
  <div className="col-md-4">

        <div className="job--view-timeline-title">
          Bids
        </div>

        <div className="row company--view-job__bid-row">
            <div className="col-6 dashboard-table--body-column">
                Highest Bid
            </div>
            <div className="col-6 dashboard-table--body-column">
                &euro;{job?.bid_highest}
            </div>
        </div>
        <div className="row company--view-job__bid-row">
            <div className="col-6 dashboard-table--body-column">
                Lowest Bid
            </div>
            <div className="col-6 dashboard-table--body-column">
                &euro;{job?.bid_lowest}
            </div>
        </div>

    <div className="job--view-timeline-title">
      Timeline
    </div>

    <div className="job--view-timeline-list">
    {job?.log?.map((log, index) => {
      return <React.Fragment key={index}>
      <div className="job--view-timeline-list-header">
        {log?.action.charAt(0).toUpperCase() + log?.action.slice(1)}
      </div>
      <div className="job--view-timeline-list-value">
        {moment.utc(log?.created_at, 'YYYY-MM-DD HH:mm:ss').local()
                          .format("Do MMM Y HH:mmA")}
      </div>
</React.Fragment>      
    })}
    </div>

  </div>
</div>

      </CompanyLayout>
    </>
}

export default CompanyJobView;
