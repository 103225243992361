import LogoMain from "../assets/imgs/logo-main.png"

import IconCustomers from "../assets/icons/icon-customers.png"
import IconCompanies from "../assets/icons/icon-companies.png"
import IconJobs from "../assets/icons/icon-jobs.png"

import IconCustomersSelected from "../assets/icons/icon-customers-selected.png"
import IconCompaniesSelected from "../assets/icons/icon-companies-selected.png"
import IconJobsSelected from "../assets/icons/icon-jobs-selected.png"

import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../components/Dashboard/Header'
import { getRole } from "../services/token"

import "./css/Sidebar.css"

export default function AdminLayout({ children, breadcrumbs }) {

    const navigate = useNavigate()    

    function handleCustomersClick() {
        navigate('/admin/customers')
    }
    function handleCompaniesClick() {
        navigate('/admin/companies')
    }
    function handleJobsClick() {
        navigate('/admin/jobs')
    }

    var customersSelected = false;
    var companiesSelected = false;
    var jobsSelected = false;
    if (window.location.pathname.indexOf("admin/customers") !== -1) {
        customersSelected = true
    }
    else if (window.location.pathname.indexOf("admin/companies") !== -1) {
        companiesSelected = true
    }
    else if (window.location.pathname.indexOf("admin/jobs") !== -1) {
        jobsSelected = true
    }    

    // get role
    if (getRole() !== "admin") {
        navigate('/admin')
    }

    return (
    <>
<div className="container-fluid website__background">
    <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 sidebar__container fixed-top">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <div className="sidebar__logo-container">
                    <img src={LogoMain} style={{width: '95px'}} 
                        className="sidebar__logo" alt="" />
                </div>
                <hr className="sidebar__hr"/>

                <ul className={customersSelected ? "nav nav-pills flex-column mb-0 align-items-center align-items-sm-start nav-pills-selected"
                        : "nav nav-pills flex-column mb-0 align-items-center align-items-sm-start"}
                    id="menu"
                    onClick={() => handleCustomersClick()}
                    style={{width:'100%', cursor: 'pointer'}}>
                    <li className="nav-item">
                        <img className="sidebar__offers-icon" 
                            src={customersSelected ? IconCustomersSelected : IconCustomers}
                            alt="" style={{width: '24px', height: '24px'}}/>
                        <span 
                        className={customersSelected ? 'ms-1 d-none d-sm-inline sidebar__link sidebar__link-selected' 
                        : 'ms-1 d-none d-sm-inline sidebar__link' }
                        >Customers</span>                  
                    </li>
                </ul>
                <p />

                <ul className={companiesSelected ? "nav nav-pills flex-column mb-0 align-items-center align-items-sm-start nav-pills-selected"
                        : "nav nav-pills flex-column mb-0 align-items-center align-items-sm-start"}
                    id="menu"
                    onClick={() => handleCompaniesClick()}
                    style={{width:'100%', cursor: 'pointer'}}>
                    <li className="nav-item">
                        <img className="sidebar__offers-icon" 
                        src={companiesSelected ? IconCompaniesSelected : IconCompanies}
                        alt="" style={{width: '22px', height: '16px'}}/>
                        <span 
                        className={companiesSelected ? 'ms-1 d-none d-sm-inline sidebar__link sidebar__link-selected' 
                        : 'ms-1 d-none d-sm-inline sidebar__link' }
                            >Companies</span>                        
                    </li>
                </ul>
                <p />

                <ul className={jobsSelected ? "nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-pills-selected"
                        : "nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"}
                    id="menu"
                    style={{width:'100%', cursor: 'pointer'}}
                    onClick={() => handleJobsClick()}
                    >
                    <li className="nav-item" >
                        <img className="sidebar__offers-icon" 
                        src={jobsSelected ? IconJobsSelected : IconJobs}
                        alt="" style={{width: '18px', height: '18px'}}/>
                        <span 
                        className={jobsSelected ? "ms-1 d-none d-sm-inline sidebar__link sidebar__link-selected" 
                                            : "ms-1 d-none d-sm-inline sidebar__link"  }
                            >Jobs</span>                        
                    </li>
                </ul>

                <div className="pb-4">
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item sidebar__copyright">    
                        &copy;2024 Smart Haul
                    </li>
                </ul>
                </div>
            </div>
        </div>

        <div className="col-md-9 col-xl-10 offset-md-3 offset-xl-2 px-4 py-3" style={{paddingLeft: '0px', paddingRight: '0px'}}>

            <DashboardHeader breadcrumbs={breadcrumbs}/>

            <div style={{paddingLeft: '35px', paddingRight: '35px'}}>
                {children}
            </div>

        </div>
    </div>
</div>
    </>
  )
}