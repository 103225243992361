import IconArrowUpDark from "../../assets/icons/icon-arrow-up-dark.png"
import IconArrowDownDark from "../../assets/icons/icon-arrow-down-dark.png"

export default function DashboardAddItemHeader(props) {

    return (
<>
<div className={props?.active ? 'add-item__row-header add-item__row-header-active' 
            : 'add-item__row-header add-item__row-header-inactive'}
            onClick={props?.openClose}
    >

    <div className={props?.active ? 'add-item__row-header-number add-item__row-header-number-active' 
            : 'add-item__row-header-number add-item__row-header-number-inactive'}>
        {props?.number}</div>
    {props?.title}

    <div style={{width: '100%'}}>
        <img src={!props?.active ? IconArrowDownDark : IconArrowUpDark} 
            alt="arrow" className="add-item__row-header-arrow"/>
    </div>

</div>
</>
    );
    
}