export default function FormFieldSet(props) {

    var columnClass = "col-md-6";
    if (props?.fields.length === 1) {
        columnClass = "col-md-12";
    }

    return (
        <>
            <div className="row edit--form_row">
                {props?.fields?.map((field, index) => {

                    var fieldName = props?.fieldsData[index];
                    var fieldValue = props.model[fieldName];
                    var fieldType = "text";

                    if (fieldName.includes("password")) {
                        fieldType = "password";
                    }
                    else if (fieldName === "email" || fieldName === "email_confirmation") {
                        fieldType = "email";
                    }
                    else if (fieldName === "description") {
                        fieldType = "textarea";
                    }
                    else if (props.fieldsLookup && (fieldName in props.fieldsLookup)) {
                        fieldType = "select"
                    }

                    var fieldHtml = ""
                    if (fieldType === "select") {
                        fieldHtml =
                            <select className="form-control signup--form_group-control signup_input"
                                onChange={props?.fieldsOnChange[index]}
                                id={fieldName}
                                name={fieldName}
                                value={fieldValue}
                                key={`${fieldName}-${index}`} // Unique key prop
                            >
                                <option value="">-- Select {field} --</option>
                                {props.fieldsLookup[fieldName].map((selectOption, optionIndex) => {
                                    return <option key={`${fieldName}-option-${optionIndex}`} value={selectOption}>{selectOption}</option>
                                })}
                            </select>
                    }

                    else if (fieldType === "textarea") {
                        fieldHtml =
                            <textarea placeholder=" "
                                className="form-control signup--form_group-control signup_input"
                                style={{ height: '120px' }}
                                onChange={props?.fieldsOnChange[index]}
                                id={fieldName}
                                name={fieldName}
                                defaultValue={fieldValue}
                                key={`${fieldName}-${index}`} // Unique key prop
                            ></textarea>
                    }

                    else {
                        if (fieldName) {
                            fieldHtml =
                                <input className="form-control signup--form_group-control signup_input"
                                    value={fieldValue}
                                    type={fieldType}
                                    onChange={props?.fieldsOnChange[index]}
                                    id={fieldName}
                                    name={fieldName}
                                    placeholder=" "
                                    key={`${fieldName}-${index}`} // Unique key prop
                                    autoComplete={`${fieldName}-${index}`}
                                />
                        }
                    }

                    return (
                        <div key={`${fieldName}-${index}`} className={columnClass}>
                            <div className="form-group edit--form_group">
                                <div className="form-group">
                                    <div className="signup_input-wrapper">
                                        {fieldHtml}
                                        <label className="signup--form_group-label" htmlFor={fieldName}>{field}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    )
}
