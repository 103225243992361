import axios from 'axios';
import { getToken } from './token';

export const getApiUrl = () => {

    var urlApiDomain = "";

    switch (window.location.origin) {
        case "http://localhost:3000":
            urlApiDomain = "http://127.0.0.1:8000"
            break;
        case "http://127.0.0.1:3000":
            urlApiDomain = "http://127.0.0.1:8000"
            break;    
        case "https://dev.smarthaul.ie":
            urlApiDomain = "https://dev-api.smarthaul.ie"
            break;
        case "https://staging.smarthaul.ie":
            urlApiDomain = "https://staging-api.smarthaul.ie"
            break;
        case "https://smarthaul.ie":
            urlApiDomain = "https://api.smarthaul.ie"
            break;
        default:
    }

    return urlApiDomain;
}

export const makeRequest = async (url, data = null, method = 'GET', auth = true, asForm = false) => {
    let headers;    
    var urlApiDomain = getApiUrl() + "/api/";
    var debug = false;

    if (asForm) {
        headers = {};
    } else {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }
    if (auth) {
        headers.Authorization = `Bearer ${getToken()}`;
    }

    if (debug) {
        console.log("HEADERS:: ")
        console.log(headers)    
        console.log("METHOD:: " + method)
        console.log("ASFORM:: " + asForm)
        console.log("DATA (first):: " + data)
        console.log("DATA (first string):: " + JSON.stringify(data))    
    }
    
    const params = {
        method: method,
        headers,
    };
    if (method === 'POST' || method === 'PUT') {
        if (asForm) {
            const formData = new FormData();
            for (const key in data) {
                if (Array.isArray(data[key])) {
                    data[key].map((arrayItem) => {
                        formData.append(key + "[]", arrayItem);
                        return null
                    })
                }
                else {
                    formData.append(key, data[key]);
                }                
            }
            if (method === "PUT") {
                formData.append('_method', 'PUT')
                params.method = 'POST';
            }
            params.data = formData;
        } else {
            params.data = JSON.stringify(data);
        }
    }

    params.url = urlApiDomain + url;
    if (debug) {
        console.log("URL:: " + params.url)
        console.log("DATA (string):: " + JSON.stringify(params.data))
        console.log("DATA:: " + params.data)
    }

    try {
        const resp = await axios.request(params);
        if (debug) {
            console.log(resp);
        }
        return resp.data
    } catch (err) {
        if (debug) {
            console.log(err);
        }
        return err.response.data;
    }
};

export const logoutAdmin = async () => {
    return await makeRequest('admin/auth/sign-out', null, 'POST');
}

// ADMIN - CUSTOMERS
export const adminListCustomers = async (page) => {
    return await makeRequest(`admin/customers?page=${page}`, null, 'GET', true);
}
export const adminGetCustomer = async (id) => {
    return await makeRequest(`admin/customers/${id}`, null, 'GET', true);
}
export const adminUpdateCustomer = async (id, data) => {
    return await makeRequest(`admin/customers/${id}`, data, 'POST', true, true);
}
export const adminDeleteCustomer = async (id, data) => {
    return await makeRequest(`admin/customers/${id}`, data, 'DELETE', true, true);
}
export const adminResetCustomerPassword = async (id, data) => {
    return await makeRequest(`admin/customers/${id}/reset`, data, 'POST', true, true);
}

// ADMIN - COMPANIES
export const adminListCompanies = async (page) => {
    return await makeRequest(`admin/companies?page=${page}`, null, 'GET', true);
}
export const adminGetCompany = async (id) => {
    return await makeRequest(`admin/companies/${id}`, null, 'GET', true);
}
export const adminUpdateCompany = async (id, data) => {
    return await makeRequest(`admin/companies/${id}`, data, 'POST', true, true);
}
export const adminDeleteCompany = async (id, data) => {
    return await makeRequest(`admin/companies/${id}`, data, 'DELETE', true, true);
}
export const adminApproveCompany = async (id, data) => {
    return await makeRequest(`admin/companies/${id}/approve`, null, 'POST', true, true);
}
export const adminViewCompanyDocument = async (companyId, documentId, data) => {
    return await makeRequest(`admin/companies/${companyId}/documents/${documentId}`, null, 'GET', true, false, false);
}
export const adminResetCompanyPassword = async (id, data) => {
    return await makeRequest(`admin/companies/${id}/reset`, data, 'POST', true, true);
}

// ADMIN - JOBS
export const adminListJobs = async (page, showDeleted) => {
    return await makeRequest(`admin/jobs?page=${page}&deleted=${showDeleted}`, null, 'GET', true);
}
export const adminGetJob = async (id) => {
    return await makeRequest(`admin/jobs/${id}`, null, 'GET', true);
}
export const adminUpdateJob = async (id, data) => {
    return await makeRequest(`admin/jobs/${id}`, data, 'POST', true, true);
}
export const adminDeleteJob = async (id, data) => {
    return await makeRequest(`admin/jobs/${id}`, data, 'DELETE', true, true);
}

// CUSTOMER
export const customerSignUp = async (data) => {
    return await makeRequest(`auth/customer/sign-up`, data, 'POST', false);
}
export const customerListJobs = async (page, showDelivered) => {
    if (showDelivered) {
        return await makeRequest(`customer/jobs?page=${page}&status=delivered`, null, 'GET', true);
    } else {
        return await makeRequest(`customer/jobs?page=${page}`, null, 'GET', true);
    }
}
export const customerGetJob = async (id) => {
    return await makeRequest(`customer/jobs/${id}`, null, 'GET', true);
}
export const customerAddJob = async (data) => {
    return await makeRequest(`customer/job`, data, 'POST', true, true);
}
export const customerUpdateJob = async (id, data) => {
    return await makeRequest(`customer/jobs/${id}`, data, 'POST', true, true);
}
export const customerDeleteJob = async (id) => {
    return await makeRequest(`customer/jobs/${id}`, null, 'DELETE', true, true);
}
export const customerAcceptBid = async (jobId, bidId) => {
    return await makeRequest(`customer/jobs/${jobId}/bids/${bidId}/accept`, null, 'POST', true, true);
}
export const customerGetCheckoutUrl = async (id) => {
    return await makeRequest(`customer/jobs/${id}/payment/url`, null, 'GET', true, true);
}
export const customerGetProfile = async () => {
    return await makeRequest(`customer/profile`, null, 'GET', true, true);
}
export const customerUpdateProfile = async (data) => {
    return await makeRequest(`customer/profile`, data, 'POST', true, true);
}

// COMPANY
export const companySignUp = async (data) => {
    return await makeRequest(`auth/company/sign-up`, data, 'POST', false, true);
}
export const companyListJobs = async (page, collectionCounty, deliveryCounty, startDate, endDate) => {
    return await makeRequest(`company/jobs?page=${page}&collection_county=${collectionCounty}&deliver_county=${deliveryCounty}&deliver_by_datetime_start=${startDate}&deliver_by_datetime_end=${endDate}`, null, 'GET', true);
}
export const companyListJobsBidOn = async (page, won, delivered) => {
    return await makeRequest(`company/jobs/own?won=${won}&delivered=${delivered}&page=${page}`, null, 'GET', true);
}
export const companyGetJob = async (id) => {
    return await makeRequest(`company/jobs/${id}`, null, 'GET', true);
}
export const companyBidOnJob = async (id, data) => {
    return await makeRequest(`company/jobs/${id}/bid`, data, 'POST', true, true);
}
export const companyUpdateBidOnJob = async (id, data) => {
    return await makeRequest(`company/jobs/${id}/bid`, data, 'PUT', true, true);
}
export const companyConfirmBidOnJob = async (id, bidId) => {
    return await makeRequest(`company/jobs/${id}/bids/${bidId}/confirm`, null, 'POST', true, true);
}
export const companyListDrivers = async (id, bidId) => {
    return await makeRequest(`company/drivers`, null, 'GET', true, true);
}
export const companyAddDriver = async (data) => {
    return await makeRequest(`company/drivers`, data, 'POST', true, true);
}
export const companyUpdateDriver = async (id, data) => {
    return await makeRequest(`company/drivers/${id}`, data, 'PUT', true, true);
}
export const companyEnableDriver = async (id) => {
    return await makeRequest(`company/drivers/${id}/enable`, null, 'POST', true, true);
}
export const companyDisableDriver = async (id) => {
    return await makeRequest(`company/drivers/${id}/disable`, null, 'POST', true, true);
}
export const companyDeleteDriver = async (id) => {
    return await makeRequest(`company/drivers/${id}`, null, 'DELETE', true, true);
}
export const companyAssignDriver = async (id, data) => {
    return await makeRequest(`company/jobs/${id}/driver`, data, 'POST', true, true);
}
export const companyGetProfile = async () => {
    return await makeRequest(`company/profile`, null, 'GET', true, true);
}
export const companyUpdateProfile = async (data) => {
    return await makeRequest(`company/profile`, data, 'POST', true, true);
}
export const companyUpdateDocumentTitle = async (id, data) => {
    return await makeRequest(`company/documents/${id}/title`, data, 'POST', true, true);
}
export const companyDeleteDocument = async (id, data) => {
    return await makeRequest(`company/documents/${id}`, data, 'DELETE', true, true);
}
export const companyAddDocument = async (data) => {
    return await makeRequest(`company/documents/`, data, 'POST', true, true);
}
export const companyResetDriverPassword = async (id) => {
    return await makeRequest(`company/drivers/${id}/reset`, null, 'POST', true, true);
}


// DRIVER
export const driverSetPassword = async (data) => {
    return await makeRequest(`auth/set-password`, data, 'POST', false, true);
}
export const driverCheckSecret = async (data) => {
    return await makeRequest(`auth/set-password/verifysecret`, data, 'POST', false, true);
}
export const driverDeletion = async (data) => {
    return await makeRequest(`driver/deletion`, data, 'POST', true, true);
}

// AUTH
export const signin = async (data) => {
    return await makeRequest(`auth/sign-in`, data, 'POST', false);
}
export const signout = async () => {
    return await makeRequest('auth/sign-out', null, 'POST', true);
}
export const forgotPassword = async (data) => {
    return await makeRequest(`auth/forgot-password`, data, 'POST', false);
}
export const forgotPasswordCheckCode = async (data) => {
    return await makeRequest(`auth/forgot-password/code`, data, 'POST', false);
}
export const forgotPasswordSave = async (data) => {
    return await makeRequest(`auth/set-password`, data, 'POST', false);
}
export const changePassword = async (data) => {
    return await makeRequest(`auth/password`, data, 'POST', true, true);
}
export const changeEmail = async (data) => {
    return await makeRequest(`auth/email`, data, 'POST', true, true);
}
export const changeEmailVerify = async (data) => {
    return await makeRequest(`auth/email/verify`, data, 'POST', true, true);
}
export const checkLogin = async (data) => {
    return await makeRequest(`auth/check-login`, data, 'GET', true);
}

export const getOffersList = async (page) => {
    return await makeRequest(`admin/offer?page=${page}`, null, 'GET', true);
}
export const addOffer = async (data) => {
    return await makeRequest(`admin/offer`, data, 'POST', true, true);
}
export const updateOffer = async (id, data) => {
    return await makeRequest(`admin/offer/${id}`, data, 'POST', true, true);
}
export const getOffer = async (id) => {
    return await makeRequest(`admin/offer/${id}`, null, 'GET', true);
}
export const deleteOffer = async (id) => {
    return await makeRequest(`admin/offer/${id}`, null, 'DELETE', true);
}
export const getUsersList = async (page, keyword) => {
    return await makeRequest(`admin/users?page=${page}&keyword=${keyword}`, null, 'GET', true);
}
export const deleteUser = async (id) => {
    return await makeRequest(`admin/user/${id}`, null, 'DELETE', true);
}
