import React, { useState, useEffect } from "react";
import { driverDeletion } from "../../services/requests"
import { getErrorMessage } from "../../services/utils";
import LogoMain from "../../assets/imgs/logo-main-dark.png"
import { signin } from '../../services/requests';
import { setToken, getRole } from '../../services/token';

const DriverPassword = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputs, setInputs] = useState({ email: "", password: "" });
    const [authorised, setAuthorised] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const inputChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    }

    const handleConfirmDeletion = async (e) => {
        e.preventDefault();
        setErrorMessage(null);
        setIsLoading(true);
        try {
            const resp = await driverDeletion();
            if (resp?.result === 'success') {
                setConfirmed(true);
                setAuthorised(false);
            } else if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
        } catch (error) {
            setErrorMessage("An unexpected error occurred.");
        }
        setIsLoading(false);
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setErrorMessage(null);
        const { email, password } = inputs;

        setIsLoading(true);
        try {
            const resp = await signin({ email, password });
            if (resp.token) {
                setToken(resp?.token, resp?.role, resp?.email, resp?.name, resp?.role_id)
                if (getRole() !== "driver") {
                    setErrorMessage("This is not a driver account.");
                } 
                else {
                    setAuthorised(true);
                }
            } else if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
        } catch (error) {
            console.error("Login Error:", error);
            setErrorMessage("An unexpected error occurred.");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.title = 'Smart Haul - Delete Account';

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
            //   handleSaveClick();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };        

        // eslint-disable-next-line
    }, []);

    return <>
<div className="login__background">

<div className="div-center">

  <div style={{textAlign:'center'}}>

    <img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/>

    {errorMessage ?
    <div dangerouslySetInnerHTML={{ __html: errorMessage }} className="alert alert-danger" role="alert"></div>
    : null}
    
    <h1 className="login__header">Delete Account</h1>

    {authorised ?
<>
    <div className="login__header-info-text">
        Are you sure you want to delete your driver account?
    </div>
    <button
        onClick={handleConfirmDeletion}
        type="button"
        className="btn btn-primary login__button"
        disabled={isLoading}>
        Delete Account
    </button>
</>
    : confirmed ?
<>
    <div className="login__header-info-text">
        Thank you. The request to delete your driver account has now been submitted.
    </div>
</>
    :
<>
    <div className="login__header-info-text">
        Please login below if you would like to delete your driver account.
    </div>
    <form onSubmit={handleFormSubmit}>
        <div className="form-group">
            <div className="login_input-wrapper">
                <input
                    onChange={inputChange}
                    value={inputs.email}
                    type="email"
                    className="form-control login_input"
                    id="email"
                    name="email"
                    placeholder=" " />
                <label htmlFor="email">Email Address</label>
            </div>
        </div>

        <div className="form-group">
            <div className="login_input-wrapper">
                <input
                    onChange={inputChange}
                    value={inputs.password}
                    type='password'
                    className="form-control login_input"
                    name="password"
                    id="password"
                    placeholder=" " />
                <label htmlFor="password">Password</label>
            </div>
        </div>

        <button
            type="submit"
            className="btn btn-primary login__button"
            disabled={isLoading}
        >Login</button>
    </form>        
    </>}

  </div>
</div>
</div>
    </>
    
}

export default DriverPassword;
