import React, { useState, useEffect } from "react";
import '../../Auth/Login.css';
import {
    changeEmail
} from "../../../services/requests"
import CompanyLayout from "../../../layouts/CompanyLayout";
import "./Profile.css"
import CompanySideProfileMenu from "../../../components/Dashboard/Companies/SideProfileMenu";
import FormFieldSet from "../../../components/Dashboard/FormFieldSet";
import { getErrorMessage } from "../../../services/utils";
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const CompanyEmail = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);    
    const [successMessage, setSuccessMessage] = useState(false);   
    const navigate = useNavigate()     
    const [searchParams] = useSearchParams();

    const [company, setCompany] = useState({
        password: "",
        password_confirmation: "",
    });

    const formValueChanged = async (fieldValue) => {
        var myValue = fieldValue['field']
        setCompany({ ...company, [myValue]: fieldValue['value'] })
    }

    const handleSaveClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)
        setSuccessMessage(null)

        var body = {
            email: company?.email,
            email_confirmation: company?.email_confirmation,
        };
    
        try {
            const resp = await changeEmail({ ...body });
            setIsLoading(false)
            if (resp?.result === 'success') {
                navigate("/company/email/verify")
                // setSuccessMessage("We've sent an email to you now with a verification code to verify your new address.")
            }
            else {             
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp))
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage("There was an error changing your password.")
        }
    }    

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true)        
            // const resp = await companyGetProfile();
            // setCompany(resp?.company);
            setIsLoading(false)
        }
        loadData()

        if (searchParams.get('changed')) {
            setSuccessMessage("Your email has now been changed successfully.")
        }

        document.title = 'Smart Haul - Company - Email';
        // eslint-disable-next-line
    }, []);    

    return (
        <CompanyLayout>
            <div className="row">
                <div className="col-3">
                    <CompanySideProfileMenu selected="email" />
                </div>
                <div className="col-9">
                    {successMessage ? 
                        <div dangerouslySetInnerHTML={{__html: successMessage}} className="alert alert-success" role="alert"></div> 
                        : ''}
                    {errorMessage ? 
                        <div dangerouslySetInnerHTML={{__html: errorMessage}} className="alert alert-danger" role="alert"></div> 
                        : ''}
                    <h1 className="profile--form__header">Update email</h1>
                    <div className="profile--form__description">Modify your email for accurate communication and account security.</div>

                    <FormFieldSet 
                        showLabels={false}
                        model={company}
                        fields={['New Email']}
                        fieldsData={['email']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'email', value: event?.target?.value}),
                        ]}/>

                    <button 
                        type="button" 
                        className="btn btn-primary login__button"
                        onClick={() => handleSaveClick()}
                        disabled={isLoading}
                        style={{width: '200px'}}
                    >
                        Save
                    </button>
                </div>
            </div>
        </CompanyLayout>
    )
}

export default CompanyEmail;
