
import React, {useState, useRef, useEffect} from "react";
import { LOGOUT_USER } from "../../constants/types"
import { checkLogin, logoutAdmin, signout } from '../../services/requests';
import IconDown from "../../assets/icons/icon-down.png"
import IconAvatarAdmin from "../../assets/icons/icon-avatar-admin.png"
import IconLogout from "../../assets/icons/icon-logout.png"
import IconLogoutSelected from "../../assets/icons/icon-logout-selected.png"
import IconProfile from "../../assets/icons/icon-profile.png"
import IconProfileSelected from "../../assets/icons/icon-profile-selected.png"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRole, getEmail, getName, removeToken } from '../../services/token';

export default function DashboardProfile(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate()    

    const [dropDownState, setDropDownState] = useState(null)
    function userDropDownToggle() {
        var stateLocal = dropDownState;
        if (stateLocal === "true") { stateLocal = "false" }
        else { stateLocal = "true" }
        setDropDownState( stateLocal );
    }

    async function handleLogoutClick() {
        var role = getRole();
        if (role === "admin") {
            await logoutAdmin();
            dispatch({ type: LOGOUT_USER });
            navigate('/admin')    
        }
        else {
            await signout();
            removeToken();
            navigate('/login')
        }
    }

    const handleProfileClick = () => {
        if (getRole() === "customer") {
            navigate("/customer/profile")
        }
        else if (getRole() === "company") {
            navigate("/company/profile")
        }
    }

    const handleHoverIcon = (event) => {
        if (event === "profile") {
            iconProfileRef.current.src = IconProfileSelected
        }
        else if (event === "logout") {
            iconLogoutRef.current.src = IconLogoutSelected
        }
        else {
            iconLogoutRef.current.src = IconLogout

            if (getRole() !== "admin") {
                iconProfileRef.current.src = IconProfile
            }
        }
    }

    useEffect(() => {   
        const interval = setInterval(() => {
            checkLogin().then((resp) => {                
                if (resp.result !== 'success') {
                    var role = getRole();
                    if (role === "admin") {
                        logoutAdmin();
                        dispatch({ type: LOGOUT_USER });    
                        navigate('/admin')
                    }
                    else {
                        signout();
                        removeToken();    
                        navigate('/login')
                    }
                }
                
                // make sure URL is correct
                else {

                    // if role is customer and URL is company, redirect to customer
                    if (getRole() === "customer" && window.location.pathname.startsWith("/company")) {
                        navigate("/customer/jobs");
                    }

                    // and if role is company and URL is not company, redirect to company
                    else if (getRole() === "company" && window.location.pathname.startsWith("/customer")) {
                        navigate("/company/jobs");
                    }

                    // and if role is admin and URL is not admin, redirect to admin
                    else if (getRole() === "admin" && !window.location.pathname.startsWith("/admin")) {
                        navigate("/admin/customers");
                    }
                }
            })
        }, 5000);

        return () => clearInterval(interval);

        // eslint-disable-next-line
    }, [])

    var menuClass = `dropdown-menu${dropDownState==="true" ? " show" : ""}`;
    var iconProfileRef = useRef(null);
    var iconLogoutRef = useRef(null);    

    return (
        <div className="col-md d-flex justify-content-end header--user" onClick={() => userDropDownToggle()}>
            <div className="row">
                <div style={{width: '60px'}} id="divAvatar">
                    <img className="header--user-avatar" src={IconAvatarAdmin} alt="avatar" />
                </div>
                <div style={{width: 'auto'}} id="divName">
                    <div className="header--user-name">{getName()}</div>
                    <div className="header--user-email">{getEmail()}</div>
                </div>
                <div style={{width: '50px'}} id="divDown">
                    <img className="header--user-down" src={IconDown} alt="down" />
                </div>
            </div>

            <div className="dropdown">
                <div className={menuClass} aria-labelledby="dropdownMenuButton">
                    <span className="dropdown-menu-arrow"></span>

                    {getRole() !== "admin" ?
                    <>
                    <button className="dropdown-item" href="#/" style={{marginTop: '1px'}} 
                        onClick={() => handleProfileClick()}
                        onMouseEnter={() => handleHoverIcon('profile')}
                        onMouseLeave={() => handleHoverIcon('')}
                        > 
                        <img ref={iconProfileRef} src={IconProfile} alt="" 
                            style={{height: '24px', width: '24px', marginRight: '10px'}} />
                        Profile
                    </button>
                    </> : ''}

                    <button className="dropdown-item" href="#/" style={{marginTop: '1px'}} 
                        onClick={() => handleLogoutClick()}
                        onMouseEnter={() => handleHoverIcon('logout')}
                        onMouseLeave={() => handleHoverIcon('')}
                        > 
                        <img ref={iconLogoutRef} src={IconLogout} alt="" 
                            style={{height: '24px', width: '24px', marginRight: '10px'}} />
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );

}