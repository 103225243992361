
import IconBackArrow from "../../assets/icons/icon-back-arrow.png"
import IconEdit from "../../assets/icons/icon-edit.png"
import IconEditActive from "../../assets/icons/icon-edit-active.png"
import IconDelete from "../../assets/icons/icon-delete.png"
import IconDeleteActive from "../../assets/icons/icon-delete-active.png"
import IconTick from "../../assets/icons/icon-tick.png"

import { useNavigate } from 'react-router-dom';

export default function DashboardItemHeader(props) {

    const navigate = useNavigate()
    function navigateBack() {
        if (props?.navigateBackUrl) {
            navigate(props?.navigateBackUrl)
        }
        else {
            navigate(-1)
        }
    }

    return (<>
<div className="container" style={{marginTop: '10px'}}>
    <div className="row" style={{marginRight: '-25px'}}>
        <div className="col-md-6">
            <div className="dashboard--view-item-header" onClick={() => navigateBack()}>
                <img className="dashboard--view-item-header-backicon" src={IconBackArrow} alt="" />
                Back
            </div>
        </div>
        <div className="col-md-6 text-end">
        
            <div style={{display: "flex",
                        alignItems: "center",
                        justifyContent: 'flex-end'}}>

            {props?.otherHtml ? 
            <><div style={{display: "flex",
                        alignItems: "center",
                        justifyContent: 'flex-end',
                }}            
                dangerouslySetInnerHTML={{__html: props?.otherHtml}}>        
            </div></> : ''}

            {props.approveShowButton || props.otherAction || props.editAction || props.deleteAction ?
            <div>&nbsp;&nbsp;</div> : '' }

            {props.approveShowButton ? 
                <button className="btn btn-table-action"
                    onClick={() => props.approveAction()}
                >Approve</button>
                :
                props.approveShowStatus ? 
                <span className="dashboard--view-item-header-approved">
                    <img src={IconTick} style={{width: '13.5px', height: '10px'}} alt="" />
                    Approved
                </span>
                : ''
            }

            {props.otherAction ? <>
                <button className="btn btn-table-action" onClick={() => props.otherAction()}
                >{props?.otherActionText}</button>
            </>
            : ''}

            {props.editAction ? <>
            <img className="dashboard-table--action-icon"                     
                src={IconEdit} 
                onMouseOver={e => (e.currentTarget.src = IconEditActive)}
                onMouseOut={e => (e.currentTarget.src = IconEdit)}
                onClick={() => props.editAction()}
                alt="edit" 
                />
            </> : ''}

            {props.deleteAction ? <>
            <img className="dashboard-table--action-icon" 
                src={IconDelete} 
                onMouseOver={e => (e.currentTarget.src = IconDeleteActive)}
                onMouseOut={e => (e.currentTarget.src = IconDelete)}
                onClick={() => props.deleteAction()}
                alt="delete" />
            </> : ''}

            </div>

        </div>
    </div>
</div>
    </>);

}
