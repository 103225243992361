import React, {useState, useEffect} from "react";
import './Login.css';
import {useNavigate} from "react-router-dom";
import {forgotPassword, forgotPasswordCheckCode} from "../../services/requests"
import {getErrorMessage} from "../../services/utils";
import LogoMain from "../../assets/imgs/logo-main-dark.png"
import IconArrowLeftCircle from "../../assets/icons/icon-arrow-left-circle.png"
import { useRef } from "react";
import { useSearchParams } from 'react-router-dom';

const ForgotCode = () => {

    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [inputs, setInput] = useState({code_1: "", code_2: "", code_3: "", code_4: ""});
    const [isFormComplete, setIsFormComplete] = useState(false);

    const code1Ref = useRef();
    const code2Ref = useRef();
    const code3Ref = useRef();
    const code4Ref = useRef();

    const inputChange = (e) => {
        setInput({...inputs, [e.target.id]: e.target.value});
        if (e.target.id === "code_1") { code2Ref.current.select(); }
        else if (e.target.id === "code_2") { code3Ref.current.select(); }
        else if (e.target.id === "code_3") { code4Ref.current.select(); }
        else if (e.target.id === "code_4") {
            setIsFormComplete(true)
        }
        else {
            setIsFormComplete(false)
        }
    }

    const handleResendCodeClick = async (event) => {

        var body = { email: searchParams.get('email') }
        if (!isLoading) {
            setIsLoading(true);
            const resp = await forgotPassword({ ...body });
            if (resp?.result === "success") {
                setSuccessMessage("A new code has now been sent to your email address.")
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    const handleSendCodeClick = async (event) => {
        var code = code1Ref.current.value + code2Ref.current.value 
                    + code3Ref.current.value + code4Ref.current.value;
        var email = decodeURIComponent(searchParams.get('email'))
        var body = {
            email: email,
            code: code,
        }
        if (!isLoading) {
            setIsLoading(true);
            const resp = await forgotPasswordCheckCode({ ...body });
            if (resp?.result === "success") {
                email = encodeURIComponent(email);        
                navigation('/forgot/password?email=' + email + "&code=" + code);
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        document.title = 'Smart Haul - Enter Code';

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
              handleSendCodeClick();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };        

        // eslint-disable-next-line
    }, []);

    const navigate = useNavigate()
    function navigateBack() {
        navigate(-1)
    }

    return <>
<div className="login__background">

<div className="div-center">

    <div style={{marginLeft: '0px'}}>
        <img src={IconArrowLeftCircle} style={{width: '42px', 
                                            height: '42px', 
                                            cursor:'pointer'}} 
                                            alt="back" 
            onClick={() => navigateBack()} />
    </div>

  <div style={{textAlign:'center'}}>

    <img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/>

    {successMessage ? 
    <><div dangerouslySetInnerHTML={{__html: successMessage}} 
        className="alert alert-success" role="alert">
    </div></> : ''}

    {errorMessage ? 
    <><div dangerouslySetInnerHTML={{__html: errorMessage}} 
        className="alert alert-danger" role="alert">        
    </div></> : ''}
    
    <h1 className="login__header">Enter reset code</h1>

    <div className="login__header-info-text">
    Enter the 4 digit code that you received in your email.
    </div>

        <div className="form-group">

            <div className="row">
                <div className="col-md-3">
                    <div className="login_input-wrapper">
                        <input onChange={inputChange} className="form-control login_input" id="code_1" 
                            ref={code1Ref} />
                    </div>  
                </div>
                <div className="col-md-3">
                    <div className="login_input-wrapper">
                        <input onChange={inputChange} className="form-control login_input" id="code_2"
                            ref={code2Ref} />
                    </div>  
                </div>
                <div className="col-md-3">
                    <div className="login_input-wrapper">
                        <input onChange={inputChange} className="form-control login_input" id="code_3" 
                            ref={code3Ref} />
                    </div>  
                </div>
                <div className="col-md-3">
                    <div className="login_input-wrapper">
                        <input onChange={inputChange} className="form-control login_input" id="code_4"
                            ref={code4Ref} />
                    </div>  
                </div>                
            </div>
        </div>

        <button type="button" className="btn btn-primary login__button"
            disabled={!isFormComplete || isLoading}
            onClick={() => handleSendCodeClick()}
        >Continue</button>
    
        <div className="login__footer-text"><a className="login__footer-link" onClick={() => handleResendCodeClick()}href="#/">Resend Code</a></div>

  </div>
</div>
</div>
    </>

    
}

export default ForgotCode;
