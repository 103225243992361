export default function DashboardItemRow(props) {

    return (<>
<div className="container">
    <div className=
        {props.alternateRow ?
            'row dashboard--item-row-alt'
            :
            'row dashboard--item-row'
        }
    >
        <div className="col-md-6">
            <div className="dashboard--item-row-label">{props.columns[0]}</div>
            <div className="dashboard--item-row-value">{props.columnsData[0]}</div>
        </div>
        <div className="col-md-6">
            <div className="dashboard--item-row-label">{props.columns[1]}</div>
            <div className="dashboard--item-row-value">{props.columnsData[1]}</div>
        </div>
    </div>
</div>

    </>);
}