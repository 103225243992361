export const setToken = (token, role = "", email = "", name = "", roleId = "") => {
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('email', email);
    localStorage.setItem('name', name);
    localStorage.setItem('role_id', roleId);
    localStorage.setItem('lastLoginTime', new Date(Date.now()).getTime());
};

export const setTokenOnly = (token) => {
    localStorage.setItem('token', token);
}

export const setEmail = (email) => {
    localStorage.setItem('email', email);
}

export const removeToken = () => {
    localStorage.setItem('token', "");
    localStorage.setItem('role', "");
    localStorage.setItem('email', "");
    localStorage.setItem('name', "");
    localStorage.setItem('role_id', "");
};

export const getToken = () => { return localStorage.getItem('token') }
export const getRole = () => { return localStorage.getItem('role') }
export const getRoleId = () => { return localStorage.getItem('role_id') }
export const getEmail = () => { return localStorage.getItem('email') }
export const getName = () => { return localStorage.getItem('name') }

export const getUserId = () => {
    return localStorage.getItem('userid')
}

export const setUserId = (userId) => {
    return localStorage.setItem('userid', userId)
}

export const setLocalJob = (job) => {
    localStorage.setItem('job', JSON.stringify(job));
}
export const getLocalJob = () => {
    return JSON.parse(localStorage.getItem('job'));
}
