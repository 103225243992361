import React, { useState, useEffect } from "react";
import '../../Auth/Login.css';
import {
    companyGetProfile, changePassword
} from "../../../services/requests"
import CompanyLayout from "../../../layouts/CompanyLayout";
import "./Profile.css"
import CompanySideProfileMenu from "../../../components/Dashboard/Companies/SideProfileMenu";
import FormFieldSet from "../../../components/Dashboard/FormFieldSet";
import { getErrorMessage } from "../../../services/utils";

const CompanyPassword = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);    
    const [successMessage, setSuccessMessage] = useState(false);    

    const [company, setCompany] = useState({
        password_existing: "",
        password: "",
        password_confirmation: "",
    });

    const formValueChanged = async (fieldValue) => {
        var myValue = fieldValue['field']
        setCompany({ ...company, [myValue]: fieldValue['value'] })
    }

    const handleSaveClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)
        setSuccessMessage(null)

        if (company?.password_existing === "" || company?.password === "" || company?.password_confirmation === ""
            || !company?.password_existing || !company?.password || !company?.password_confirmation) {
            setErrorMessage("Please make sure you enter all password fields.")
            setIsLoading(false)
            return
        }

        var body = {
            password_existing: company?.password_existing,
            password: company?.password,
            password_confirmation: company?.password_confirmation,    
        };
    
        try {
            const resp = await changePassword({ ...body });
            setIsLoading(false)
            if (resp?.result === 'success') {
                setSuccessMessage("Your password has been changed successfully.")
            }
            else {             
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp))
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage("There was an error changing your password.")
        }
    }    

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true)        
            const resp = await companyGetProfile();
            setCompany(resp?.company);
            setIsLoading(false)
        }
        loadData()
        document.title = 'Smart Haul - Company - Password';
        // eslint-disable-next-line
    }, []);    

    return (
        <CompanyLayout>
            <div className="row">
                <div className="col-3">
                    <CompanySideProfileMenu selected="password" />
                </div>
                <div className="col-9">
                    {successMessage ? 
                        <div dangerouslySetInnerHTML={{__html: successMessage}} className="alert alert-success" role="alert"></div> 
                        : ''}
                    {errorMessage ? 
                        <div dangerouslySetInnerHTML={{__html: errorMessage}} className="alert alert-danger" role="alert"></div> 
                        : ''}
                    <h1 className="profile--form__header">Update password</h1>
                    <div className="profile--form__description">Update password regularly for a safer online experience and better account protection</div>

                    <FormFieldSet 
                        showLabels={false}
                        model={company}
                        fields={['Current Password']}
                        fieldsData={['password_existing']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'password_existing', value: event?.target?.value}),
                        ]}/>

                    <FormFieldSet 
                        showLabels={false}
                        model={company}
                        fields={['New Password']}
                        fieldsData={['password']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'password', value: event?.target?.value}),
                        ]}/>

                    <FormFieldSet 
                        showLabels={false}
                        model={company}
                        fields={['Confirm New Password']}
                        fieldsData={['password_confirmation']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'password_confirmation', value: event?.target?.value}),
                        ]}/>

                    <button 
                        type="button" 
                        className="btn btn-primary login__button"
                        onClick={() => handleSaveClick()}
                        disabled={isLoading}
                        style={{width: '200px'}}
                    >
                        Save
                    </button>
                </div>
            </div>
        </CompanyLayout>
    )
}

export default CompanyPassword;
