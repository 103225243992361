import React, {useState, useEffect} from "react";
import '../../Auth/Login.css';
import {
    customerGetProfile, customerUpdateProfile
} from "../../../services/requests"
import CustomerLayout from "../../../layouts/CustomerLayout";
import "./Profile.css"
import CustomerSideProfileMenu from "../../../components/Dashboard/Customers/SideProfileMenu";
import FormFieldSet from "../../../components/Dashboard/FormFieldSet";
import { irishCounties } from "../../../constants/counties";
import { getErrorMessage } from "../../../services/utils";

const CustomerProfile = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);    
    const [successMessage, setSuccessMessage] = useState(false);    

    const [customer, setCustomer] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
        address1: "",
        address2: "",
        city: "",
        county: "",
        postcode: "",
        vat_number: "",
    });

    const formValueChanged = async (fieldValue) => {
        var myValue = fieldValue['field']
        setCustomer({ ...customer, [myValue]: fieldValue['value'] })
    }

    const handleSaveClick = async (event) => {

        setIsLoading(true)
        setErrorMessage(null)
        setSuccessMessage(null)

        var body = {
            first_name: customer?.first_name,
            last_name: customer?.last_name,
            phone: customer?.phone,
            password: customer?.password,
            password_confirmation: customer?.password_confirmation,
            address1: customer?.address1,
            address2: customer?.address2,
            city: customer?.city,
            county: customer?.county,
            postcode: customer?.postcode,
            vat_number: customer?.vat_number,
        };
    
        try {
            const resp = await customerUpdateProfile({ ...body });
            setIsLoading(false)
            if (resp?.result === 'success') {
                setSuccessMessage("Your profile has been updated successfully.")
            }
            else {             
                setIsLoading(false)
                setErrorMessage(getErrorMessage(resp))
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage("There was an error saving your profile.")
        }
    }    

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true)        
            const resp = await customerGetProfile();
            setCustomer(resp?.customer);
            setIsLoading(false)
        }
        loadData()
        document.title = 'Smart Haul - Customer - Profile';
        // eslint-disable-next-line
    }, []);    

    return (
        <CustomerLayout>
            <div className="row">
                <div className="col-3">
                    <CustomerSideProfileMenu selected="profile" />
                </div>
                <div className="col-9">
                    {successMessage ? 
                        <div dangerouslySetInnerHTML={{__html: successMessage}} className="alert alert-success" role="alert"></div> 
                        : ''}
                    {errorMessage ? 
                        <div dangerouslySetInnerHTML={{__html: errorMessage}} className="alert alert-danger" role="alert"></div> 
                        : ''}
                    <h1 className="profile--form__header">Edit profile</h1>
                    <div className="profile--form__description">Edit profile to reflect changes and keep your details accurate and up-to-date</div>

                    <FormFieldSet 
                        showLabels={false}
                        model={customer}
                        fields={['First Name', 'Last Name']}
                        fieldsData={['first_name', 'last_name']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'first_name', value: event?.target?.value}),
                            (event) => formValueChanged({field: 'last_name', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={customer}
                        fields={['Phone', '']}
                        fieldsData={['phone', '']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'phone', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={customer}
                        fields={['Address line 1', 'Address line 2']}
                        fieldsData={['address1', 'address2']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'address1', value: event?.target?.value}),
                            (event) => formValueChanged({field: 'address2', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={customer}
                        fields={['City', 'County']}
                        fieldsData={['city', 'county']}
                        fieldsLookup={{county: irishCounties}}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'city', value: event?.target?.value}),
                            (event) => formValueChanged({field: 'county', value: event?.target?.value}),
                        ]}/>
                    <FormFieldSet 
                        model={customer}
                        fields={['Postcode', 'VAT Number']}
                        fieldsData={['postcode', 'vat_number']}
                        fieldsOnChange={[
                            (event) => formValueChanged({field: 'postcode', value: event?.target?.value}),
                            (event) => formValueChanged({field: 'vat_number', value: event?.target?.value}),
                        ]}/>
                    <button 
                        type="button" 
                        className="btn btn-primary login__button"
                        onClick={() => handleSaveClick()}
                        disabled={isLoading}
                        style={{width: '200px'}}
                    >
                        Save
                    </button>
                </div>
            </div>
        </CustomerLayout>
    )
}

export default CustomerProfile;
