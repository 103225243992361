import '../Auth/Login.css';
import LogoMain from "../../assets/imgs/logo-main-dark.png"

const Privacy = () => {

    return (
      <>
        <div className="login__background">
          <div className="div-center" style={{display:"block", width: "75%", height: "100%"}}>
            <div className="content" style={{ textAlign: 'left' }}>
              <div style={{textAlign: 'center', paddingTop: '50px'}}>
                <a href="/"><img src={LogoMain} style={{ width: '200px', textAlign: "center" }} className="login__logo" alt="" /></a>
              </div>
              <h1 className="login__header">Privacy Notice</h1>
              <p>
                <b><i>Last Updated: 17 April, 2024</i></b>
              </p>
              <p>
                This Privacy Notice (“Privacy Notice”) explains how Smart Haul Logistics (“SHL”), collects, uses, discloses, and applies the information collected when you use or access the services provided by SHL including, but not limited to, the provision of an online and mobile software platform (the “Platform”) allowing for various stakeholders to be connected to allow for the transportation of cargo (collectively the “Services”). Information collected by SHL may include personal information as defined by global privacy laws and other relevant personally identifiable information relating to you (collectively “Information”).
              </p>
              <p>
                By engaging with our Services you are agreeing to this Privacy Notice. Please read the following carefully to understand how we collect, use, and maintain your Information. In addition, it describes your choices for use, access, and correction of your Information.
              </p>
              <p>
                <h3>Changes to this Notice</h3>
                We may change this Privacy Notice from time to time. In the event we make changes, we will notify you by revising the “Last Updated” date at the top of this Privacy Notice. If there are significant changes to this Privacy Notice, we will attempt to notify you directly by email or within the Platform prior to changes becoming effective. We encourage you to review our Privacy Notice whenever you use our Services to stay informed of ways you can protect your privacy.
              </p>
              <p>
                <h3>Your Consent</h3>
                When we collect Information about you we will first obtain your permission to do so, if and as required by applicable law. However, by using the Services you agree that this notice governs our collection, use, and disclosure of your Information in connection with the Services. If you do not agree to be bound by this notice, you will need to stop using the Services.
              </p>
              <p>
                <h3>Information Collected</h3>
                SHL collects Information about you from various sources and methods. You may choose to submit Information to us, via our website, in connection with the use of the Platform, services or applications software, when you participate in an offer or promotion, or in connection with an actual or potential business relationship with us. We also may obtain Information about you from companies that use our products or services, publicly available information systems, consumer reporting agencies, and commercial information services. Below are the types of Information we may collect, including but not limited to:
              </p>
              <ul>
                <li><b>Individual and Business Contact Information:</b> We obtain Information to create a profile for you with Information such as name, email address, phone, company name (if applicable), and physical address, etc.</li>
                <li><b>Shipment Information:</b> If you are engaging with the Services we will collect certain details about transactions you engage with us, cargo Information, Information related to parties who may be involved with the transaction (such as shippers, carriers, drivers, etc.), and any other applicable Information about the shipment or the transaction.</li>
                <li><b>Log Information:</b> We retain Information about you when you access and use our applications which includes the Platform. This Information can include the following: IP address, timestamps, browser Information, Internet Service Provider “ISP”, Web Pages visited, and URL of the Web Page you visited before navigating to FFT.</li>
                <li><b>Location Information:</b> Providing the Services may require the collection of precise location Information. When you access our Services (via the Platform or otherwise) we may ask for permission to access your precise location. You may deny or revoke your permission at any time but doing so may affect your ability to utilize all features of our Services.</li>
                <li><b>Usage Information:</b> We monitor user activity in connection with our Services and may collect Information about the applications and features you use, websites you visit, as well as how you engage with our Services.</li>
                <li><b>Device Information:</b> We collect Information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, mobile device and mobile app identifiers, and mobile network Information.</li>
                <li><b>Crash or error Information:</b> If the application through which you access our Services crashes or returns an error, we may collect data to determine the cause of the error using first or third party services. The crash or error Information collected may include the following: Device Internet Protocol (“IP”) address, device name, operating system version, application configurations(s), the time and date, and other statistics.</li>
                <li><b>Visual Information:</b> For security purposes, we may utilize video or other electronic, digital, or wireless surveillance systems or devices (such as CCTV) at the entrance of our premises to record your data if you visit our premises.</li>
                <li><b>Information from other sources:</b> We may also obtain Information from other sources and combine that with Information we collect from you directly. These third parties include, but are not limited to, partners, verification services (for instance, to confirm your insurance or regulatory compliance status), credit agencies and bureaus, and publicly available sources.</li>
              </ul>
              <p>
                <h3>Use of Your Information</h3>
                We may use the Information collected for the limited purpose of providing the Services and related functionality and services, as described in this Privacy Notice and as permitted by applicable laws. These limited purposes include circumstances where it is necessary to provide or fulfill Services requested by or for you or where you have given us your express consent either directly or by engaging in the relevant transaction.
              </p>
              <p>
                We may use the Information we obtain about you to:
              </p>
              <ul>
                <li>provide the information, products and services you request;</li>
                <li>provide you, if you are a client, with effective customer service;</li>
                <li>provide our customers and carriers with shipment Information such as location;</li>
                <li>provide you with a personalized experience when you use our website or applications;</li>
                <li>contact you with special offers and other Information we believe will be of interest to you (in accordance with any privacy preferences you have expressed to us);</li>
                <li>contact you with information and notices related to your use of our products and services, including our website;</li>
                <li>invite you to participate in surveys and provide feedback to us (in accordance with any privacy preferences you have expressed to us);</li>
                <li>better understand your needs and interests;</li>
                <li>publish research and analysis of i) trade, trade patterns, and ramifications of trade and trade patterns; ii) supply chain trends, supply chain patterns, and ramifications of supply chain trends and patterns; iii) the economic impact related to the same; and (iv) other issues generally related in any way to all aspects of the transportation industry worldwide;</li>
                <li>improve our products and services and develop new products and services;</li>
                <li>improve our marketing and promotional efforts;</li>
                <li>improve the content, functionality and usability of our website or applications;</li>
                <li>enforce any applicable terms of use;</li>
                <li>promote security;</li>
                <li>detect, protect against and prevent fraud, claims and other liabilities, and manage corporate risk exposure;</li>
                <li>meet our internal and external audit obligations;</li>
                <li>comply with our legal and regulatory obligations, including with your privacy requests;</li>
                <li>support disaster recovery; and</li>
                <li>for any other purpose for which we provide notice at the time of collection.</li>
              </ul>
              <p>
                <h3>Sharing with Other Users of the Platform</h3>
                Depending on how you use the Platform, we may share information about you with other users of the Platform. Users of the Platform include, but may not be limited to:
              </p>
              <ul>
                <li>Shippers: Those who submit requests for transportation of Cargo</li>
                <li>Carriers: Companies that can accept requests for transportation and assign Drivers to fulfill them.</li>
                <li>Drivers: Those who fulfill requests to transmit cargo (may either belong to a Carrier or operate independently).</li>
                <li>Partners/Brokers: Other intermediary companies that help to facilitate the connection of the above on parties to allow for the transportation of Cargo</li>
              </ul>
              <p>
                For example, if you are a driver, we share your name, vehicle information, license information (If applicable), precise or approximate location, and certain contact information (including phone number) with Shippers involved in the Shipment request you helped to fulfill. If you are a Shipper or Broker/Partner, your name or company name, your instructions, address, and contact information will be shared with drivers who will transport your cargo. Certain documents and information that you submit to our Services in connection with a Shipment request, such as feedback, Shipment, and payment history with FFT, may be shared with other users. If you submit driver ratings and reviews, this information may be shared with other users of our Services.
              </p>
              <p>
                The Information described above (and any additional Information that may need to be shared to facilitate transactions in the Platform) is shared only upon the direct request by you to engage with other parties on the Platform or to participate in any relevant transaction.
              </p>
              <p>
                <h3>Information We Share or Sell</h3>
                We do not sell or otherwise disclose Information we collect about you, except as necessary to provide you the Services and/or as described herein or otherwise disclosed to you at the time the data is collected. Information shared with third parties in accordance with this Privacy Notice includes some or all of the Information we collect, as described and listed in this Privacy Notice. Information may be shared in the following ways and circumstances:
              </p>
              <ul>
                <li><b>Information shared upon request:</b> We share Information to perform activities that you request including where necessary to perform the Services as requested by you.</li>
                <li><b>Shared with Consent or at your direction:</b> We may share Information with other third parties with the consent of the individual to whom the Information pertains or upon such individual’s direction.</li>
                <li><b>Service Providers:</b> We may share your Information with third-party vendors that perform tasks on our behalf and under our instruction. These third-party vendors may use your Information only in connection with the services they perform on our behalf and they are bound to protect your Information in a manner consistent with our own policies and practices.</li>
                <li><b>Partner Features:</b> You may choose to use certain features on our website or applications (including the Platform) for which we partner with other entities. These features, which may include social networking and geographic location tools, are operated by third parties that are not affiliated with SHL. These third parties may use Information in accordance with their own privacy policies. On sites or applications on which these features are offered, the relevant third parties are identified. We strongly suggest you review the third parties' privacy policies if you use the relevant features.</li>
                <li><b>Legal Disclosures:</b> We may also disclose Information about you when we, in good faith, believe disclosure is appropriate to comply with the law, a court order, or a subpoena. We will attempt to notify users about legal demands for their Information when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority.</li>
                <li><b>Fraud Prevention or Investigation:</b> We may also disclose Information about you to prevent or investigate a possible crime, such as fraud or identity theft; to protect the security of our website; to enforce or apply our websites' terms of use or other agreements; or to protect our own rights or property or the rights, property or safety of our users or others.</li>
              </ul>
              <p>
                <h3>Change of Control</h3>
                We also reserve the right to transfer Information about you to a successor organization if we transfer the ownership or operation of the Services to another organization or if we merge with another organization. If such a transfer occurs, we will use reasonable efforts to direct the transferee to use Information you have provided to us in a manner consistent with this policy. Following such a sale or transfer, you may contact the entity to which we transferred your Information with any inquiries concerning the processing of that Information.
              </p>
              <p>
                <h3>Security</h3>
                SHL takes security seriously and has implemented administrative, physical, and technological measures to protect your Information from unauthorized access, loss, misuse, disclosure, alteration, and destruction. If you wish to report a security concern or if you have a question around security, please submit your inquiry to <a href="mailto: privacy@smarthaullogistics.com">privacy@smarthaullogistics.com</a>
              </p>
              <p>
              <h3>Policy Regarding Children</h3>
                The Services are directed to individuals at the age of 18 and over. We do not knowingly collect Information from individuals under the age of 18 (“Children”). If we become aware of individuals who have provided us with Information and are under the age of 18, we will take steps to deactivate the account and delete the Information. If you become aware of Information from individuals under the age of 18 which has been provided to SHL, please contact us at <a href="mailto: privacy@smarthaullogistics.com">privacy@smarthaullogistics.com</a>
              </p>
              <p>
                <h3>Your Privacy Rights</h3>
                You have the following rights regarding the Information we maintain about you. We may ask you for additional information to verify your identity in order to process your request. If at any time you wish to exercise your rights, please reach out to privacy@smarthaullogistics.com
              </p>
              <ul>
                <li><b>Information and access:</b> You can request to see which Information we process from you. We can inform you on how and why we process this data unless restricted by law, or if we are unable to verify your identity.</li>
                <li><b>Submitting Information:</b> You may refrain from submitting Information directly to us, although doing so may impact our ability to provide the products, services, and information you request and/or affect your ability to use Services.</li>
                <li><b>Rectification/correction:</b> You may request to update or correct the Information we maintain for you.</li>
                <li><b>Right to be forgotten/request deletion:</b> You may request deletion of your Information. We may ask you for additional information to verify your identity and will process your request subject to and in accordance with law.</li>
                <li><b>Restriction:</b> You may request the limitation on the processing of your Information.</li>
                <li><b>Restricting cookies:</b> You may elect not to have a unique cookie identification number assigned to your computer to avoid aggregation and analysis of data collected on our websites. Generally, if you do not wish to receive cookies, you may set your browser to deny cookies or to alert you when a cookie is placed on your computer.</li>
                <li><b>Objection:</b> You may remove your consent for the processing of your Information.</li>
                <li><b>Transferability/portability:</b> You may request to transfer your Information in a portable format.</li>
              </ul>
              <p>
                We will not discriminate against you for exercising your rights. However, these rights are not absolute. We may deny requests as permitted by law, such as requests which risk the privacy of others or are unreasonable or repetitive, or requests that would require disproportionate effort or when we are not able to verify your identity.
              </p>
              <p>
                If you wish to contact us through an authorized agent, we may request evidence that you have provided such agent with power of attorney, or that the agent otherwise has valid written authority to submit requests to exercise rights on your behalf.
              </p>
              <p>
                <h3>Information Retention</h3>
                SHL will retain your Information for the period necessary to fulfill the purposes for which it has been collected or as required or permitted by law (purposes are described in the "Use of Your Information" section above). SHL will retain Information required to comply with privacy requests, manage active accounts, and to resolve disputes or enforce agreements. We will retain Information we process on behalf of our clients as directed by them. We may also retain copies of your Information for disaster recovery purposes.
              </p>
              <p>
                <h3>Contacting Us</h3>
                For any questions about this Privacy Notice or if you wish to exercise your rights under the access principle to correct, amend, and delete Information, 
                you can address <a href="mailto: privacy@smarthaullogistics.com">privacy@smarthaullogistics.com</a> or to our mailing address:
                <br /><br />
                Smart Haul Logistics<br />
                Att: Privacy Team<br />
                9 Garville Road<br />
                Rathgar<br />
                Dublin 6<br />
              </p>
            </div>
          </div>
        </div>
      </>
    );

}

export default Privacy;
